import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import forms from './forms'
import { arrayFromEntitiesCollection } from '../../../lib/utils/collections'
import { connect } from 'react-redux'
import { AppReduxState } from '../../../data/types/appReduxState'
import { IGym } from '../../../data/types/models/Gym'
import { IForm } from '../../../data/types/models/Form'
import cuid from 'cuid'
import api from '../../../data/firebase/api'

interface Props {
    gym: IGym
    dispatch: any
    onDone: () => void
}

const SelectPreLoadedForms: React.FC<Props> = ({ gym, dispatch, onDone }) => {
    const [preloadedForms] = useState(arrayFromEntitiesCollection(forms))
    const [selectedForms, setSelectedForms] = useState<{[id: string]: boolean}>({})
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        let selected = {}
        preloadedForms.forEach((form) => {
            selected[form.id] = true
        })
        setSelectedForms(selected)
    }, [preloadedForms])

    const onSend = async () => {
        setLoading(true)
        for (const [key, value] of Object.entries(selectedForms)) {
            let form = forms[key]
            if (value === true && form) {
                const newForm: IForm = {
                    id: cuid(),
                    createdOn: new Date(),
                    gymId: gym.id,
                    items: form.items,
                    description: form.description,
                    room: form.room,
                }
                await api.setTemplate(newForm, dispatch)
            }
        }
        onDone()
        setLoading(false)
    }

    return (
        <Layout
            //@ts-ignore
            preloadedForms={preloadedForms}
            selectedForms={selectedForms}
            onSelectAll={() => {
                let selected = {}
                preloadedForms.forEach((form) => {
                    selected[form.id] = true
                })
                setSelectedForms(selected)
            }}
            onUnselectAll={() => {
                let selected = {}
                setSelectedForms(selected)
            }}
            onSelectForm={(id: string) => {
                selectedForms[id] = !selectedForms[id]
                setSelectedForms({...selectedForms})
            }}
            isLoading={isLoading}
            onValid={onSend}
        />
    )
}

export default connect((appState: AppReduxState) => ({gym: appState.gyms.current}))(SelectPreLoadedForms)