import { Button, Dialog, DialogTitle, FormControl, FormLabel, MenuItem, Select, TextField, Typography, useMediaQuery, useTheme, Input, Chip, CircularProgress } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DoneIcon from '@material-ui/icons/Done';
import React, { useEffect, useState } from 'react';
import { IFormItem } from '../../../../data/types/models/Form';
import { ActionMenuModel } from '../../../../lib/ui/ActionsMenu';
import { useMainStyles } from '../../../../lib/ui/mainStyles';
import { ButtonActions, FormContainer, QuestionDiv, useStyles } from './style';
import BackspaceIcon from '@material-ui/icons/Backspace';
import { IGym } from '../../../../data/types/models/Gym';

const actions: ActionMenuModel[] = [
    {
        value: "cancel",
        label: "Annuler",
        icon: ArrowBackIcon,
        color: "red"
    },{
        value: "confirm",
        label: "Confirmer",
        icon: DoneIcon,
        color: "green"
    }
]

interface Props {
    onValid: () => void;
    onCancel: () => void;
    onAddItem: () => void;
    setValue: ({name, value}: {name: string, value: string}) => void
    values: any
    items: IFormItem[]
    onModifyItem: (item: IFormItem, index: number) => void
    onDelete: (index: number) => void
    isLoading: boolean
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

const Layout: React.FC<Props> = ({ onValid, onCancel, onAddItem, setValue, values, items, isLoading, onModifyItem, onDelete}) => {
    
    const mainClasses = useMainStyles()
    const [localValues, setLocalValues] = useState(values)
    useEffect(() => {
        setLocalValues(values)
    }, [values])
    const classes = useStyles()
    const onBlur = (event: any) => {
        setValue(event.target)
    }
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <Dialog fullWidth fullScreen={!matches} open={true} classes={{paper: classes.paper}}>
                <DialogTitle style={{fontWeight: "bold"}} disableTypography>
                    <Typography variant="h5" style={{fontWeight: "bold"}}>{"Modifier le formulaire"}</Typography>
                </DialogTitle>
                <FormContainer>
                    <TextField
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className={classes.textField}
                        name="room"
                        onChange={(e) => {
                            setLocalValues({...localValues, room: e.currentTarget.value})
                        }}
                        value={localValues.room}
                        placeholder="Protocol d'Hygiène" 
                        size={"medium"} 
                        label={"Nom du formulaire"} 
                        onBlur={onBlur}
                    />
                    <TextField 
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="description"
                        onBlur={onBlur}
                        value={localValues.description}
                        onChange={(e) => {
                            setLocalValues({...localValues, description: e.currentTarget.value})
                        }}
                        placeholder="Ici écrire la description"
                        size={"medium"} 
                        label={"Description (optionnel)"} 
                        multiline 
                        className={classes.textField}
                        rowsMax={5}
                    />
                    <FormLabel className={classes.formLabel}>Questions</FormLabel>
                    {items.map((item, index) => (
                        <QuestionDiv>
                            <TextField
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="newItemLabel"
                                placeholder="Titre de la question"
                                size={"medium"}
                                defaultValue={item.label}
                                onBlur={({target}) => {
                                    onModifyItem({...item, label: target.value}, index)
                                }}
                                className={classes.questionsTextField}
                            />
                            <FormControl
                                className={classes.questionsTextField}
                                style={{flex: 1}}
                            >
                                <Select
                                    labelId="type"
                                    id="type"
                                    value={item.type}
                                    placeholder={"Type de la question"}
                                    onChange={(event: any) => {
                                        onModifyItem({...item, type: event.target.value}, index)
                                    }}
                                >
                                    <MenuItem value={"note"}>Commentaire</MenuItem>
                                    <MenuItem value={"number"}>Nombre</MenuItem>
                                    <MenuItem value={"mark"}>Note sur 10</MenuItem>
                                    <MenuItem value={"boolean"}>Vrai ou Faux</MenuItem>
                                </Select>
                            </FormControl>
                                <BackspaceIcon className={classes.deleteIcon} color="primary" onClick={() => {onDelete(index)}}/>
                            </QuestionDiv>                                                
                    ))}
                    <Button onClick={onAddItem} color="secondary" variant="text" style={{placeSelf: "self-start"}}>+ Ajouter une question</Button>
                </FormContainer>
                <ButtonActions>
                    <Button disabled={isLoading} onClick={onCancel} variant="contained" style={{marginRight: "10px"}}>{isLoading ? <CircularProgress color="primary" size={20}/> : "Annuler"}</Button>
                    <Button disabled={isLoading} onClick={onValid} color="primary" variant="contained">{isLoading ? <CircularProgress color="secondary" size={20}/> : "Valider les modifications"}</Button>
                </ButtonActions>
        </Dialog>
    )
}

export default Layout