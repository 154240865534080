import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import styled from 'styled-components';

const drawerWidth = 260;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: "100%"
    },
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    buttonAction: {
      margin: "auto",
      display: "block"
    },
    // necessary for content to be below app bar
    toolbar: {
      ...theme.mixins.toolbar,
    },
    title: {
      flex: 1,
      fontWeight: "bold"
    },
    select: {
      alignSelf: "center",
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
    },
    listItem: {
      margin: "15px 0",
      padding: "17px 16px"
    },
    listItemText: {
      fontWeight: "bold",
      fontSize: '0.8rem'
    },
    trialTimeText: {
      fontWeight: "bold",
      fontSize: '0.8rem',
      textAlign: "center",
    },
    trialTimeTextButton: {
      fontWeight: "bold",
      fontSize: '0.8rem',
      textAlign: "center",
      textDecorationLine: "underline",
      textDecorationStyle: "solid",
      textDecorationColor: theme.palette.primary.main
    }
  }),
);


export const ButtonIcon = styled(IconButton)`
  @media print { 
    display: none;
  }
`