import React from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DoneIcon from '@material-ui/icons/Done';
import { MainContainer, useStyles, QuestionDiv, ButtonActions } from './style'
import ActionsMenu, { ActionMenuModel } from '../../../../lib/ui/ActionsMenu'
import { Typography, TextField, Divider, Switch, Slider, Dialog, DialogTitle, useMediaQuery, useTheme, FormLabel, Button, CircularProgress } from '@material-ui/core';
import { IFormItem } from '../../../../data/types/models/Form';
import ImageUploader from 'react-images-upload';
import './styles.css'

interface Props {
    room?: string
    place?: string
    errors: {
        coach?: string
        pictures?: string
    }
    description?: string;
    author?: string;
    items: IFormItem[]
    setValue: (props: {name: string, value: any}) => void;
    onCancel: () => void
    onConfirm: () => void
    isLoading: boolean
    onPicture: (files: File[], pictures: string[]) => void
    pictureSending?: number
}

const Layout: React.FC<Props> = ({ 
    onCancel, 
    onConfirm, 
    errors,
    room,
    place,
    author,
    items,
    setValue,
    description,
    isLoading,
    onPicture,
    pictureSending
}) => {
    const classes = useStyles()
    const actions: ActionMenuModel[] = [
        {
            value: "cancel",
            label: "Annuler",
            icon: ArrowBackIcon,
            color: "red"
        },{
            value: "confirm",
            label: "Envoyer",
            icon: DoneIcon,
            color: "green"
        }
    ]
    const handleActions = (value: string) => {
        if (value === "cancel") {
            onCancel()
        } else if (value === "confirm") {
            onConfirm()
        }
    }
    const onChange = (event: any) => {
        setValue({name: event.target.name, value: event.target.value})
    }
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Dialog fullWidth fullScreen={!matches} open={true} classes={{paper: classes.paper}}>
            {pictureSending &&
                <div className={classes.overlayLoadingPictures}>
                    <CircularProgress color="primary" size={20}/>
                    <Typography variant="h6">{`Envoi de la photo n°${pictureSending} en cours...`}</Typography>
                </div>
            }
            <DialogTitle style={{fontWeight: "bold"}} disableTypography>
                <Typography variant="h5" style={{fontWeight: "bold"}}>{room || "Completion du formulaire"}</Typography>
            </DialogTitle>
            <MainContainer>
                <Typography variant="h6">{description}</Typography>
                <br/>
                <FormLabel>{"Nom de l'auteur"}</FormLabel>
                <TextField
                    name="author" 
                    placeholder="Raphaël"
                    size={"medium"} 
                    onBlur={onChange}
                    error={errors.coach !== undefined}
                    helperText={errors.coach}
                />
                <br/>
                <br/>
                {items && items.map((item, index) => (
                    <QuestionDiv row={item.type === "boolean" || item.type === "number"} key={item.label + item.type + index}>
                        <FormLabel>{item.label}</FormLabel>
                        {
                            item.type === "note" && 
                            <TextField 
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name={index.toString()}
                                placeholder="Réponse"
                                multiline 
                                rowsMax={5}
                                onBlur={onChange}
                        />
                        }
                        {
                            item.type === "boolean" && 
                            <Switch 
                                color="secondary"
                                //@ts-ignore
                                onChange={(event) => {setValue({name: index.toString(), value: event.target.checked})}}
                            />
                        }
                        {
                            item.type === "mark" && 
                            <Slider
                                color="secondary"
                                valueLabelDisplay='on'
                                defaultValue={5}
                                min={0}
                                max={10}
                                className={classes.slider}
                                onChange={(e, newValue) => {setValue({name: index.toString(), value: newValue})}}
                            />
                        }
                        {
                            item.type === "number" && 
                            <TextField 
                                type="number"
                                name={index.toString()}
                                placeholder="Réponse"
                                size={"medium"}
                                onBlur={onChange}
                        />
                        }
                    </QuestionDiv>    
                ))}
                <ImageUploader
                    withIcon={true}
                    buttonText='Ajouter des photos'
                    onChange={onPicture}
                    imgExtension={['.jpg', "jpeg", '.png']}
                    maxFileSize={5242880}
                    withPreview
                    fileSizeError=" : fichier trop volumineux"
                    fileTypeError=" : extension de ficher non supportée"
                    label="Taille max: 5mb"
                />
            </MainContainer>
            {errors.pictures && <Typography color="error">{errors.pictures}</Typography>}
            <ButtonActions>
                <Button disabled={isLoading} onClick={onCancel} variant="contained" style={{marginRight: "10px"}}>{isLoading ? <CircularProgress color="primary" size={20}/> : "Annuler"}</Button>
                <Button disabled={isLoading} onClick={onConfirm} color="primary" variant="contained">{isLoading ? <CircularProgress color="secondary" size={20}/> : "Envoyer"}</Button>
            </ButtonActions>
        </Dialog>
    )
}

export default Layout