import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers';
import * as yup from "yup";

import { Button, CircularProgress, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core'
import { Form, MainContainer, useStepsStyles, ButtonsDiv } from '../stepsStyles'
import { Controller } from "react-hook-form"
import { Visibility, VisibilityOff } from '@material-ui/icons';

interface Props {
    onDone: (values: {password: string}) => void
    onBack: () => void
    isLoading: boolean
    stepNumber: number
    totalStep: number
}

interface Values {
    password: string;
    confirmPassword: string;
}

const schema = yup.object().shape({
    password: yup.string().min(6, "Le mot de passe doit être composé de minimum 6 caractères").required('Mot de passe requis'),
    confirmPassword: yup.string().oneOf([yup.ref('password')], 'Les mots de passe doivent concorder')}).required('Confirmation du mot de passe requis');
  
const PasswordInfos: React.FC<Props> = ({ onDone, isLoading, onBack, stepNumber, totalStep }) => {
    const { handleSubmit, errors, control, register, watch } = useForm<Values>({
        resolver: yupResolver(schema)
    })

    const [showPassword, setShowPassword] = useState(false)

    const onSubmit = (data: Values) => {
        onDone(data)
    }
    const stepsClasses = useStepsStyles()

    return (
        <MainContainer>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="h4" className={stepsClasses.title}>Création de l'espace <span className={stepsClasses.colored}>administrateur</span></Typography>
                <Typography variant="body2" className={stepsClasses.stepText}>ÉTAPE {stepNumber}/{totalStep}</Typography>
                <Controller
                    as={TextField}
                    name="password"
                    control={control}
                    type={showPassword ? "text" : "password"}
                    label="Mot de passe de l'espace administrateur"
                    className={stepsClasses.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: (<InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {setShowPassword(!showPassword)}}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>),
                        style: {
                            display: "flex",
                        }
                    }}
                    disabled={isLoading}
                    placeholder="***************"
                    helperText={errors.password?.message}
                    error={!!errors.password}
                />
                <Controller
                    as={TextField}
                    control={control}
                    name="confirmPassword"
                    className={stepsClasses.textField}
                    ref={register}
                    disabled={isLoading}
                    type={showPassword ? "text" : "password"}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: (<InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {setShowPassword(!showPassword)}}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>),
                        style: {
                            display: "flex",
                        }
                    }}
                    label="Confirmation du mot de passe"
                    placeholder="***************"
                    helperText={errors.confirmPassword?.message}
                    error={!!errors.confirmPassword}
                />
                <ButtonsDiv>
                    <Button disabled={isLoading} onClick={onBack} variant="outlined" color="primary">Revenir</Button>
                    <Button type='submit' disabled={isLoading || !watch("confirmPassword")} variant="contained" color="primary">{isLoading ? <CircularProgress size={20} color="secondary"/> : "Suivant"}</Button>
                </ButtonsDiv>
            </Form>
        </MainContainer>
    )
}

export default PasswordInfos