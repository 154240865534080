import { Button, MobileStepper, Typography } from '@material-ui/core'
import { auth } from 'firebase'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import AppConfig from '../../AppConfig'
import api from '../../data/firebase/api'
import { AppReduxState } from '../../data/types/appReduxState'
import { IGym } from '../../data/types/models/Gym'
import { IUser } from '../../data/types/models/User'
import { useMainStyles } from '../../lib/ui/mainStyles'
import PaiementDetails from './PaiementDetails'
import SubscriptionDetails, { SubscriptionDetailsValues } from './SubscriptionDetails'

interface Props {
    gym: IGym
    onCancel: () => void
    dispatch: any
}

const SubscribeGym: React.FC<Props> = ({ gym, onCancel, dispatch }) => {
    const mainClasses = useMainStyles()
    const [index, setIndex] = useState<number>(0)
    const [selectedSubscription, setSelectedSubscription] = useState()
    const [gymDetails, setGymDetails] = useState<SubscriptionDetailsValues | undefined>(undefined)
    const history = useHistory()
    const onBack = () => {
        switch (index) {
            case 0:
                onCancel()
                break;
            default:
                setIndex(index - 1)
                break
        }
    }

    function displayStep() {
        if (index === 0 || !gymDetails) {
            return <SubscriptionDetails onBack={onBack} gym={gym} onDone={(values) => {
                setGymDetails(values)
                setIndex(index + 1)
            }}/>
        } else {
            return <PaiementDetails onBack={onBack} values={gymDetails} gym={gym} onDone={async () => {
                await api.loadAdminItems(auth().currentUser.uid, dispatch)
                if (onCancel) {
                    onCancel()
                } else {
                    history.push("/")
                }
            }}/>
        }
    }

    return (
        <div>
            <Typography variant="h4" className={mainClasses.stepTitle}>{AppConfig.brandName} {gym.name}</Typography>
            {displayStep()}
        </div>
    )
}

export default connect(
    (rootState: AppReduxState) => ({ gyms: rootState.gyms, user: rootState.user }),
    (dispatch) => ({dispatch}),
    ({ gyms }, dispatchProps, { gymId, ...props }: { gymId: string }) => ({
        gym: gyms[gymId],
        ...dispatchProps,
        ...props
    })
)(SubscribeGym)