import styled from 'styled-components'
import { makeStyles, Theme, createStyles } from '@material-ui/core'

export const useMainStyles = makeStyles((theme: Theme) => 
    createStyles({
        title: {
            marginLeft: "15px",
            marginBottom: "15px"
        },
        subTitle: {
            marginLeft: "15px",
            marginBottom: "15px"
        },
        dialogPaper: {
            padding: "10px",
            [theme.breakpoints.up('sm')]: {
                padding: "30px",
            },
        },
        stepTitle: {
            fontWeight: "bold",
            marginBottom: "50px",
            textAlign: "center"
        },
        stepSectionTitle: {
            color: theme.palette.secondary.main,
            fontWeight: "bold",
        },
        subtitle: {
            textAlign: "center"
        },
    })
)

export const MainContainer = styled.div`
    @media (min-width: 600px) {
        padding: 25px;
    }
    padding-top: 15px;
    box-sizing: border-box;
    display: flex;
    padding-bottom: 59px;
    width: 100%;
    flex-direction: column;
    /* justify-content: space-between; */
    flex: 1;
    overflow: auto;
`
export const Lists = styled.div`
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    flex: 1;

`

export const TopLists = styled.div`
    flex-direction: column;
    display: flex;
    flex: 1;
`

export const ButtonActions = styled.div`
    display: flex;
    place-self: center;
    margin: 15px 0;
    flex-direction: row;
`

export const PrintDiv = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
    @media print {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
`