import { FormLabel, Typography, useMediaQuery, useTheme, Dialog, DialogTitle, Button } from '@material-ui/core'
import React, { useRef } from 'react'
import { IForm } from '../../../data/types/models/Form'
import Drawer from '../../../lib/ui/Drawer'
import { Container, MainContainer, QRCodeView, QuestionDiv, useStyles, ButtonsDiv, PrintDiv } from './style'
import { getLabelForQuestionType } from '../../../lib/utils/labels'
import DeleteIcon from '@material-ui/icons/Delete';
import { ButtonIcon } from '../../../lib/ui/Drawer/styles'
import { ButtonActions } from '../../FormsListView/CreateFormView/Layout/style'
import EditIcon from '@material-ui/icons/Edit';
import EditFormView from '../EditFormView'
import PrintIcon from '@material-ui/icons/Print';

interface Props {
    form: IForm
    onFill: () => void
    onDelete: () => void
    validDeleteModal: boolean
    onValidDelete: () => void
    onCancelDelete: () => void
    canDelete: boolean
    onEdit: () => void
    onPrint: () => void
}

const Layout: React.FC<Props> = ({ form, onFill, onDelete, validDeleteModal, onCancelDelete, onValidDelete, canDelete, onEdit, onPrint }) => {
    const { id, room, place, description, items } = form
    const classes = useStyles()
    const theme = useTheme()
    const matches = useMediaQuery('@media print');
    const printRef = useRef()
    return (
        <Drawer title={room} buttonAction={{title: "Remplir le formulaire", onClick: onFill}}>
            <Dialog fullWidth open={validDeleteModal} classes={{paper: classes.paper}}>
                <DialogTitle style={{fontWeight: "bold"}} disableTypography>
                    <Typography variant="h5" style={{fontWeight: "bold"}}>{"Supprimer ce formulaire ?"}</Typography>
                </DialogTitle>
                <ButtonActions>
                    <Button onClick={onCancelDelete} variant="contained" style={{marginRight: "10px"}}>Annuler</Button>
                    <Button onClick={onValidDelete} color="primary" variant="contained">Supprimer le formulaire</Button>
                </ButtonActions>
            </Dialog>
            <PrintDiv>
                <Typography>{room}</Typography>
                <Container>
                    <QRCodeView value={id} renderAs='svg' size={200} level='H'/>
                </Container>
                <Typography>Découper ici</Typography>
            </PrintDiv>
            <MainContainer className="no-print">
                <EditFormView/>
                {description &&
                    <div>
                        <FormLabel>Description :</FormLabel>
                        <Typography variant="body1">{`${description}`}</Typography>
                    </div>
                }
                <Container>
                    <QRCodeView value={id} renderAs='svg' size={200} level='H'/>
                </Container>
                <div className="no-print" style={{display: "flex", flexDirection: "column", flex: 1}}>
                    <FormLabel>Questions :</FormLabel>
                    {items.map((item, index) => (
                        <QuestionDiv key={item.label + item.type + index}>
                            <Typography variant="body1">{`✍️ ${item.label}`}</Typography>
                            <Typography variant="body2">{`${getLabelForQuestionType(item)}`}</Typography>
                        </QuestionDiv>
                    ))}
                    <ButtonsDiv>
                        <ButtonIcon onClick={onPrint}><PrintIcon color="primary"/></ButtonIcon>
                        {canDelete &&
                            <>
                                <Button onClick={onEdit} variant="contained" color="primary" startIcon={<EditIcon/>}>Modifier le formulaire</Button>
                                <Button style={{marginLeft: "10px"}} variant="contained" onClick={onDelete} color="primary" startIcon={<DeleteIcon/>}>Supprimer le formulaire</Button>
                            </>
                        }
                    </ButtonsDiv>
                </div>
            </MainContainer>
        </Drawer>
    )
}

export default Layout