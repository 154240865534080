import { IForm } from "../types/models/Form";

export const SET_FORMS = "SET_FORMS"

export const setForms = (forms: {[id: string]: IForm}) =>
      ({
            type: SET_FORMS,
            payload: forms
      });

export const SET_FORM = "SET_FORM"

export const setForm = (form: IForm) =>
      ({
            type: SET_FORM,
            payload: form
      });
