import { IForm } from "../types/models/Form";
import { SET_FORMS, SET_FORM } from "../actions/forms";

const formsReducer = (
    state: {[id: string]: IForm} = {},
    {type, payload}: {type: string, payload: any}
) => {
    switch (type) {
        case SET_FORMS:
            return payload;
        case SET_FORM:
            state[payload.id] = payload
            return {...state};
    }
    return state;
}
  
export default formsReducer;
  