import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
import { Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>  
    createStyles({
        main: {
        },
        gymsList: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
        },
        gymCard: {
            margin: "10px"
        }
    })
)