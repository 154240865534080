import styled from 'styled-components'
import { Theme, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>  ({
    title: {
        marginLeft: "15px",
        marginBottom: "15px"
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    subHeaderTitle: {
        fontWeight: "bold"
    },
    formControl: {
        margin: theme.spacing(3),
        width: "100%"
    },
    chips: {
        display: 'flex',
        flexDirection: "column"
    },
    chip: {
        margin: 2,
        justifyContent: "space-between"
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    paperFilter: {
        minWidth: "100%",
        maxWidth: "100%",
        minHeight: "100%",
        maxHeight: "100%",
        boxSizing: "border-box",
        left: "0 !important",
        top: "0 !important",
    },
    buttonsFiltersDiv: {
        position: "fixed",
        bottom: "20px",
        flexDirection: "column",
        [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
            flexDirection: "row",
        },
        display: "flex",
    },
    loadingLayer: {
        position: "absolute",
        backgroundColor: 'rgba(255,255,255, 0.2)',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 100,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    }
}))

export const MainContainer = styled.div`
    padding-top: 15px;
    box-sizing: border-box;
    display: flex;
    flex: 1;
    overflow: auto;
    width: 100%;
    @media(max-width: 960px) {
        flex-direction: column;
        overflow: unset;
    };
    position: relative;
    flex-direction: row;
`

export const ReportsList = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    flex: 1;
`

export const FilterDiv = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    width: 400px;
    max-width: 100%;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
    padding-bottom: 100px;
    margin-left: auto;
    margin-right: auto;
`
