import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'
import cuid from 'cuid'

import Layout from './Layout'
import { IForm, IFormItem } from '../../../data/types/models/Form'
import { AppReduxState } from '../../../data/types/appReduxState'
import { withRouter, RouteComponentProps, useRouteMatch } from 'react-router'
import { routes } from '../../../lib/utils/routes'
import api from '../../../data/firebase/api'
import { IGym } from '../../../data/types/models/Gym'
import { arrayFromEntitiesCollection } from '../../../lib/utils/collections'

interface Values {
    room?: string;
    place?: string;
    description?: string;
    gyms: string[]
}

interface Props extends RouteComponentProps<{id: string}> {
    dispatch: any
    gym: IGym
    gyms: IGym[]
}

const CreateFormView: React.FC<Props> = ({ match, history, dispatch, gym, gyms }) => {
    const [items, setItems] = useState<IFormItem[]>([{label: "", type: "boolean"}])
    const [values, setValues] = useState<any>({gyms: []})
    const [isLoading, setLoading] = useState(false)
    const isOpen = useRouteMatch(routes.CREATE_FORM_VIEW)?.isExact
    useEffect(() => {
        setItems([{label: "", type: "boolean"}])
        setValues({gyms: []})
        setLoading(false)
    }, [isOpen])
    if (!isOpen) {
        return null
    }

    return (
        <Layout 
            onCancel={() => {
                history.push(routes.FORMS_LIST_VIEW)
            }}
            isLoading={isLoading}
            onValid={async () => {
                for (let i = 0; i < values.gyms.length ; i++) {
                    const form: IForm = {
                        id: cuid(),
                        createdOn: new Date(),
                        gymId: (values.gyms)[i],
                        items: items,
                        description: values.description,
                        room: values.room,
                    }
                    setLoading(true)
                    await api.setTemplate(form, dispatch)
                }
                history.push(routes.FORMS_LIST_VIEW)
            }}
            setValue={({name, value}) => {
                values[name] = value
                setValues({...values})
            }}
            onAddItem={() => {
                items.push({
                    type: "boolean",
                    label: ""
                })
                setItems(items.slice())
            }}
            onModifyItem={(item, index) => {
                items[index] = item
                setItems(items.slice())
            }}
            gyms={gyms}
            onDelete={(index) => {
                items.splice(index, 1)
                setItems(items.slice())
            }}
            values={values}
            items={items}
        />
    )
}

interface TStateProps {
    gym: IGym
    gyms: IGym[]
}

export default withRouter(
    connect<TStateProps>(
    ({gyms}: any) => {
        let current = gyms.current
        gyms = {...gyms, current: undefined }
        delete gyms.current
        return {
            gym: current,
            gyms: arrayFromEntitiesCollection(gyms)
        }}
    )(CreateFormView)
)