import { CircularProgress, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import AppConfig from '../../../../AppConfig';
import { Form, MainContainer, useStepsStyles, LoaderDiv } from '../stepsStyles';


interface Props {
    onDone: () => void
    gymName: string;
}

const LoaderView: React.FC<Props> = ({ onDone, gymName }) => {
    const stepsClasses = useStepsStyles()

    useEffect(() => {
        setTimeout(() => {
            onDone()
        }, 3000)
    })

    return (
        <MainContainer>
            <Form>
                <Typography variant="h4" className={stepsClasses.title}>Espace {AppConfig.brandName} <span className={stepsClasses.colored}>{gymName}</span></Typography>
                <LoaderDiv>
                    <CircularProgress size={150} color="secondary"/>
                    <br/>
                    <Typography variant="h6" className={stepsClasses.title}>Nous préparons ton espace...</Typography>
                </LoaderDiv>
            </Form>
        </MainContainer>
    )
}

export default LoaderView