import { IForm } from "../types/models/Form";

export const SET_TEMPLATES = "SET_TEMPLATES"

export const setTemplates = (templates: {[id: string]: IForm}) =>
      ({
            type: SET_TEMPLATES,
            payload: templates
      });

export const SET_TEMPLATE = "SET_TEMPLATE"

export const setTemplateAction = (template: IForm) =>
      ({
            type: SET_TEMPLATE,
            payload: template
      });
