import { createStore } from 'redux'
import rootReducer from '../reducers'
import { AppReduxState } from '../types/appReduxState';
import { persistStore, persistReducer, createTransform } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const setTransform = createTransform(
  //@ts-ignore
  JSON.stringify,
  (toRehydrate) => JSON.parse(toRehydrate, (key, value) => {
    if (
      typeof value === 'string'
    && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)
    ) return new Date(value);
    return value;
  }),
  { whitelist: ['forms', 'templatesForm', "gyms", "user", "auth"] },
);

const persistConfig = {
  key: 'root',
  storage,
  transforms: [setTransform],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default () => {
  let store = createStore(persistedReducer,
      //@ts-ignore
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
  let persistor = persistStore(store)
  return {store, persistor}
};