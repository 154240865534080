export const routes = {
    FILL_FORM_VIEW: "/scan/fill/",
    SCAN_VIEW: "/scan",
    CREATE_FORM_VIEW: "/forms/create",
    TEMPLATE_FORM_VIEW: "/forms/",
    FORMS_LIST_VIEW: "/forms",
    REPORTS: "/reports/",
    REPORTS_DETAIL_VIEW: "/reports/",
    SETTINGS: "/settings",
    SIGNUP: "/signup",
    TEAM: "/team",
    ADDONS: "/addons",
    HYGUP: "/addons/hyg-up",
    HYGUP_ORDER: "/addons/hyg-up/order/",
    GYMS: "/gyms/",
}