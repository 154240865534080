import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
import { Theme, createStyles } from '@material-ui/core';
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        main: {
        },
        stepMain: {
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
        },
        paiementDiv: {
            display: 'flex',
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%"
        },
        textField: {
            margin: "10px 0"
        },
        textFieldPromoCode: {
            fontSize: theme.typography.pxToRem(26),

            boxShadow: "unset",
            "&$disabled": {
                backgroundColor: "inherits"
            }
        }
    })
)