import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
import { Theme, createStyles } from '@material-ui/core';
export const useStyles = makeStyles((theme: Theme) =>  
    createStyles({
        paper: {
            padding: "10px",
            [theme.breakpoints.up('md')]: {
                padding: "30px",
            },
        }
    })
)