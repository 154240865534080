import React, { useEffect, useState } from 'react'
import Drawer from '../../../lib/ui/Drawer'
import { MainContainer, PrintDiv } from '../../../lib/ui/mainStyles'
import { useStyles } from './styles'
import Setup from '../Setup'
import { HygUpData } from '../../../data/types/models/Addons/HygUp'
import { Button, TextField, Typography } from '@material-ui/core'
import ActionCard from '../../../lib/ui/ActionCard'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import PrintIcon from '@material-ui/icons/Print';
import { Container, QRCodeView } from '../../TemplateFormView/Layout/style'

interface Props {
    loading?: boolean
    onUpdateData?: (data: HygUpData) => void
    data?: HygUpData
    onOrder: () => void
    onPrint: () => void
}

const Layout: React.FC<Props> = ({ loading, onUpdateData, data, onOrder, onPrint }) => {
    const classes = useStyles({})
    const [localData, setLocalData] = useState(data)
    useEffect(() => {
        setLocalData(data)
    }, [data])
    const qrCodeData = {
        version: "1.0",
        redirect: "/addons/hyg-up/order",
    }
    return (
        <Drawer title={"Hyg'Up"}>
            <Setup />
            <PrintDiv>
                <Typography>{"Commande de produits Hyg'Up"}</Typography>
                <Container>
                    <QRCodeView value={JSON.stringify(qrCodeData)} renderAs='svg' size={200} level='H'/>
                </Container>
                <Typography>Découper ici</Typography>
            </PrintDiv>
            {localData &&
                <MainContainer className="no-print">
                    <Typography variant="h6" style={{fontWeight: "bold"}}>Actions :</Typography>
                    <div className={classes.addonActions}>
                        <ActionCard label="Commander" ImageComponent={AddShoppingCartIcon} onClick={onOrder}/>
                        <div style={{ height: "20px", width: "20px" }}/>
                        <ActionCard label="Imprimer le QRCode" ImageComponent={PrintIcon} onClick={onPrint}/>
                    </div>
                    <div className={classes.paramsDiv}>
                        <Typography variant="h6" style={{fontWeight: "bold"}}>Parametres du compte :</Typography>
                        <TextField
                            className={classes.textField}
                            value={localData.accountNumber}
                            label={"Numéro de compte Hyg'Up"}
                            onChange={(e) => {
                                setLocalData({ ...localData, accountNumber: e.target.value.trim() })
                            }}
                        />
                        <TextField
                            className={classes.textField}
                            value={localData.address}
                            name={"address"}
                            label={"Numéro de compte Hyg'Up"}
                            onChange={(e) => {
                                setLocalData({ ...localData, address: e.target.value.trim() })
                            }}
                        />
                        <div className={classes.buttonActions}>
                            <Button variant="contained" style={{marginRight: "20px"}} onClick={() => {setLocalData(data)}}>Annuler</Button>
                            <Button variant="contained" color="primary" onClick={() => {onUpdateData(localData)}}>Mettre à jour</Button>
                        </div>
                    </div>
                </MainContainer>
            }
        </Drawer>
    )
}

export default Layout