import * as firebase from 'firebase'

const firebaseConfig = {
    apiKey: "AIzaSyB_HmGZ6Mbyu-skOC34LS3fuv54q4iwNZo",
    authDomain: "fitnesspark-19de7.firebaseapp.com",
    databaseURL: "https://fitnesspark-19de7.firebaseio.com",
    projectId: "fitnesspark-19de7",
    storageBucket: "fitnesspark-19de7.appspot.com",
    messagingSenderId: "310563774377",
    appId: "1:310563774377:web:234876c204d883ba32eeb8",
    measurementId: "G-N1MSFN97BT"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();