import { Link } from 'react-router-dom'
import styled from 'styled-components'
import LogoWithoutBlanc from '../../lib/icons/components/LogoWithoutBlanc'

export const MainContainer = styled.div`
    height: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    text-align: center;
    justify-content: space-around;
`

export const RadioDiv = styled.div`
`

export const DivContainer = styled.form`
    box-sizing: border-box;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    width: 600px;
    height: 600px;
    max-width: 100%;
    max-height: 100%;
    justify-content: space-around;
    border-radius: 20px;
    box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    overflow: hidden;
`

export const ImageLogo = styled(LogoWithoutBlanc)`
    width: 70%;
`

export const SignupLink = styled(Link)`
    color: black;
`

export const ButtonsDiv = styled.div`
    display: flex; 
    flex-direction: column;
`