import styled from 'styled-components'
import LogoIcon from '../../lib/icons/components/LogoIcon'

export const MainContainer = styled.div`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
`

export const StepContainer = styled.div`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    max-width: 100%;
    width: 750px;
    justify-content: center;
    align-items: center;
`

export const Logo = styled(LogoIcon)`
    position: absolute;
    top: 20px;
    left: 20px;
    width: 239px;
    @media(max-width: 600px) {
        display: none   
    }
`