import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from "react-router";
import Addons from "../../components/Addons";
import FormsListView from "../../components/FormsListView";
import GymsView from "../../components/GymsView";
import GymDetail from "../../components/GymsView/GymDetail";
import HygUp from "../../components/HygUp";
import OrderView from "../../components/HygUp/OrderView";
import ReportsView from "../../components/ReportsView";
import ScanView from '../../components/ScanView';
import SettingsView from "../../components/SettingsView";
import TeamView from "../../components/TeamView";
import TemplateFormView from "../../components/TemplateFormView";
import { AppReduxState } from "../../data/types/appReduxState";
import { routes } from "../utils/routes";


interface Props extends RouteComponentProps<void> {
    isConnected: boolean
    user?: "coach" | "admin"
}

const ProtectedRoutes: React.FC<Props> = ({ history, isConnected, user }) => {
    if (!isConnected) {
        console.log("not connected")
        history.push("/login")
    }
    return (
        <Switch>
            <Route path={routes.FILL_FORM_VIEW + ":id"} component={ScanView} />
            <Route path={routes.SCAN_VIEW} component={ScanView} />
            {user === "admin" && <Route path={routes.CREATE_FORM_VIEW} component={FormsListView} />}
            <Route path={"/forms-add-preloaded"} component={FormsListView} />
            <Route path={"/print-forms"} component={FormsListView} />
            <Route path={routes.TEMPLATE_FORM_VIEW + ":id/edit"} component={TemplateFormView} />
            <Route path={routes.TEMPLATE_FORM_VIEW + ":id"} component={TemplateFormView} />
            <Route path={routes.FORMS_LIST_VIEW} component={FormsListView} />
            {user === "admin" && <Route path={routes.GYMS + "add"} component={GymsView} />}
            {user === "admin" && <Route path={routes.GYMS + ":id" + "/subscription"} component={GymDetail} />}
            {user === "admin" && <Route path={routes.GYMS + ":id"} component={GymDetail} />}
            {user === "admin" && <Route path={routes.GYMS} component={GymsView} />}
            <Route path={routes.REPORTS_DETAIL_VIEW + ":id"} component={ReportsView} />
            <Route path={routes.REPORTS} component={ReportsView} />
            <Route path={routes.TEAM} component={TeamView} />
            <Route path={routes.HYGUP_ORDER} component={OrderView} />
            <Route path={routes.HYGUP} component={HygUp} />
            <Route exact path={routes.ADDONS} component={Addons} />
            <Route path={routes.ADDONS + "/*"}><Redirect to={routes.ADDONS}/></Route>
            <Route path="/*">
                <Redirect to="/scan"/>
            </Route>

        </Switch>
    );
    
}

export default withRouter(
    connect(
        ({auth}: AppReduxState) => ({isConnected: auth.isConnected, user: auth.user})
    )(ProtectedRoutes)
)