import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
import { Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>  
    createStyles({
        main: {
        },
        products: {
            display: "flex",
            flexDirection: "row",
            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                alignItems: "center"
            },
            flexWrap: "wrap",
            maxWidth: "100%",
        },
        product: {
            margin: "20px",
            maxWidth: "100%",
            boxSizing: "border-box"
        },
        title: {
            fontWeight: "bold"
        }
    })
)