import data from './injected/appConfig.json'
export interface AppConfig {
    app: string
    hasSignUp?: boolean
    htmlTitle: string,
    primaryColor: string;
    secondaryColor: string;
    secondaryTextColor: string;
    brandName: string
}

let AppConfig: AppConfig

AppConfig = data as AppConfig

export default AppConfig
