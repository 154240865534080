import React, { useState, useEffect } from 'react'
import Layout from './Layout'
import { IForm } from '../../data/types/models/Form'
import { connect } from 'react-redux'
import { AppReduxState } from '../../data/types/appReduxState'
import { arrayFromEntitiesCollection } from '../../lib/utils/collections'
import { withRouter, RouteComponentProps, useRouteMatch } from 'react-router'
import api from '../../data/firebase/api'
import { IGym } from '../../data/types/models/Gym'
import { routes } from '../../lib/utils/routes'

interface Props extends RouteComponentProps {
    templatesForm: {[id: string]: IForm}
    dispatch: any
    user?: "coach" | "admin" 
    gym: IGym
}

const FormsListView: React.FC<Props> = ({ templatesForm, history, dispatch, user, gym }) => {
    const [isLoading, setLoading] = useState(false)
    const [forms, setForms] = useState(arrayFromEntitiesCollection(templatesForm).filter((form) => form.gymId === (gym && gym.id))) 
    const isSelectPreloadedForms = useRouteMatch("/forms-add-preloaded")?.isExact
    const isSelectFormsToPrint = useRouteMatch("/print-forms")?.isExact

    const getForms = async () => {
        setLoading(true)
        const forms = await api.getTemplatesReport(gym.id, dispatch)
        setLoading(false)
    }

    useEffect(() => {
        getForms()
    }, [gym])

    useEffect(() => {
        setForms(arrayFromEntitiesCollection(templatesForm).filter((form) => form.gymId === (gym && gym.id)))
    }, [templatesForm, gym])

    const onCreate = () => {
        history.push("/forms/create")
    }

    const onSelect = (id: string) => {
        history.push("/forms/" + id)
    }

    return (
        <Layout
            forms={forms}
            onCreate={onCreate}
            onSelect={onSelect}
            canCreate={user === "admin"}
            isSelectFormsToPrint={isSelectFormsToPrint}
            isSelectPreloadedForms={isSelectPreloadedForms}
            onSelectedPreloadedForms={() => {
                history.push(routes.FORMS_LIST_VIEW)
            }}
            onPrintForms={() => {
                history.push('/print-forms')
            }}
            isLoading={isLoading}
        />
    )
}

export default withRouter(connect(
    ({templatesForm, auth, gyms}: AppReduxState) => ({user: auth.user, templatesForm: templatesForm, gym: gyms["current"]})
)(FormsListView))