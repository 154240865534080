import { createStyles, makeStyles, Theme } from '@material-ui/core'
import styled from 'styled-components'

export const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        title: {
            fontWeight: "bold",
            marginBottom: "50px",
            textAlign: "center"
        },
        colored: {
            color: theme.palette.secondary.main
        },
        nonBold: {
            fontWeight: "normal"
        },
        stepText: {
            color: theme.palette.secondary.main,
            fontWeight: "bold",
        },
        textField: {
            margin: "22px 0"
        },
        button: {

        },
        checkInput: {
            zIndex: 0
        }
    })
)

export const MainContainer = styled.div`
    display: flex;
    box-sizing: border-box;
    width: 70%;
    align-self: center;
    padding-top: 50px;
    justify-content: center;
    align-items: center;
    height: 100%;
`

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 500px;
`

export const ButtonsDiv = styled.div`
    align-self: flex-end;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 20px 0;
`

export const CheckBoxDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -8px;
`

export const GenerateDiv = styled.div`
    align-self: center;
    margin: 25px 0;
`

export const LoaderDiv = styled.div`
    margin: 15px 0;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`