import { Addon } from "../types/models/Addons";
import { SET_ADDON, SET_ADDONS } from "../actions/addons";
import cuid from "cuid";

const addonsReducer = (
    state: {[id: string]: Addon} = {},
    {type, payload}: {type: string, payload: any}
) => {
    switch (type) {
        case SET_ADDONS:
            return payload;
        case SET_ADDON:
            state[payload.id] = payload
            return {...state};
    }
    return state;
}
  
export default addonsReducer;
  