import styled from 'styled-components'
import { makeStyles, Theme, createStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        title: {
        },
        subTitle: {
        },
        description: {
        },
        questionTitle: {
            marginBottom: "5px"            
        },
        questionDescription: {
            fontStyle: "italic"
        },
        slider: {
            marginTop: "30px"
        },
        paper: {
            padding: "10px",
            [theme.breakpoints.up('sm')]: {
                padding: "30px",
            },
            position: "relative"
        },
        overlayLoadingPictures: {
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: 'rgba(255,255,255,0.9)',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            zIndex: 10,
            left: 0,
            top: 0
        }
    })
)

export const MainContainer = styled.div`
    display: flex;
    padding: 20px 15px 59px 15px;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    box-sizing: border-box;
`

export const QuestionDiv = styled.div<{row: boolean}>`
    display: flex;
    justify-content: space-between;
    flex-direction: ${({row}) => row ? "row" : "column"};
    margin: 20px 0;
    align-items: ${({row}) => row ? "center" : "flex-start"};
`

export const ButtonActions = styled.div`
    display: flex;
    margin: 15px 0;
    place-self: center;
    flex-direction: row;
`