import preloadedForms from './forms.json'
import { IForm } from '../../../data/types/models/Form'
import AppConfig from '../../../AppConfig'

//@ts-ignore
const forms = preloadedForms as { [id:string]: IForm }
if (AppConfig.app !== "fitnesspark") {
    delete forms.ckfbjnhp900043b5ony3bx5hi;
}

export default forms