import { yupResolver } from '@hookform/resolvers';
import { Button, FormHelperText, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import * as yup from "yup";
import { FlexDiv } from '../../../../lib/ui/Divs/FlexDiv';
import { ButtonsDiv, Form, MainContainer, useStepsStyles } from '../stepsStyles';

interface Props {
    onDone: () => void
    onBack: () => void
}

const TrialInformations: React.FC<Props> = ({ onDone, onBack }) => {
    const stepsClasses = useStepsStyles()

    return (
        <MainContainer>
            <FlexDiv column alignItems="center">
                <Typography variant="h4" className={stepsClasses.title} style={{ marginBottom: "0" }}>Merci pour ta <span className={stepsClasses.colored}>confiance</span></Typography>
                <br/>
                <Typography variant="h6" className={stepsClasses.subtitle} style={{ marginBottom: "0" }}>Tu as maintenant <span style={{ fontWeight: "bold" }}>7 jours d'essai gratuit</span> pour utiliser l'application.</Typography>
                <br/>
                <Typography variant="h6" className={stepsClasses.subtitle} style={{ marginBottom: "0" }}>Tu pourras ensuite choisir de t'abonner pour <span style={{ fontWeight: "bold" }}>89,00 € HT / mois</span></Typography>
                <Typography variant="h6" className={stepsClasses.subtitle} style={{ marginBottom: "0" }}>ou <span style={{ fontWeight: "bold" }}>948,00 € HT / an</span> (soit 79,00 € HT / mois).</Typography>
                <br/>
                <Typography variant="body1" className={stepsClasses.subtitle} style={{ marginBottom: "0" }}>N'hésites pas à nous contacter via le bouton en bas à droite pour toute information supplémentaire.</Typography>
                <br/>
                <br/>
                {/* <Typography variant="body2" className={stepsClasses.stepText}>ÉTAPE FINAL</Typography> */}
                <Button onClick={onDone} variant="contained" color="primary">CREER MON ESPACE</Button>
                <br/>
                <br/>
                <ButtonsDiv>
                    <Button onClick={onBack} variant="outlined" color="primary">Revenir</Button>
                </ButtonsDiv>
            </FlexDiv>
        </MainContainer>
    )
}

export default TrialInformations