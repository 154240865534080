import { SET_GYMS, SET_GYM, SET_CURRENT_GYM } from "../actions/gyms";
import { IGym } from "../types/models/Gym";

const gymsReducer = (
    state: {
        [id: string]: IGym
    } = {},
    {type, payload}: {type: string, payload: any}
) => {
    switch (type) {
        case SET_GYMS:
            return payload;
        case SET_GYM:
            state[payload.id] = payload
            return {...state};
        case SET_CURRENT_GYM:
            let gym = state[payload.id]
            if (gym) {
                state.current = gym
            } else {
                state.current = payload
            }
            return {...state}
    }
    return state;
}
  
export default gymsReducer;
  