import React from 'react'
import { Typography } from '@material-ui/core'
import { Addon } from '../../../data/types/models/Addons'
import AddonCard from '../../../lib/ui/AddonCard'
import Drawer from '../../../lib/ui/Drawer'
import { MainContainer } from '../../../lib/ui/mainStyles'
import { FlexDiv } from '../../../lib/ui/Divs/FlexDiv'
import { useStyles } from './style'

interface Props {
    installedAddons: Addon[]
    availableAddons: Addon[]
    onSelectAddon: (addon: Addon) => void
}

const Layout: React.FC<Props> = ({ installedAddons, availableAddons, onSelectAddon }) => {
    const classes = useStyles({})
    return (
        <Drawer title={"Extensions"}>
            <MainContainer>
                <FlexDiv column className={classes.main}>
                    <Typography variant="h6" color="primary" style={{fontWeight: "bold"}}>Mes extensions</Typography>
                    <div className={classes.extensionsList}>
                        {installedAddons.map((addon) => (
                            <AddonCard addon={addon} className={classes.addon} onSelect={() => { onSelectAddon(addon) }} />
                        ))}
                        {installedAddons.length === 0 && 
                            <Typography style={{fontWeight: "bold", margin: "20px"}}>Aucune extension installée</Typography>
                        }
                    </div>
                    <Typography variant="h6" color="primary" style={{fontWeight: "bold"}}>Extensions Disponibles</Typography>
                    <div className={classes.extensionsList}>
                        {availableAddons.map((addon) => (
                            <AddonCard addon={addon} className={classes.addon} onSelect={() => { onSelectAddon(addon) }} />
                        ))}
                        {availableAddons.length === 0 && 
                            <Typography style={{fontWeight: "bold", margin: "20px"}}>Aucune extension disponible</Typography>
                        }
                    </div>
                </FlexDiv>
            </MainContainer>
        </Drawer>
    )
}

export default Layout