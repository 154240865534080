import React, { useState, useEffect } from 'react'
import Layout from './Layout'
import { RouteComponentProps, withRouter, useParams } from 'react-router'
import { connect } from 'react-redux'
import { AppReduxState } from '../../../data/types/appReduxState'
import { IForm } from '../../../data/types/models/Form'
import api from '../../../data/firebase/api'
import cuid from 'cuid'
import { IGym } from '../../../data/types/models/Gym'
import { routes } from '../../../lib/utils/routes'
import firebase from 'firebase'
import { captureException } from '@sentry/minimal'

interface Props extends RouteComponentProps<{ id: string }> {
    forms: { [id: string]: IForm }
    dispatch: any
    gym: IGym
}

const FillFormView: React.FC<Props> = ({ history, location, forms, dispatch, gym }) => {
    const { id } = useParams<{ id: string }>()
    const [errors, setErrors] = useState<{ coach?: string, pictures?: string }>({ coach: undefined, pictures: undefined })
    const form = forms[id]
    const [localForm, setLocalForm] = useState(form)
    const [isLoading, setLoading] = useState(false)
    const [pictures, setPictures] = useState<{ file: File, picture: string }[]>([])
    const [pictureSending, setPictureSending] = useState<number | undefined>(undefined)
    useEffect(() => {
        if (!form) {
            api.getTemplateReport({ id, gymId: gym.id }, dispatch).then((value) => {
                if (!value) {
                    history.push(routes.REPORTS)
                }
                setLocalForm(value)
            })
        } else {
            setLocalForm(form)
        }
    }, [form, gym])

    const onConfirm = async () => {
        setErrors({})
        if (!(localForm.author?.trim())) {
            setErrors({ coach: "L'auteur du rapport ne peux être vide" })
            return
        }
        setLoading(true)
        localForm.date = new Date()
        localForm.createdOn = new Date()
        localForm.templateId = id
        localForm.id = cuid()
        localForm.pictures = []
        if (pictures.length > 0) {
            const storageRef = firebase.storage().ref();
            for (let i = 0; i < pictures.length; i++) {
                const { file } = pictures[i]
                setPictureSending(i + 1)
                let filePath = `${localForm.gymId}/${localForm.id}/${i}-${file.name}`
                const imageRef = storageRef.child(filePath)
                try {
                    const uploadTask = await new Promise<firebase.storage.UploadTask>((resolve, reject) => {
                        const uploadTask = imageRef.put(file)
                        uploadTask.on('state_changed',
                            (snapshot) => { 
                                // Observe state change events such as progress, pause, and resume
                                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                console.log('Upload is ' + progress + '% done');
                                switch (snapshot.state) {
                                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                                        console.log('Upload is paused');
                                        break;
                                    case firebase.storage.TaskState.RUNNING: // or 'running'
                                        console.log('Upload is running');
                                        break;
                                }
                            },
                            (error) => {
                                // Handle unsuccessful uploads
                                reject(error)
                            },
                            () => {
                                // Handle successful uploads on complete
                                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                                resolve(uploadTask)
                            }
                        );
                    })
                    let url = await uploadTask.ref.getDownloadURL()
                    localForm.pictures.push({url: removeTokenParamFrom(url), firebaseFilePath: filePath})
                } catch (e) {
                    captureException(e)
                    setPictureSending(undefined)
                    setErrors({ pictures: `Une erreur est survenu durant l'envoi de la photo n°${i}, l'erreur à été envoyé au support. Si le problème persiste, contactez le support via le bouton en bas à gauche de la page` })
                    setLoading(false)
                    return
                }
            }
            setPictureSending(undefined)
        }
        if (localForm.items) {
            localForm.items.forEach((item) => {
                if (item.value === undefined) {
                    switch (item.type) {
                        case "boolean":
                            item.value = false
                            break;
                        case "mark":
                            item.value = 5
                            break;
                        default:
                            break;
                    }
                }
            })
        }
        localForm.hasIssue = !!localForm.items.find((item) => (item.type === "boolean" && item.value === false))
        await api.setReport(localForm, dispatch)
        setLoading(false)
        history.push("/reports")
    }

    const onCancel = () => {
        history.goBack()
    }
    const setValue = ({ name, value }: { name: string, value: any }) => {
        if (name === "author") {
            setErrors({ coach: undefined })
            setLocalForm({ ...form, author: value });
            return
        }
        const i = parseInt(name)
        const { type } = form.items[i]
        switch (type) {
            case "boolean":
                form.items[i].value = value
                break;
            case "note":
                form.items[i].value = value
                break;
            case "mark":
                form.items[i].value = value
                break;
            case "number":
                if (value === undefined || value === "") {
                    //@ts-ignore
                    form.items[i].value = null
                } else {
                    form.items[i].value = parseInt(value)
                }
                break;

            default:
                break;
        }
    }

    const onPicture = (files: File[], addedPictures: string[]) => {
        let newPictures = files.map((file, index) => ({ file, picture: addedPictures[index] }))
        setPictures(newPictures)
        console.log(files)
    }

    return (
        <Layout
            isLoading={isLoading}
            room={localForm && localForm.room}
            place={localForm && localForm.place}
            errors={errors}
            author={localForm && localForm.author}
            description={localForm && localForm.description}
            items={(localForm && localForm.items) || []}
            setValue={setValue}
            onConfirm={onConfirm}
            onCancel={onCancel}
            onPicture={onPicture}
            pictureSending={pictureSending}
        />
    )
}

function removeTokenParamFrom(url: string): string {
    let urlObject = new URL(url)
    urlObject.searchParams.delete("token")
    return urlObject.toString()
}

export default withRouter(
    connect(
        ({ templatesForm, gyms }: AppReduxState) => ({
            forms: templatesForm,
            gym: gyms.current
        })
    )(FillFormView))