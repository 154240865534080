import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { AppReduxState } from '../../data/types/appReduxState'
import { IGym } from '../../data/types/models/Gym'
import { arrayFromEntitiesCollection } from '../../lib/utils/collections'
import { routes } from '../../lib/utils/routes'
import AddGymDialog from './AddGymDialog'
import Layout from './Layout'

interface Props {
    gyms: IGym[]
    currentGymId: string
}

const GymsView: React.FC<Props> = ({ gyms }) => {
    const history = useHistory()

    return (
        <Fragment>
            <AddGymDialog/>
            <Layout
                gyms={gyms}
                onSelectGym={(id) => {
                    history.push(routes.GYMS + id)
                }}
                onAddGym={() => {
                    history.push(routes.GYMS + "add")
                }}
            />
        </Fragment>
    )
}

export default connect((rootState: AppReduxState) => {
    let gyms = { ...rootState.gyms }
    delete gyms.current

    return ({
        gyms: arrayFromEntitiesCollection(gyms)
    })
})(GymsView)
