import styled from 'styled-components'
import { makeStyles, Theme, createStyles, FormLabel } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        title: {
        },
        subTitle: {
        },
        description: {
        },
        questionTitle: {
            marginRight: "5px",
            fontSize: "0.8rem"
        },
        questionDescription: {
            fontStyle: "italic"
        },
        slider: {
            marginTop: "30px"
        },
        paper: {
            padding: "10px",
            [theme.breakpoints.up('sm')]: {
                padding: "30px",
            },
        },
        imageTitle: {
            marginTop: "20px"
        },
        image: {
            marginTop: "20px"
        }
    })
)

export const MainContainer = styled.div`
    display: flex;
    padding: 0px 15px 59px 15px;
    flex-direction: column;
    box-sizing: border-box;
    flex: 1;
    overflow: auto;
`

export const QuestionDiv = styled.div<{row: boolean}>`
    display: flex;
    justify-content: space-between;
    flex-direction: ${({row}) => row ? "row" : "column"};
    margin: 20px 0;
    align-items: ${({row}) => row ? "center" : "flex-start"};
`

export const ButtonActions = styled.div`
    display: flex;
    place-self: center;
    margin: 15px 0;
    flex-direction: row;
`

export const AnswerDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`