import axios, { AxiosRequestConfig, Method } from "axios";
import firebase from "firebase";
import Cookies from "js-cookie";

const API_ROOT = "https://europe-west1-fitnesspark-19de7.cloudfunctions.net/"
// const API_ROOT = "http://localhost:5001/fitnesspark-19de7/europe-west1/"
declare interface APIRequest {
    method: Method,
    endpoint: string,
    headers?: any;
    params?: any;
    data?: any;
    needToken?: boolean
}

export declare interface APIError {
    message: string
    localizedMessage: string
}

export declare interface APIResponse {
    body?: any
    error?: APIError
    httpStatus: number
}

// get the current user inside the observer
// then refresh the token
// and finally unsubscribe the observer
const getIdTokenRefreshed = async () => {
    return new Promise(async (resolve, reject) => {
        const unsubscribe = firebase
            .auth()
            .onAuthStateChanged(async user => {
                unsubscribe()
                const refreshedToken = await user
                    .getIdToken(true)
                    .catch(err => console.error(err))
                resolve(refreshedToken)
            }, reject)
    });
}


export async function sendRequest({
    method,
    data,
    endpoint,
    headers = {},
    needToken = true,
    params
}: APIRequest): Promise<APIResponse> {
    // TODO: Check that shit (getting Token from Cookies)
    if (needToken) {
        const token = await getIdTokenRefreshed()
        headers['Authorization'] = 'Bearer ' + token
    }
    try {
        const response = await axios({ headers, method, params, data, url: API_ROOT + endpoint })
        return { httpStatus: response.status, body: response.data }
    } catch (error) {
        const { response } = error
        if (!response) {
            console.log("Error on API Request :", error)
            return {
                httpStatus: 0,
                error: {
                    message: error.message,
                    localizedMessage: "Nous n'avons pas pu effectuer ta demande.\nVérifie ta connexion internet."
                }
            }
        }
        const { status, data } = response
        return { httpStatus: status, error: { message: error.message, localizedMessage: "Erreur" }, body: data }
    }
    //   if (status <= 200 && status >= 300) {
    //     return Promise.reject(data);
    //   }

    //   if (schema) return Object.assign({}, normalize(camelizedJson, schema));
    //   else return Object.assign({}, camelizedJson);
    // })
    // .catch(e => {
    //   console.log("API Error : ", e.response);
    //   throw e;
    // });
}
