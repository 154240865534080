export declare interface Addon {
    id: string;
    name: string;
    isInstalled?: boolean
    isAvailable?: boolean
    path?: string
    logoUrl?: string
    data?: any
}

export const AddonsList = {
    HygUp: "HygUp",    
}