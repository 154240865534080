import { useTheme } from "@material-ui/core";
import React from "react";

const GymIcon: React.FC<React.SVGAttributes<HTMLOrSVGElement> & { selected?: boolean }> = ({ selected, ...props }) => {
  const theme = useTheme()
  const secondary = theme.palette.secondaryText
  return (
    <svg id="XMLID_1508_" xmlns="http://www.w3.org/2000/svg" width="31.08" height="31.08" viewBox="0 0 31.08 31.08">
      <g id="XMLID_215_">
        <path id="XMLID_289_" d="M30.473,9.712H27.8V.607A.607.607,0,0,0,27.195,0H3.885a.607.607,0,0,0-.607.607V9.712H.607A.607.607,0,0,0,0,10.319V30.473a.607.607,0,0,0,.607.607H30.473a.607.607,0,0,0,.607-.607V10.319A.607.607,0,0,0,30.473,9.712ZM20.76,17V29.866H16.147V17Zm1.214,0h7.891v9.955H21.974Zm-9.166-6.07a.607.607,0,0,0,0-1.214H4.492v-8.5h22.1v8.5H18.271a.607.607,0,1,0,0,1.214H29.866v4.856H1.214V10.926Zm-3.7,16.025H1.214V17H9.105ZM1.214,28.166H9.105v1.7H1.214ZM10.319,17h4.613V29.866H10.319ZM29.866,29.866H21.974v-1.7h7.891Z" fill={selected ? secondary : "#000000"} />
        <path id="XMLID_312_" d="M307.173,46.024a.607.607,0,0,0,.705-.49l.481-2.663.794,2.69.007.021a.715.715,0,0,0,.679.488h0a.716.716,0,0,0,.678-.5l.006-.021.766-2.688.511,2.673A.607.607,0,1,0,313,45.313l-.9-4.73v-.007a.717.717,0,0,0-1.384-.078l-.006.021-.87,3.052-.9-3.058-.007-.022a.717.717,0,0,0-1.383.093v.007l-.854,4.729a.607.607,0,0,0,.49.705Z" transform="translate(-288.058 -37.573)" fill={selected ? secondary : "#000000"} />
        <path id="XMLID_313_" d="M106.379,42.428h-1.453a.607.607,0,0,0,0,1.214h.775a1.419,1.419,0,0,1-1.419,1.214,1.821,1.821,0,1,1,1.018-3.331.607.607,0,0,0,.68-1.006,3.035,3.035,0,1,0-1.7,5.552,2.758,2.758,0,0,0,2.7-3.035A.607.607,0,0,0,106.379,42.428Z" transform="translate(-95.102 -37.572)" fill={selected ? secondary : "#000000"} />
        <path id="XMLID_314_" d="M217.326,45.462a.607.607,0,1,0,1.214,0v-2.3l1.442-2.212a.607.607,0,0,0-1.017-.663l-1.032,1.583-1.041-1.6a.607.607,0,0,0-1.017.663l1.451,2.224Z" transform="translate(-202.679 -37.57)" fill={selected ? secondary : "#000000"} />
        <path id="XMLID_323_" d="M63.677,364.025a.607.607,0,0,0,.858,0l2.489-2.489a.607.607,0,0,0-.859-.859l-2.489,2.489A.607.607,0,0,0,63.677,364.025Z" transform="translate(-59.645 -338.617)" fill={selected ? secondary : "#000000"} />
        <path id="XMLID_324_" d="M38.844,313.858a.607.607,0,0,0,.858,0l2.489-2.489a.607.607,0,0,0-.859-.859L38.844,313A.607.607,0,0,0,38.844,313.858Z" transform="translate(-36.319 -291.495)" fill={selected ? secondary : "#000000"} />
        <path id="XMLID_326_" d="M409.024,360.679a.607.607,0,0,0-.859,0l-2.489,2.489a.607.607,0,1,0,.858.859l2.489-2.489A.607.607,0,0,0,409.024,360.679Z" transform="translate(-380.885 -338.618)" fill={selected ? secondary : "#000000"} />
        <path id="XMLID_327_" d="M384.191,310.512a.607.607,0,0,0-.859,0L380.844,313a.607.607,0,1,0,.858.859l2.489-2.489A.607.607,0,0,0,384.191,310.512Z" transform="translate(-357.559 -291.496)" fill={selected ? secondary : "#000000"} />
        <path id="XMLID_328_" d="M246.607,160.04a.606.606,0,1,0,.429.177A.611.611,0,0,0,246.607,160.04Z" transform="translate(-231.067 -150.325)" fill={selected ? secondary : "#000000"} />
      </g>
    </svg>
  );
}

export default GymIcon;
