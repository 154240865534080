import { SET_USER } from "../actions/users";
import { IForm } from "../types/models/Form";

const userReducer = (
    state: any = null,
    {type, payload}: {type: string, payload: any}
) => {
    switch (type) {
        case SET_USER:
            return payload;
    }
    return state;
}
  
export default userReducer;