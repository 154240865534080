import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'
import cuid from 'cuid'

import Layout from './Layout'
import { IForm, IFormItem } from '../../../data/types/models/Form'
import { AppReduxState } from '../../../data/types/appReduxState'
import { withRouter, RouteComponentProps, useRouteMatch } from 'react-router'
import { routes } from '../../../lib/utils/routes'
import api from '../../../data/firebase/api'
import { IGym } from '../../../data/types/models/Gym'
import { arrayFromEntitiesCollection } from '../../../lib/utils/collections'

interface Values {
    room?: string;
    place?: string;
    description?: string;
}

interface Props extends RouteComponentProps<{id: string}> {
    templates: {[id: string]: IForm}
    dispatch: any
}

const EditFormView: React.FC<Props> = ({ match, history, dispatch, templates }) => {
    const routeMatch = useRouteMatch<{id: string}>(routes.TEMPLATE_FORM_VIEW + ":id/edit")
    let template = routeMatch?.params.id && templates[routeMatch.params.id]
    const [items, setItems] = useState<IFormItem[]>(template ? template.items : [])
    const [values, setValues] = useState<any>(template || {})
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        if (template) {
            console.log("useeffect", template)
            setItems(template.items)
            setValues(template)
            setLoading(false)    
        }
    }, [routeMatch?.isExact, template])
    if (!routeMatch?.isExact || !template) {
        return null
    }
    return (
        <Layout 
            onCancel={() => {
                history.push(routes.FORMS_LIST_VIEW + "/" + routeMatch?.params.id)
            }}
            isLoading={isLoading}
            onValid={async () => {
                const form: IForm = {
                    id: routeMatch?.params.id,
                    createdOn: template.createdOn,
                    gymId: template.gymId,
                    items: items,
                    description: values.description,
                    room: values.room,
                }
                setLoading(true)
                await api.setTemplate(form, dispatch)
                history.push(routes.FORMS_LIST_VIEW)
            }}
            setValue={({name, value}) => {
                values[name] = value
                setValues({...values})
            }}
            onAddItem={() => {
                items.push({
                    type: "boolean",
                    label: ""
                })
                setItems(items.slice())
            }}
            onModifyItem={(item, index) => {
                items[index] = item
                setItems(items.slice())
            }}
            onDelete={(index) => {
                items.splice(index, 1)
                setItems(items.slice())
            }}
            values={values}
            items={items}
        />
    )
}

interface TStateProps {
    templates: {[id: string]: IForm}
}

export default withRouter(
    connect<TStateProps>(
    ({templatesForm}: AppReduxState) => {
        return {
            templates: templatesForm
        }}
    )(EditFormView)
)