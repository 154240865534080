import styled from 'styled-components'
import { makeStyles, createStyles, Theme } from '@material-ui/core'
import qrcode from 'qrcode.react'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            margin: "0px 0 30px 0",
        },
        paper: {
            paddingBottom: "30px",
            boxSizing: "border-box",
            [theme.breakpoints.up('sm')]: {
                padding: "30px",
            },
        },
        questionsTextField: {
            margin: "0 5px",
            flex: 1
        },
        formLabel: {
            fontSize: "0.8rem"
        },
        deleteIcon: {
            "&:hover": {
                cursor: "pointer"
            }
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        formControl: {
            width: "45%",
            margin: "10px 0",
            [theme.breakpoints.down("sm")]: {
                width: "90%",
            }
        }
    })
)

export const FormContainer = styled.form`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    @media(max-width: 600px) {
        flex-direction: row;
    }
    padding: 0px 15px;
    background: white;
    box-sizing: border-box;
    overflow-y: auto;
    margin: 20px 0;
`

export const ItemDiv = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    background: white;
`

export const ItemsHeader = styled.div`
    flex-direction: row;
    padding: 0px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const MinMaxDiv = styled.div`
    flex-direction: row;
    display: flex;
`

export const QuestionDiv = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px 0;
`

export const ButtonActions = styled.div`
    display: flex;
    place-self: center;
    flex-direction: row;
`

export const PrintDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid black;
    width: 33.333%;
    height: 25%;
    box-sizing: border-box;
`

export const PrintContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
    @media print {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
    }
`

export const QRCodeView = styled(qrcode)`
    margin: 5px 0;
`
