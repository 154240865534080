import React from 'react'
import { MainContainer, ReportsList, useStyles, FilterDiv } from './style'
import { List, ListItem, ListItemText, ListItemSecondaryAction, ListItemIcon, ListSubheader, Badge, FormControl, InputLabel, Select, Input, Chip, MenuItem, ButtonGroup, Button, Typography, Hidden, Popover, useTheme, CircularProgress } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { IForm } from '../../../data/types/models/Form'
import { formatTime, sameDay } from '../../../lib/utils/date';
import Drawer from '../../../lib/ui/Drawer';
import ReportDetailView from '../ReportDetailView';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ErrorIcon from '@material-ui/icons/Error';
import { DatePicker } from "@material-ui/pickers";
import "moment/locale/fr";
import moment from "moment";
import { ReportsFilters } from '../types';
import { FlexDiv } from '../../../lib/ui/Divs/FlexDiv';

moment.locale("fr"); // it is required to select default locale manually

interface Props {
    forms: IForm[][]
    allForms: IForm[][]
    onSelect: (id: string) => void
    reportDetailView: boolean
    templates: IForm[]
    authors: string[]
    onChange: (filter: { name: string, value: any }) => void
    filters: ReportsFilters
    onReset: () => void
    isLoading?: boolean
}

const dateTimeFormat = new Intl.DateTimeFormat('fr', { year: 'numeric', month: 'short', day: '2-digit', weekday: "long" })

const Layout: React.FC<Props> = ({ forms, onSelect, reportDetailView, templates, onChange, filters, authors, allForms, onReset, isLoading }) => {
    const classes = useStyles()
    const theme = useTheme()
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const FiltersView = () => (
        <FilterDiv>
            <DatePicker
                autoOk
                variant="static"
                openTo="date"
                value={filters.date}
                onChange={(date) => { onChange({ name: "date", value: date }) }}
                renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
                    let isSelected = isInCurrentMonth && !!allForms.find((forms) => forms.length > 0 && sameDay(day.toDate(), forms[0].date))
                    // You can also use our internal <Day /> component
                    return <Badge variant="dot" color="primary" invisible={!isSelected}>{dayComponent}</Badge>;
                }}
                orientation="portrait"
            />
            <br />
            <br />
            <InputLabel style={{ alignSelf: "flex-start" }} id="demo-mutiple-chip-template">Rapports :</InputLabel>
            <br />
            <ButtonGroup color="primary" aria-label="outlined primary button group">
                <Button
                    onClick={() => { onChange({ name: "issue", value: false }) }}
                    variant={filters.issue === false ? "contained" : undefined}
                ><DoneAllIcon style={{ color: filters.issue === false ? theme.palette.secondaryText : theme.palette.primary.main }} /></Button>
                <Button
                    onClick={() => { onChange({ name: "issue", value: null }) }}
                    variant={filters.issue === undefined || filters.issue === null ? "contained" : undefined}
                // color={filters.issue === undefined || filters.issue === null ? "secondary" : "primary"}
                >Tous</Button>
                <Button
                    onClick={() => { onChange({ name: "issue", value: true }) }}
                    variant={filters.issue === true ? "contained" : undefined}
                ><ErrorIcon color={"error"} /></Button>
            </ButtonGroup>
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-chip-template">Sélectionner des pièces</InputLabel>
                <Select
                    labelId="demo-mutiple-chip-template"
                    id="demo-mutiple-chip"
                    multiple
                    value={filters.templates}
                    onChange={(e) => { onChange({ name: "templates", value: e.target.value }) }}
                    input={<Input id="select-multiple-template" />}
                    renderValue={(selected: string[]) => (
                        <div className={classes.chips}>
                            {selected.map((value, index) => (
                                <Chip
                                    key={value}
                                    color="primary"
                                    label={templates.find((template) => template.id === value).room}
                                    className={classes.chip}
                                    onDelete={() => {
                                        let newArray = selected.slice()
                                        newArray.splice(index, 1)
                                        onChange({ name: "templates", value: newArray })
                                    }}
                                />
                            ))}
                        </div>
                    )}
                // MenuProps={MenuProps}
                >
                    {templates.map((template) => (
                        <MenuItem key={template.id} value={template.id}>
                            {template.room}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-chip-authors">Sélectionner des auteurs</InputLabel>
                <Select
                    labelId="demo-mutiple-chip-authors"
                    id="demo-mutiple-chip"
                    multiple
                    value={filters.authors}
                    onChange={(e) => { onChange({ name: "authors", value: e.target.value }) }}
                    input={<Input id="select-multiple-authors" />}
                    renderValue={(selected: string[]) => (
                        <div className={classes.chips}>
                            {selected.map((value, index) => (
                                <Chip
                                    key={value}
                                    color="primary"
                                    label={value}
                                    className={classes.chip}
                                    onDelete={() => {
                                        let newArray = selected.slice()
                                        newArray.splice(index, 1)
                                        onChange({ name: "authors", value: newArray })
                                    }}
                                />
                            ))}
                        </div>
                    )}
                // MenuProps={MenuProps}
                >
                    {authors.map((author) => (
                        <MenuItem key={author} value={author}>
                            {author}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div className={classes.buttonsFiltersDiv}>
                <Hidden mdUp implementation="js">
                    <Button variant="contained" color="primary" onClick={handleClose}>Appliquer les filtres</Button>
                    <div style={{ height: "20px", width: "20px" }} />
                </Hidden>
                <Button variant="contained" color="secondary" onClick={onReset}>Réinitialiser les filtres</Button>
            </div>
        </FilterDiv>
    )

    return (
        <Drawer title={"Rapports"}>
            {reportDetailView && <ReportDetailView />}
            <MainContainer>
                {isLoading &&
                    <div className={classes.loadingLayer}>
                        <CircularProgress color='secondary' size={50} />
                    </div>
                }

                <Hidden mdUp implementation="js">
                    <div style={{ width: "100%", textAlign: "right", paddingRight: "20px", boxSizing: "border-box" }}>
                        <Button variant="contained" color="primary" onClick={handleClick}>
                            Filtres
                        </Button>
                    </div>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        classes={{
                            paper: classes.paperFilter
                        }}
                    >
                        <FiltersView />
                    </Popover>
                </Hidden>
                <Hidden smDown implementation="js">
                    <FiltersView />
                </Hidden>
                <ReportsList>
                    {forms.length > 0 ? <List component="nav" style={{ flex: 1, overflowY: "auto" }} subheader={<li />}>
                        {
                            forms.map((dayForms, index) => (
                                <li key={`section-${index}`} className={classes.listSection}>
                                    <ul className={classes.ul}>
                                        {dayForms[0] && <ListSubheader color="primary" classes={{ root: classes.subHeaderTitle }}><span role="img" aria-label="date emoji" style={{ marginRight: "10px" }}>📅</span>{dateTimeFormat.format(dayForms[0].date).toLocaleUpperCase()}</ListSubheader>}
                                        {dayForms.map((form) => {
                                            let hasIssue = form.items.find((item) => item.type === "boolean" && item.value === false)
                                            return (
                                                <ListItem button onClick={() => { onSelect(form.id) }}>
                                                    <ListItemText primary={`${form.room}`} secondary={form.author ? `${formatTime(form.date)} par ${form.author}` : `${formatTime(form.date)}`} />
                                                    <ListItemSecondaryAction>
                                                        <ListItemIcon style={{ justifyContent: "flex-end" }}>
                                                            {hasIssue ? <ErrorIcon color="error" /> : <DoneAllIcon color="primary" />}
                                                        </ListItemIcon>
                                                        <ListItemIcon style={{ justifyContent: "flex-end" }}>
                                                            <ArrowForwardIosIcon />
                                                        </ListItemIcon>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            )
                                        })}
                                    </ul>
                                </li>
                            ))
                        }
                    </List>
                        :
                        <Typography color="primary" variant="h5">Aucun rapport n'a été trouvé</Typography>
                    }
                </ReportsList>
            </MainContainer>
        </Drawer>
    )
}

export default Layout