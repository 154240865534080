import React from 'react'

import { Typography, List, ListItem, ListItemText, Divider, ListItemSecondaryAction, ListItemIcon, Button, CircularProgress } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AddIcon from '@material-ui/icons/Add';

import Menu from '../../../lib/ui/Menu'
import { useMainStyles, MainContainer } from '../../../lib/ui/mainStyles'
import { IForm } from '../../../data/types/models/Form'
import { useStyles } from './style';
import Drawer from '../../../lib/ui/Drawer';
import CreateFormView from '../CreateFormView';
import SelectPreLoadedForms from '../SelectPreLoadedForms';
import AppConfig from '../../../AppConfig';
import SelectFormsToPrint from '../SelectFormsToPrint';

interface Props {
    forms: IForm[]
    canCreate: boolean
    onCreate: () => void;
    onSelect: (id: string) => void
    isSelectPreloadedForms: boolean
    onSelectedPreloadedForms: () => void
    isSelectFormsToPrint: boolean
    onPrintForms: () => void
    isLoading?: boolean
}

const Layout: React.FC<Props> = ({ forms, onCreate, onSelect, canCreate, isSelectPreloadedForms, onSelectedPreloadedForms, isSelectFormsToPrint, onPrintForms, isLoading}) => {
    const mainClasses = useMainStyles()
    const classes = useStyles({})

    return (
        <Drawer
            title={"Formulaires"}
            buttonAction={canCreate ? {title: "Ajouter un formulaire", onClick: onCreate} : undefined}
        >
            <CreateFormView/>
            {isSelectPreloadedForms && <SelectPreLoadedForms onDone={onSelectedPreloadedForms}/>}
            {isSelectFormsToPrint && <SelectFormsToPrint/>}
            {isLoading && 
                <div className={classes.loadingLayer}>
                    <CircularProgress color="secondary" size={50}/>
                </div>
            }
            <MainContainer className="no-print">
                <List component="nav">
                    {
                        forms.map((form) => (
                            <section key={`${form.id}`}>
                                <ListItem button onClick={() => {onSelect(form.id)}}>
                                    <ListItemText 
                                        primary={(AppConfig.app === "fitcarebarber" ? "💈" : "📍") + " " + form.room}
                                        secondary={`📝 ${form.items.length} questions`}
                                        classes={{primary: classes.listItemPrimaryText}}
                                    />
                                    <ListItemSecondaryAction>
                                        <ListItemIcon style={{ justifyContent: "flex-end" }}>
                                            <ArrowForwardIosIcon/>
                                        </ListItemIcon>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </section>
                        ))
                    }
                </List>
                <Button onClick={onPrintForms} variant="contained" color="primary" className={classes.printAllButton}>Imprimer les QR-Code</Button>
            </MainContainer>
        </Drawer>
    )
}

export default Layout