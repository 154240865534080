import { Button, InputAdornment, TextField, Typography } from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import AppConfig from '../../../../AppConfig'
import { IGym } from '../../../../data/types/models/Gym'
import { FlexDiv } from '../../../../lib/ui/Divs/FlexDiv'
import Drawer from '../../../../lib/ui/Drawer'
import { MainContainer } from '../../../../lib/ui/mainStyles'
import { useStyles, Form, GenerateDiv } from './styles'

interface Props {
    gym: IGym
    onGenerateNewPassword: () => void
    onSubscribe: () => void
}

const Layout: React.FC<Props> = ({ gym, onGenerateNewPassword, onSubscribe }) => {
    const classes = useStyles({})

    let subscriptionView = (
        <>
        </>
    )
    let subscription = gym.subscription
    if (subscription) {
        switch (subscription.status) {
            case "trialing":
                subscriptionView = (
                    <FlexDiv width="100%" margin="10px" column>
                        <Typography>En <Typography display="inline" color="primary" style={{fontWeight: "bold"}}>periode d'essai</Typography> jusqu'au {moment(subscription.trial_end * 1000).format("DD/MM/YYYY")}</Typography>
                        <br/>
                        <Button variant="contained" color="primary" onClick={onSubscribe} style={{ alignSelf: "flex-end" }}>Démarer l'abonnement dès aujourd'hui</Button>
                    </FlexDiv>
                )
                break;
            case "active":
                subscriptionView = (
                    <FlexDiv width="100%" margin="10px" column>
                        <Typography>Abonnement <Typography display="inline" color="primary" style={{fontWeight: "bold"}}>actif</Typography></Typography>
                        <br/>
                        {/* <Button variant="contained" color="primary" onClick={onSubscribe} style={{ alignSelf: "flex-end" }}>Démarer l'abonnement dès aujourd'hui</Button> */}
                    </FlexDiv>
                )
        }
    } else {
        subscriptionView = (
            <>
                <Typography>Aucun abonnement attaché à cette salle</Typography>
                <Button variant="contained" color="primary" onClick={onSubscribe} style={{ alignSelf: "flex-end" }}>S'abonner</Button>
            </>
        )
    }
    return (
        <Drawer title={gym.name}>
            <MainContainer>
                <Form>
                    <TextField
                        value={gym.salesPassword}
                        label={"Mot de passe de l'espace " + (AppConfig.app === "fitcarebarber" ? "coiffeur" : "commercial")}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <CopyToClipboard text={gym.salesPassword}>
                                        <Button
                                            aria-label="toggle password visibility"
                                            color="secondary"
                                            style={{ fontWeight: 'bold' }}
                                        >
                                            {"COPIER LE MDP"}
                                        </Button>
                                    </CopyToClipboard>
                                </InputAdornment>
                            ),
                            style: {
                                display: "flex",
                                fontWeight: "bold"
                            }
                        }}
                        helperText={"Le mot de passe est unique et est partagé entre tous les " + (AppConfig.app === "fitcarebarber" ? "coiffeurs" : "commerciaux")}
                    />
                    <GenerateDiv>
                    </GenerateDiv>
                </Form>
                <Typography variant="h6" className={classes.sectionTitle}>Etat de l'abonnement</Typography>
                {subscriptionView}
            </MainContainer>
        </Drawer>
    )
}

export default Layout