import { IFormItem } from "../../data/types/models/Form";

export function getLabelForQuestionType({type}: IFormItem): string {
    switch (type) {
        case "boolean":
            return "Vrai ou faux"
        case "note":
            return "Commentaire"
        case "number":
            return "Nombre"
        case "mark":
            return "Note sur 10"
        case "boolean":
            return "Vrai ou faux"
        default:
            return ""
    }
}