import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import QrCodeIcon from '../../icons/components/QRCodeIcon';
import ReportsIcon from '../../icons/components/ReportsIcon';
import FormsIcon from '../../icons/components/FormsIcon';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import TeamIcon from '../../icons/components/TeamIcon';
import { CSSProperties } from 'react';
import AddonIcon from '../../icons/components/AddonIcon';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import GymIcon from '../../icons/components/GymIcon';

interface MenuItemModel {
    label: string;
    value: string;
    link: string;
    icon: React.FC<{selected?: boolean, style?: CSSProperties}>
    adminOnly?: boolean
}

const items: MenuItemModel[] = [
    {
        label: "QR Code",
        value: "scan",
        link: "/scan",
        icon: QrCodeIcon
    },
    {
        label: "Rapports",
        value: "reports",
        link: "/reports",
        icon: ReportsIcon
    },
    {
        label: "Formulaires",
        value: "forms",
        link: "/forms",
        icon: FormsIcon
    },
    {
        label: "Equipe",
        value: "team",
        link: "/team",
        icon: TeamIcon
    },
    {
        label: "Mes salles",
        value: "gyms",
        link: "/gyms",
        icon: GymIcon,
        adminOnly: true
    },
    // {
    //     label: "Extensions",
    //     value: "addons",
    //     link: "/addons",
    //     icon: AddonIcon
    // },
    {
        label: "Se deconnecter",
        value: "logout",
        link: "/login",
        icon: MeetingRoomIcon
    }
]

export default items;