import React from 'react'
import { Button, Dialog, DialogActions, DialogContentText, DialogTitle, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { useStyles } from './styles'
import { FlexDiv } from '../../../../lib/ui/Divs/FlexDiv'
import { ButtonActions } from '../../../ReportsView/ReportDetailView/Layout/style'
import { FormValues } from '../types'

interface Props {
    values: FormValues
    errors: FormValues
    setValue: (props: {value: string, name: string}) => void
    onNext: () => void
    onCancel: () => void
}

const Layout: React.FC<Props> = ({ errors, values, setValue, onNext, onCancel }) => {

    const classes = useStyles({})
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const onChange = (event: any) => {
        setValue({name: event.target.name, value: event.target.value})
    }

    return (
        <Dialog fullWidth fullScreen={!matches} open={true} classes={{ paper: classes.paper }}>
            <DialogTitle style={{ fontWeight: "bold" }} disableTypography>
                <Typography variant="h5" style={{ fontWeight: "bold" }}>Installation de l'extension Hyg'Up</Typography>
            </DialogTitle>
            <DialogContentText>Hyg'Up te fournit en matériel de nettoyage et tu disposes d'un compte chez eux ?</DialogContentText>
            <DialogContentText>Avec notre partenariat, nous te permettons de commander leurs produits directement depuis notre plateforme en un simple click ou via un QRCode.</DialogContentText>
            <DialogContentText>On a juste besoin des informations suivantes : </DialogContentText>
            <FlexDiv column>
                <TextField
                    label="Numéro de compte Hyg'Up"
                    color="primary"
                    value={values.accountNumber}
                    error={!!errors.accountNumber}
                    helperText={!!errors.accountNumber && errors.accountNumber}
                    name={"accountNumber"}
                    onChange={onChange}
                    className={classes.textField}
                />
                <TextField
                    label="Adresse de livraison"
                    color="primary"
                    value={values.address}
                    error={!!errors.address}
                    helperText={!!errors.address && errors.address}
                    name={"address"}
                    className={classes.textField}
                    onChange={onChange}
                />
            </FlexDiv>
            <ButtonActions>
                <Button onClick={onCancel} variant="contained" style={{ marginRight: "10px" }}>Annuler</Button>
                <Button onClick={onNext} color="primary" variant="contained" style={{ marginRight: "10px" }}>Suivant</Button>
            </ButtonActions>
        </Dialog>
    )
}

export default Layout