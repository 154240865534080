import React, { useEffect, useState } from 'react'
import { useStyles } from './styles'
import clsx from 'clsx'
import { FirebasePicture } from '../../../data/types/models/FirebasePicture'
import firebase from 'firebase'
import { captureException } from '@sentry/minimal'

interface Props {
	className?: string
	picture: FirebasePicture
}

const FirebaseImg: React.FC<Props> = ({ picture, className }) => {
	const classes = useStyles({})
	const [url, setUrl] = useState(picture.url)
	const [error, setError] = useState<string | undefined>(undefined)
	useEffect(() => {
		firebase.storage().ref(picture.firebaseFilePath).getDownloadURL().then((url) => {
			setUrl(url)
		}).catch((e) => {
			captureException(e)
			setError(e.toString())
		})
	}, [picture.firebaseFilePath])
	return (
		<img className={clsx([classes.image, className])} src={url} />
	)
}

export default FirebaseImg