import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
import { Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>  
    createStyles({
        paramsDiv: {
            display: "flex",
            flexDirection: "column",
            marginTop: "40px"
        },
        textField: {
            margin: "20px 0"
        },
        buttonActions: {
            alignSelf: "flex-end"
        },
        addonActions: {
            marginTop: "20px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            [theme.breakpoints.down('sm')]: {
                flexDirection: "column",
                alignItems: "center"
            }

        }
    })
)