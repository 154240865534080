export const LOGIN_ACTION_TYPE = "LOGIN_ACTION_TYPE"

export const loginAction = ({type}: {type: "coach" | "admin"}) =>
      ({
            type: LOGIN_ACTION_TYPE,
            payload: type
      });

export const LOGOUT_ACTION_TYPE = "LOGOUT_ACTION_TYPE"

export const logoutAction = () =>
  ({
      type: LOGOUT_ACTION_TYPE,
  });
