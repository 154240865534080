import { combineReducers } from "redux";
import { AppReduxState } from "../types/appReduxState";

import formsReducer from "./forms";
import templatesFormReducer from "./templatesForm";
import userReducer from "./user";
import authReducer from "./auth";
import gymsReducer from "./gyms";
import { LOGOUT_ACTION_TYPE } from "../actions/auth";
import addonsReducer from "./addons";


const appReducer = combineReducers({
    forms: formsReducer,
    templatesForm: templatesFormReducer,
    user: userReducer,
    auth: authReducer,
    gyms: gymsReducer,
    addons: addonsReducer,
});

const rootReducer = (state?: AppReduxState, action?: any) => {
  const { type } = action;
  if (type && type === LOGOUT_ACTION_TYPE) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;