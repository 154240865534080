import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
import { Theme, createStyles } from '@material-ui/core';
import { BOX_SHADOW } from '../../../../lib/ui/palette';

export const useStyles = makeStyles((theme: Theme) =>  
    createStyles({
        main: {
            display: "flex",
            flexDirection: "row",
            width: "300px",
            height: "150px",
            boxShadow: theme.shadows[5],
            padding: "10px",
            borderRadius: "10px",
            alignItems: "center",
            justifyContent: "space-around"
        },
        image: {
            width: "130px",
            height: "130px",
            objectFit: "contain"
        },
        productTitle: {
            textAlign: "center",
            // marginTop: "10px",
            fontWeight: "bold"
        },
        quantityDiv: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center"
        },
        quantityTextField: {
            width: "50px"
        },
        quantityInput: {
            textAlign: "center",
            fontSize: "30px",
            fontWeight: "bold"
        },
        details: {
            display: "flex",
            flexDirection: "column",
            marginLeft: "15px"
        }
    })
)