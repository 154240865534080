import { Button, Dialog, DialogTitle, FormLabel, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { FirebasePicture } from '../../../../data/types/models/FirebasePicture';
import { IFormItem } from '../../../../data/types/models/Form';
import FirebaseImg from '../../../../lib/ui/FirebaseImg';
import { formatDateTime } from '../../../../lib/utils/date';
import { AnswerDiv, ButtonActions, MainContainer, QuestionDiv, useStyles } from './style';

interface Props {
    room?: string
    place?: string
    description?: string;
    author?: string;
    date?: Date;
    items: IFormItem[];
    pictures?: FirebasePicture[]
    onCancel: () => void,
    confirmDelete?: boolean
    onDeleteModal: (boolean: boolean) => void
    onConfirmDelete: () => void
    canDelete: boolean
}

const Layout: React.FC<Props> = ({ 
    onCancel, 
    room,
    place,
    author,
    items,
    date,
    description,
    confirmDelete,
    onDeleteModal,
    onConfirmDelete,
    canDelete,
    pictures = []
}) => {
    const classes = useStyles()

    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    if (confirmDelete && canDelete) {
        return (
            <Dialog fullWidth open={confirmDelete} classes={{paper: classes.paper}}>
            <DialogTitle style={{fontWeight: "bold"}} disableTypography>
                <Typography variant="h5" style={{fontWeight: "bold"}}>{"Supprimer ce formulaire ?"}</Typography>
            </DialogTitle>
            <ButtonActions>
                <Button onClick={() => {onDeleteModal(false)}} variant="contained" style={{marginRight: "10px"}}>Annuler</Button>
                <Button onClick={onConfirmDelete} color="primary" variant="contained">Supprimer le formulaire</Button>
            </ButtonActions>
            </Dialog>
        )
    } 
    return (
        
        <Dialog fullWidth fullScreen={!matches} open={true} classes={{paper: classes.paper}}>
            <DialogTitle style={{fontWeight: "bold"}} disableTypography>
                <Typography variant="h5" style={{fontWeight: "bold"}}>{room || "Completion du formulaire"}</Typography>
            </DialogTitle>
            <MainContainer>
                <Typography variant="h6" style={{textAlign: "center"}}>{description}</Typography>
                <br/>
                <AnswerDiv style={{alignSelf: "center"}}>
                    <FormLabel className={classes.questionTitle}>{`Par ${author || "-"} ${formatDateTime(date)}`}</FormLabel>
                </AnswerDiv>
                <br/>
                <br/>
                {items && items.map((item, index) => (
                    <QuestionDiv row={item.type === "boolean" || item.type === "number" || item.type === "mark"} key={item.label + item.type}>
                        <FormLabel>{item.label}</FormLabel>
                        {
                            item.type === "note" && 
                            <Typography>{item.value || "Non renseigné"}</Typography>
                        }
                        {
                            item.type === "boolean" && 
                            <Typography>{item.value ? "✅" : "❌"}</Typography>
                        }
                        {
                            item.type === "mark" && 
                            <Typography>{item.value ? item.value + " / 10" : "Non renseigné"}</Typography>
                        }
                        {
                            item.type === "number" && 
                            <Typography>{item.value || "Non renseigné"}</Typography>
                        }
                    </QuestionDiv>    
                ))}
                {pictures.length > 0 && 
                    <FormLabel className={classes.imageTitle}>{"Photos :"}</FormLabel>
                }
                {pictures.map((picture) => (
                    <FirebaseImg picture={picture} key={picture.firebaseFilePath} className={classes.image}/>
                ))}
            </MainContainer>
                <ButtonActions>
                    <Button onClick={onCancel} variant="contained" style={{marginRight: "10px"}}>Retour</Button>
                    {canDelete &&
                        <Button onClick={() => {onDeleteModal(true)}} color="primary" variant="contained" style={{marginRight: "10px"}} startIcon={<DeleteIcon/>}>Supprimer</Button>
                    }
                </ButtonActions>
        </Dialog>
    )
}

export default Layout