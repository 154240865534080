export const LINEAR_GRADIENT = (direction: string) =>
  `linear-gradient(to ${direction}, #eb5e27, #ef7425, #f38825, #f59c2a, #f6af32);`;

export const LINEAR_GRADIENT_PROGRESSIVE = (direction: string, value: string) =>
  `linear-gradient(to ${direction}, #eb5e27 0%, #f6af32 ${value}%, #E5E5E5 ${value}%, #E5E5E5 100%);`;

export const BORDER_RADIUS_1 = "10px";
export const BORDER_RADIUS_2 = "15px";
export const BORDER_RADIUS_3 = "30px";

export const PRIMARY_FONT_SIZE = "0.83em";

export const PRIMARY_BORDER = "1px solid whitesmoke";
export const ERROR_BORDER = "1px solid red";
export const PLACEHOLDER_COLOR = "#8b959a";

export const BOX_SHADOW = "10px 10px 50px 0px rgba(0,0,0,0.08)";
export const BOX_SHADOW_HOVER = "10px 10px 50px 0px rgba(0,0,0,0.1)";
export const BOX_SHADOW_INACTIVE_BUTTON = "0 0 3px 2px #EB5E27";
export const BOX_SHADOW_ACTIVE_BUTTON = "0 0 1px 2px #EB5E27";
