import { createMuiTheme } from "@material-ui/core";
import { BOX_SHADOW, LINEAR_GRADIENT, BOX_SHADOW_HOVER } from "./palette";

export default ({primaryColor, secondaryColor, secondaryTextColor}: {primaryColor: string, secondaryColor: string, secondaryTextColor: string}) => createMuiTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  props: {
  },
  palette: {
    primary: {
      main: primaryColor
    },
    secondary: {
      main: secondaryColor
    },
    secondaryText: secondaryTextColor
  },
  overrides: {
    MuiTypography: {
      noWrap: {
        overflow: "unset"
      }
    },
    MuiBottomNavigation: {
        root: {
            borderTop: "solid 1px rgb(117, 117, 117)",
            zIndex: 1,
        }
    },
    MuiBottomNavigationAction: {
      root: {
        '&$selected': {
          backgroundColor: primaryColor,
          color: secondaryColor,
        }
      }
    },
    // AppBar
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "white",
        color: "black",
        boxShadow: "unset",
      },
    },
    // TextField
    MuiOutlinedInput: {
      root: {
        backgroundColor: "white",
        paddingRight: "28px",
      }
    },
    MuiFormLabel: {
      root: {
        color: "black",
        fontWeight: "bold",
      }
    },
    //@ts-ignore
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        // border: "1px solid black",
        boxShadow: BOX_SHADOW,
        overflow: "unset"
      }
    },
    MuiList: {
      root: {
        width: "100%",
        backgroundColor: "white",
      }
    },
    MuiSelect: {
      root: {
        boxSizing: "border-box",
      }
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: primaryColor,
          color: secondaryTextColor,
          '&:hover': {
            backgroundColor: primaryColor + "CC",
          }
        },
      }
    },
    MuiListItemText: {
      root: {
        display: "block"
      }
    },
    MuiInputBase: {
      root: {
        display: "block"
      }
    },
    MuiFormControl: {
      root: {
        display: "block"
      }
    },
    MuiListItemIcon: {
      root: {
        color: "rgba(0, 0, 0, 0.54)",
        display: "inline-flex",
        minWidth: "56px",
        flexShrink: 0,
        justifyContent: "center",
      }
    },
    // Buttons
    MuiButton: {
      root: {
        // borderRadius: "10px",
        textTransform: "none",
        boxSizing: "content-box"
      },
      containedPrimary: {
        backgroundColor: primaryColor,
        color: secondaryTextColor,
        fontWeight: "bold"
      }
    },
    // Dialogs
    // MuiDialog: {
    //   root: {
    //     textAlign: "center"
    //   },
    //   paper: {
    //     padding: "20px 55px",
    //     borderRadius: "31px",
    //     boxShadow: BOX_SHADOW
    //   }
    // },
    MuiDialogTitle: {
      root: {
        textAlign: "center",
      },
    },
    // MuiDialogContentText: {
    //   root: {
    //     color: "#8B959A"
    //   }
    // },
    MuiDialogActions: {
      root: {
        justifyContent: "center"
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(255,255,255,0.93)"
      }
    },
    // Avatars
    MuiAvatar: {
      root: {
        width: "80px",
        height: "80px",
        backgroundImage: LINEAR_GRADIENT("right bottom"),
        color: "white"
      }
    }
  }
});
