import { useTheme } from "@material-ui/core";
import React from "react";


const QrCodeIcon: React.FC<React.SVGAttributes<SVGElement> & { selected?: boolean }> = ({ selected, ...props }) => {
  const theme = useTheme()
  const secondary = theme.palette.secondaryText
  return (
    <svg {...props} style={{ fill: selected ? secondary : "black" }} xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32">
      <g id="qr-code" transform="translate(0)">
        <path id="Path_1" data-name="Path 1" d="M1.883,1.883H5.264V0H0V5.264H1.883Z" transform="translate(0 0)" />
        <path id="Path_2" data-name="Path 2" d="M428.143,0V1.883h3.381V5.264h1.883V0Z" transform="translate(-401.408)" />
        <path id="Path_3" data-name="Path 3" d="M1.883,428.143H0v5.264H5.264v-1.883H1.883Z" transform="translate(0 -401.408)" />
        <path id="Path_4" data-name="Path 4" d="M431.524,431.524h-3.381v1.883h5.264v-5.264h-1.883Z" transform="translate(-401.408 -401.408)" />
        <path id="Path_5" data-name="Path 5" d="M68.857,79.386H79.386V68.857H68.857ZM70.74,70.74H77.5V77.5H70.74Z" transform="translate(-64.534 -64.534)" />
        <path id="Path_6" data-name="Path 6" d="M285.958,275.429H275.429v10.529h10.529Zm-8.646,1.883h6.762v2.439h-4.323v4.323h-2.439v-6.762Zm4.323,6.762v-2.439h2.439v2.439Z" transform="translate(-258.281 -258.281)" />
        <path id="Path_7" data-name="Path 7" d="M77.5,279.752H75.063v-4.323H68.857v10.529H79.386v-9.587H77.5Zm0,4.323H70.74v-6.762H73.18v4.323H77.5Z" transform="translate(-64.534 -258.281)" />
        <path id="Path_8" data-name="Path 8" d="M137.714,137.714H139.6V139.6h-1.883Z" transform="translate(-129.109 -129.109)" />
        <path id="Path_9" data-name="Path 9" d="M277.312,70.74h2.439v4.323h4.323V77.5h-8.645v1.883h10.528V68.857H275.429V75.05h1.883Zm4.323,0h2.439V73.18h-2.439Z" transform="translate(-258.281 -64.534)" />
      </g>
    </svg>

  );
}
export default QrCodeIcon;
