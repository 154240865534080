import React from 'react'
import { Addon } from '../../../data/types/models/Addons'
import { FlexDiv } from '../Divs/FlexDiv'
import { useStyles } from './styles'
import ExtensionIcon from '@material-ui/icons/Extension';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

interface Props {
    addon: Addon
    className?: string;
    onSelect?: () => void
}

const AddonCard: React.FC<Props> = ({ addon, className, onSelect }) => {
    const classes = useStyles({})
    return (
        <div className={clsx([classes.card, className])} onClick={onSelect}>
            {addon.logoUrl ? <img className={classes.logo} src={addon.logoUrl} alt={"Logo de l'extension"} /> : <ExtensionIcon />}
            <Typography variant="h6" className={classes.addonName} >{addon.name}</Typography>
        </div>
    )
}

export default AddonCard