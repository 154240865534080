import styled from 'styled-components'
import { makeStyles, createStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            margin: "0px 0 30px 0",
        },
        paper: {
            padding: "10px",
            [theme.breakpoints.up('sm')]: {
                padding: "30px",
            },
        },
        questionsTextField: {
            margin: "0 5px",
            flex: 1
        },
        formLabel: {
            fontSize: "0.8rem"
        },
        deleteIcon: {
            "&:hover": {
                cursor: "pointer"
            }
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
    })
)

export const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 15px;
    background: white;
    box-sizing: border-box;
    overflow-y: auto;
    margin: 20px 0;
`

export const ItemsContainer = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: white;
    box-sizing: border-box;
    flex:1;
`

export const ItemsHeader = styled.div`
    flex-direction: row;
    padding: 0px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const MinMaxDiv = styled.div`
    flex-direction: row;
    display: flex;
`

export const QuestionDiv = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px 0;
`

export const ButtonActions = styled.div`
    display: flex;
    place-self: center;
    flex-direction: row;
`