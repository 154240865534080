import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router";
import { auth } from 'firebase'

import ProtectedRoutes from './protected'
import SignUp from '../../components/SignUp'
import Login from "../../components/Login";
import { connect } from "react-redux";
import { AppReduxState, AuthState } from "../../data/types/appReduxState";
import api from "../../data/firebase/api";
import { logoutAction } from "../../data/actions/auth";
import { IGym } from "../../data/types/models/Gym";
import { IUser } from "../../data/types/models/User";

interface Props {
    dispatch: any
    gyms: {[id: string]: IGym}
    authState: AuthState
    user?: IUser
}

const Routes: React.FC<Props> = ({
    dispatch,
    gyms,
    authState,
    user
}) => {
    if (
        !window.location.href.includes("https") &&
        window.location.href.includes("http") &&
        process.env.NODE_ENV === "production"
    ) {
        window.location.href = window.location.href.replace("http", "https");
    }
    const [firebaseAuth, setFirebaseAuth] = useState(false)

    useEffect(() => {
        console.log("listener use Effect")
        auth().onAuthStateChanged((user) => {
            console.log("onAuthStateChanged")
            if (user && !firebaseAuth) {
                setFirebaseAuth(true)
            } else {
                setFirebaseAuth(false)
            }
        })
    }, [])
    useEffect(() => {
        if (authState.isConnected) {
            if (authState.user === "coach" && gyms.current) {
                api.loadSalesItems(gyms.current.id, dispatch)
                console.log("load sales item")
            } else if (auth().currentUser && !auth().currentUser.isAnonymous) {
                console.log("load admin item")
                api.loadAdminItems(auth().currentUser.uid, dispatch)
            } else {
                console.log("no auth")
            }
        } else {
            console.log("not connected")
        }
    }, [authState, firebaseAuth])
    return (
        <Switch>
            <Route path="/signup" component={SignUp} />
            <Route path="/login" component={Login} />
            <Route path="/" component={ProtectedRoutes} />
            <Route path="/*">
                <Redirect to="/" />
            </Route>
        </Switch>
    );
}

export default connect((appState: AppReduxState) => ({authState: appState.auth, gyms: appState.gyms, user: appState.user}))(Routes)