import { useTheme } from "@material-ui/core";
import React from "react";

const TeamIcon: React.FC<React.SVGAttributes<SVGElement> & { selected?: boolean }> = ({ selected, ...props }) => {
  const theme = useTheme()
  const secondary = theme.palette.secondaryText
  return (

    <svg {...props} style={{ fill: selected ? secondary : "black" }} xmlns="http://www.w3.org/2000/svg" width="28" height="25.434" viewBox="0 0 28 25.434">
      <g id="support" transform="translate(0 -23.462)">
        <g id="Group_109" data-name="Group 109" transform="translate(25.571 40.157)">
          <g id="Group_108" data-name="Group 108" transform="translate(0 0)">
            <path id="Path_1872" data-name="Path 1872" d="M469.854,330.234l-1.335-1.324a.547.547,0,0,0-.77.777l1.335,1.324a.547.547,0,0,0,.77-.777Z" transform="translate(-467.587 -328.751)" />
          </g>
        </g>
        <g id="Group_111" data-name="Group 111" transform="translate(24.208 38.801)">
          <g id="Group_110" data-name="Group 110" transform="translate(0 0)">
            <path id="Path_1873" data-name="Path 1873" d="M443.61,304.112a.547.547,0,0,0-.774,0h0a.547.547,0,1,0,.775,0Z" transform="translate(-442.675 -303.952)" />
          </g>
        </g>
        <g id="Group_113" data-name="Group 113" transform="translate(0 23.462)">
          <g id="Group_112" data-name="Group 112" transform="translate(0 0)">
            <path id="Path_1874" data-name="Path 1874" d="M20.48,41.484l5.306-5.306a2.06,2.06,0,0,0-.994-3.465,2.062,2.062,0,0,0-1.519-2.9,2.058,2.058,0,0,0-1.605-2.675,2.062,2.062,0,0,0-2.942-2.459l-.053-.057a3.65,3.65,0,0,0-2.663-1.16h-4.3a.547.547,0,0,0-.547.547v.717a2.485,2.485,0,0,0,.141.829,2.061,2.061,0,0,0-2.747,2.551,2.061,2.061,0,0,0-1.608,2.676,2.061,2.061,0,0,0-1.518,2.9,2.061,2.061,0,0,0-.994,3.466l1.091,1.091L.16,43.611a.547.547,0,1,0,.773.774L6.357,38.96a2.06,2.06,0,0,0,3.077-1.875l.083,0a2.059,2.059,0,0,0,2.061-2.06c0-.028,0-.056,0-.083l.082,0a2.059,2.059,0,0,0,2.061-2.06c0-.028,0-.056,0-.083l.082,0a2.06,2.06,0,0,0,1.594-3.367l3.631-3.631a.967.967,0,1,1,1.368,1.368l-.535.535-1.942,1.942a.547.547,0,1,0,.773.774l1.942-1.942A.967.967,0,1,1,22,29.851l-.535.535-1.407,1.407a.547.547,0,1,0,.773.774l1.407-1.407a.967.967,0,1,1,1.368,1.368l-.735.735-.672.672a.547.547,0,1,0,.774.774l.673-.673A.967.967,0,0,1,25.012,35.4L19.705,40.71a7.41,7.41,0,0,1-4.986,2.172.547.547,0,0,0-.368.161l-4.9,4.92a.547.547,0,1,0,.775.772l3.605-3.619a5.084,5.084,0,0,0,3.088.794l2.822,2.822a.547.547,0,1,0,.773-.774l-3-3a.547.547,0,0,0-.431-.158l-.173.014a3.993,3.993,0,0,1-2.282-.5l.352-.353A8.5,8.5,0,0,0,20.48,41.484ZM8.06,37.852a.967.967,0,0,1-1.368,0L5.214,36.374a.967.967,0,1,1,1.368-1.368L8.06,36.484A.968.968,0,0,1,8.06,37.852ZM10.2,35.71a.968.968,0,0,1-1.368,0L7.355,34.232,6.621,33.5A.967.967,0,1,1,7.988,32.13L10.2,34.342h0a.967.967,0,0,1,0,1.368Zm2.141-2.141a.968.968,0,0,1-1.368,0L8.227,30.821a.967.967,0,0,1,1.367-1.368L10.847,30.7l1.5,1.5h0a.967.967,0,0,1,0,1.368Zm2.141-2.141a.968.968,0,0,1-1.368,0l-2.748-2.748h0l-.534-.534A.967.967,0,0,1,11.2,26.776l3.283,3.283a.967.967,0,0,1,0,1.368Zm.144-2.771-1.5-1.5a2.5,2.5,0,0,0,.524.056h.012l1.5-.007a.547.547,0,0,0,0-1.094h0l-1.5.007H13.65a1.389,1.389,0,0,1-1.389-1.389v-.17h3.748a2.553,2.553,0,0,1,1.863.811l.022.024Z" transform="translate(0 -23.462)" />
          </g>
        </g>
      </g>
    </svg>

  );
}

export default TeamIcon;
