import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
import { Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>  
    createStyles({
        main: {
        },
        paper: {
            padding: "10px",
            [theme.breakpoints.up('sm')]: {
                padding: "30px",
            },
        },
        textField: {
            margin: "10px 0"
        }
    })
)