import React from 'react'
import { Addon } from '../../../data/types/models/Addons'
import { FlexDiv } from '../Divs/FlexDiv'
import { useStyles } from './styles'
import ExtensionIcon from '@material-ui/icons/Extension';
import { Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { IGym } from '../../../data/types/models/Gym';
import LogoWithoutBlanc from '../../icons/components/LogoWithoutBlanc';

interface Props {
    gym: IGym
    className?: string;
    onClick?: () => void
}

const GymCard: React.FC<Props> = ({ gym, className, onClick }) => {
    const classes = useStyles({})

    return (
        <div className={clsx([classes.card, className])}>
            <LogoWithoutBlanc className={classes.logo}/>
            <Typography variant="h6" className={classes.addonName}>{gym.name.toUpperCase()}</Typography>
            <div className={classes.buttonDiv}>
                <Button variant="contained" color="primary" onClick={onClick}>Gérer la salle</Button>
            </div>
        </div>
    )
}

export default GymCard