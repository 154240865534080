import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, useRouteMatch, withRouter } from 'react-router'
import { AppReduxState } from '../../../data/types/appReduxState'
import { IGym } from '../../../data/types/models/Gym'
import { routes } from '../../../lib/utils/routes'
import Layout from './Layout'
import SubscriptionDialog from './SubscriptionDialog'

interface Props extends RouteComponentProps<{ id: string }> {
    gym: IGym
}

const GymDetail: React.FC<Props> = ({ history, gym }) => {
    if (!gym) {
        history.push(routes.GYMS)
        return null
    }
    return (
        <Fragment>
            <SubscriptionDialog gymId={gym.id}/>
            <Layout
                gym={gym}
                onGenerateNewPassword={() => {

                }}
                onSubscribe={() => {
                    history.push(routes.GYMS + gym.id + "/subscription")
                }}
            />
        </Fragment>
    )
}

interface TStateProps {
    gyms: { [id: string]: IGym }
}

interface TOwnProps {
    id: string
}

export default withRouter(
    connect<TStateProps, { dispatch: any }, RouteComponentProps<{ id: string }>, Props, AppReduxState>(
        ({ gyms }: AppReduxState) => ({
            gyms: gyms,
        }),
        (dispatch) => ({ dispatch }),
        ({ gyms, ...stateProps }, dispatchProps, props) => ({
            gym: gyms[props.match.params.id],
            ...dispatchProps,
            ...props
        })
    )(GymDetail))