import styled from 'styled-components'

interface Props {
    column?: boolean
    center?: boolean
    flex?: number
    justifyContent?: "space-between" | "center" | "space-around" | "flex-start" | "flex-end";
    alignItems?: "center" | "flex-start" | "flex-end";
    margin?: string;
    bgColor?: string
    height?: string;
    borderRadius?: number;
    padding?: string;
    width?: string;
    boxShadow?: boolean;
}

export const FlexDiv = styled.div<Props>`
    display: flex;
    flex-direction: ${({column}) => column ? "column" : "row"};
    flex: ${({flex}) => flex};
    justify-content: ${({ center, justifyContent }) => center ? "center" : justifyContent};
    align-items: ${({ center, alignItems }) => center ? "center" : alignItems};
    margin: ${({ margin }) => margin};
    background-color: ${({ bgColor }) => bgColor};
    border-radius: ${({borderRadius}) => (borderRadius || 0) + "px"};
    padding: ${({ padding }) => padding};
    height: ${({ height }) => height};
    width: ${({ width }) => width};
`