import React, { useState } from 'react'
import { HygUpProduct } from '../../../data/types/models/Addons/HygUp'
import { dummyProducts } from './dummyProducts'
import Layout from './Layout'

interface Props {
    
}

const OrderView: React.FC<Props> = ({ }) => {
    const [products, setProducts] = useState<HygUpProduct[]>(dummyProducts)
    const [quantities, setQuantities] = useState<number[]>(dummyProducts.map(() => 0))

    return (
        <Layout 
            products={products}
            quantities={quantities}
            onUpdateQuantity={(newValue, index) => {
                if (newValue < 0) {
                    newValue = 0
                }
                console.log(newValue, index)
                let newQuantites = quantities.slice()
                newQuantites[index] = newValue
                setQuantities(newQuantites)
            }}
        />
    )
}

export default OrderView