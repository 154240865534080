import styled from 'styled-components'
import QRCode from 'qrcode.react'
import { makeStyles, Theme, createStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => 
createStyles({
    paper: {
        padding: "10px",
        [theme.breakpoints.up('sm')]: {
            padding: "30px",
        },
    },
}))

export const Container = styled.div`
    padding: 10px;
    flex-direction: column;
    align-items: center;
    text-align: center;
    align-self: center;
    @media print {
        border-style: dotted;
        border-width: 2px;
        border-color: rgba(0,0,0,0.5);
    }
`

export const PrintDiv = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
    @media print {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
`

export const QRCodeView = styled(QRCode)`
    max-width: 100%;
`

export const MainContainer = styled.div`
    padding: 25px;
    @media (min-width: 600px) {
        padding: 25px;
    }
    box-sizing: border-box;
    padding-top: 15px;
    display: flex;
    padding-bottom: 59px;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    overflow-y: auto;
`

export const QuestionDiv = styled.div`
    display: flex;
    margin: 20px 0;
    flex-direction: column;
`

export const ButtonsDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    align-items: center;
`