import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { setAddon } from '../../../data/actions/addons'
import { AppReduxState } from '../../../data/types/appReduxState'
import { Addon, AddonsList } from '../../../data/types/models/Addons'
import { HygUpData } from '../../../data/types/models/Addons/HygUp'
import { IGym } from '../../../data/types/models/Gym'
import { routes } from '../../../lib/utils/routes'
import Layout from './Layout'
import { FormValues } from './types'

interface Props {
    addon?: Addon
    gym?: IGym
    dispatch: any
}

const SetupHygUp: React.FC<Props> = ({ addon, gym, dispatch }) => {
    const history = useHistory()
    const [values, setValues] = useState<FormValues>({
        accountNumber: "",
        address: gym ? `${gym.address.mainAddress} ${gym.address.postalCode} ${gym.address.city}` : ""
    })
    const [errors, setErrors] = useState<FormValues>({accountNumber: "", address: ""})
    useEffect(() => {
        if (gym) {
            setValues((old) => ({
                accountNumber: old.accountNumber,
                address: `${gym.address.mainAddress} ${gym.address.postalCode} ${gym.address.city}`
            }))
            setErrors({accountNumber: "", address: ""})
        }
    }, [gym])
    if (!addon || addon.isInstalled) {
        return null
    }
    const onNext = () => {
        setErrors({accountNumber: "", address: ""})
        let accountNumber = values.accountNumber.trim()
        let address = values.address.trim()
        let error = false
        if (!accountNumber) {
            error = true
            setErrors((errors) => ({...errors, accountNumber: "Le numéro de compte est incorrect"}))
        }
        if (!address) {
            error = true
            setErrors((errors) => ({...errors, address: "L'adresse ne peut être vide"}))
        }
        if (error) {
            return
        }
        let data: HygUpData = {
            accountNumber,
            address,
        }
        dispatch(setAddon({ ...addon, data, isInstalled: true }))
    }
    return (
        <Layout
            onCancel={() => { history.push(routes.ADDONS) }}
            values={values}
            errors={errors}
            onNext={onNext}
            setValue={({name, value}) => {
                setValues((old) => ({...old, [name]: value}))
            }}
        />
    )
}

export default connect((rootState: AppReduxState) => ({
    addon: rootState.addons[AddonsList.HygUp],
    gym: rootState.gyms.current
}))(SetupHygUp)