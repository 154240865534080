import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers';
import * as yup from "yup";

import { Button, Checkbox, FormHelperText, FormLabel, TextField, Typography } from '@material-ui/core'
import { Form, MainContainer, useStepsStyles, ButtonsDiv, CheckBoxDiv } from '../stepsStyles'
import { Controller } from "react-hook-form"
import { IGym } from '../../../../data/types/models/Gym';
import AppConfig from '../../../../AppConfig';

interface Props {
    gym: IGym
    onDone: (gym: IGym) => void
    onBack: () => void
    stepNumber: number
    totalStep: number

}

const schema = yup.object().shape({
    phoneNumber: yup.string().min(3).required("Le numéro de téléphone est obligatoire."),
    email: yup.string().email().required("L'adresse e-mail est obligatoire."),
    address: yup.object().shape({
        mainAddress: yup.string().required("Ce champ est obligatoire"),
        postalCode: yup.string().matches(/^[0-9]{5}$/, 'Le code postal doit contenir 5 chiffres'),
        city: yup.string().required("Ce champ est obligatoire"),
    }),
    franchise: AppConfig.app === "fitnesspark" ? yup.bool().oneOf([true], "Nous sommes actuellement en discussion avec le siège social Fitness park au niveau des succursales. Tu ne peux pour l'instant pas déployer l'application sur cette salle").required("Nous sommes actuellement en discussion avec le siège social Fitness park au niveau des succursales. Tu ne peux pour l'instant pas déployer l'application sur cette salle") :
    yup.bool().optional()
});

const GymInfo: React.FC<Props> = ({ onDone, gym, onBack, stepNumber, totalStep }) => {
    const { handleSubmit, getValues, errors, control, register } = useForm<IGym>({
        resolver: yupResolver(schema),
        defaultValues: gym
    })

    const onSubmit = (data: IGym) => {
        onDone(data)
    }
    const stepsClasses = useStepsStyles()

    return (
        <MainContainer>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="h4" className={stepsClasses.title}>Informations sur la <span className={stepsClasses.colored}>salle de sport</span></Typography>
                <Typography variant="body2" className={stepsClasses.stepText}>ÉTAPE {stepNumber}/{totalStep}</Typography>
                <Controller
                    as={TextField}
                    name="address.mainAddress"
                    control={control}
                    type="text"
                    label="Adresse de la salle de sport"
                    className={stepsClasses.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    placeholder="5 Rue Eugène Freyssinet"
                    helperText={errors.address?.mainAddress?.message}
                    error={!!errors.address?.mainAddress}
                />
                <Controller
                    as={TextField}
                    name="address.postalCode"
                    control={control}
                    type="text"
                    label="Code Postal"
                    className={stepsClasses.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    placeholder="75013"
                    helperText={errors.address?.postalCode?.message}
                    error={!!errors.address?.postalCode}
                />
                <Controller
                    as={TextField}
                    name="address.city"
                    control={control}
                    type="text"
                    label="Ville"
                    className={stepsClasses.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    placeholder="Paris"
                    helperText={errors.address?.city?.message}
                    error={!!errors.address?.city}
                />
                <Controller
                    as={TextField}
                    control={control}
                    name="phoneNumber"
                    ref={register}
                    type="tel"
                    autoComplete="tel"
                    className={stepsClasses.textField}
                    label="Numéro de la salle de sport"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    helperText={errors.phoneNumber?.message}
                    error={!!errors.phoneNumber}
                    placeholder="01 23 45 67 89"
                />
                <Controller
                    as={TextField}
                    control={control}
                    name="email"
                    className={stepsClasses.textField}
                    ref={register}
                    type="email"
                    autoComplete="email"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label="Adresse e-mail de la salle de sport"
                    placeholder={`${gym.name.toLowerCase()}@${AppConfig.brandName.toLowerCase()}.fr`}
                    helperText={errors.email?.message}
                    error={!!errors.email}
                />
                <CheckBoxDiv>
                    <Controller
                        control={control}
                        name="franchise"
                        render={({ onChange, onBlur, value, name }) => (
                            <Checkbox
                                color="secondary"
                                onBlur={onBlur}
                                onChange={e => onChange(e.target.checked)}
                                checked={value}
                                name={name}
                            />
                        )}
                    />
                    <FormLabel style={{ fontSize: "0.8rem" }}>Salle franchisé</FormLabel>
                </CheckBoxDiv>
                {AppConfig.app === "fitnesspark" && !!errors.franchise && <FormHelperText error={true}>{errors.franchise.message}</FormHelperText>}
                <ButtonsDiv>
                    <Button onClick={onBack} variant="outlined" color="primary">Revenir</Button>
                    <Button type='submit' variant="contained" color="primary">Confirmer les informations</Button>
                </ButtonsDiv>
            </Form>
        </MainContainer>
    )
}

export default GymInfo