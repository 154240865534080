import React from 'react'

import {CardElement} from '@stripe/react-stripe-js';
import * as stripejs from '@stripe/stripe-js'

import './CardSectionStyles.css'
import { FormLabel } from '@material-ui/core';

const CARD_ELEMENT_OPTIONS: stripejs.StripeCardElementOptions = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

interface Props {
    onBlur: () => any
    onChange: (e: stripejs.StripeCardElementChangeEvent) => any
    postalCode: string
}

export default function CardSection({onBlur, onChange, postalCode}: Props) {
    return (
      <label>
        <FormLabel style={{fontSize: "0.8rem"}}>Informations de carte bancaire</FormLabel>
        <CardElement options={{...CARD_ELEMENT_OPTIONS, value: {postalCode: postalCode}}} onBlur={onBlur} onChange={onChange}/>
      </label>
    );
  };