import { useTheme } from "@material-ui/core";
import React from "react";

import Icon from "../svg/reports.svg";

const ReportsIcon: React.FC<React.SVGAttributes<SVGElement> & { selected?: boolean }> = ({ selected, ...props }) => {
  const theme = useTheme()
  const secondary = theme.palette.secondaryText
  return (
    <svg {...props} style={{ fill: selected ? secondary : "black" }} id="Group_32" data-name="Group 32" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 32">
      <path id="Path_25" data-name="Path 25" d="M90.195,0H67.145a.472.472,0,0,0-.475.468V22.7a.475.475,0,0,0,.951,0V.936h22.1V3.879a.475.475,0,0,0,.951,0V.468A.472.472,0,0,0,90.195,0Z" transform="translate(-66.67)" />
      <path id="Path_26" data-name="Path 26" d="M90.195,89.65a.472.472,0,0,0-.475.468v24.971h-22.1v-6.135a.475.475,0,0,0-.951,0v6.6a.472.472,0,0,0,.475.468H90.195a.472.472,0,0,0,.475-.468V90.118A.472.472,0,0,0,90.195,89.65Z" transform="translate(-66.67 -84.026)" />
      <path id="Path_27" data-name="Path 27" d="M99,101.625a3.639,3.639,0,1,0,3.639-3.639A3.643,3.643,0,0,0,99,101.625Zm3.639,2.7a2.7,2.7,0,0,1-.47-5.354v2.656a.47.47,0,0,0,.47.47h2.656A2.7,2.7,0,0,1,102.635,104.323Zm2.656-3.169h-2.186V98.969A2.7,2.7,0,0,1,105.291,101.155Z" transform="translate(-96.968 -91.869)" />
      <path id="Path_28" data-name="Path 28" d="M103.507,432.35a.47.47,0,0,0-.471.47v2.028a.471.471,0,0,0,.471.471h3.042a.471.471,0,0,0,.471-.471v-2.028a.47.47,0,0,0-.471-.47Zm2.571,2.028h-2.1v-1.087h2.1Z" transform="translate(-100.755 -405.347)" />
      <path id="Path_29" data-name="Path 29" d="M184.321,389.873h3.042a.47.47,0,0,0,.47-.47v-5.07a.47.47,0,0,0-.47-.47h-3.042a.47.47,0,0,0-.47.47v5.07A.47.47,0,0,0,184.321,389.873Zm.471-5.07h2.1v4.129h-2.1Z" transform="translate(-176.408 -359.9)" />
      <path id="Path_30" data-name="Path 30" d="M265.134,323.252a.47.47,0,0,0-.471.47v8.872a.47.47,0,0,0,.471.47h3.042a.47.47,0,0,0,.47-.47v-8.872a.47.47,0,0,0-.47-.47Zm2.571,8.872h-2.1v-7.931h2.1Z" transform="translate(-252.088 -303.093)" />
      <path id="Path_31" data-name="Path 31" d="M348.992,285.45a.47.47,0,0,0,.47-.47V283.32a.47.47,0,0,0-.47-.471h-3.041a.471.471,0,0,0-.471.471v11.406a.47.47,0,0,0,.471.47h3.041a.47.47,0,0,0,.471-.47v-7.551a.47.47,0,1,0-.941,0v7.081h-2.1V283.791h2.1v1.189A.471.471,0,0,0,348.992,285.45Z" transform="translate(-327.744 -265.225)" />
      <path id="Path_32" data-name="Path 32" d="M178.3,32.325H168.157a.47.47,0,0,0-.47.471v2.028a.47.47,0,0,0,.47.471H178.3a.47.47,0,0,0,.47-.471V32.8A.471.471,0,0,0,178.3,32.325Zm-.471,2.028h-9.2V33.266h9.2v1.087Z" transform="translate(-161.227 -30.297)" />
      <path id="Path_33" data-name="Path 33" d="M258.349,97.986h-9.379a.47.47,0,0,0,0,.941h9.379a.47.47,0,1,0,0-.941Z" transform="translate(-236.848 -91.863)" />
      <path id="Path_34" data-name="Path 34" d="M258.349,131.658h-9.379a.47.47,0,0,0,0,.941h9.379a.47.47,0,1,0,0-.941Z" transform="translate(-236.848 -123.43)" />
      <path id="Path_35" data-name="Path 35" d="M258.82,165.8a.47.47,0,0,0-.471-.47h-9.379a.47.47,0,0,0,0,.941h9.379A.471.471,0,0,0,258.82,165.8Z" transform="translate(-236.848 -154.998)" />
      <path id="Path_36" data-name="Path 36" d="M255.018,199.473a.47.47,0,0,0-.471-.47h-5.577a.47.47,0,0,0,0,.941h5.577A.471.471,0,0,0,255.018,199.473Z" transform="translate(-236.931 -186.566)" />
      <path id="Path_37" data-name="Path 37" d="M121.154,248.5H112.6a.47.47,0,0,0,0,.941h8.555a.47.47,0,1,0,0-.941Z" transform="translate(-109.276 -232.971)" />
      <path id="Path_38" data-name="Path 38" d="M121.624,282.642a.47.47,0,0,0-.471-.47H112.6a.471.471,0,0,0,0,.941h8.555A.471.471,0,0,0,121.624,282.642Z" transform="translate(-109.276 -264.538)" />
      <path id="Path_39" data-name="Path 39" d="M117.822,316.314a.47.47,0,0,0-.471-.47H112.6a.47.47,0,1,0,0,.941h4.753A.471.471,0,0,0,117.822,316.314Z" transform="translate(-109.276 -296.106)" />
    </svg>
  );
}
export default ReportsIcon;
