import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { AppReduxState } from '../../data/types/appReduxState'
import { IGym } from '../../data/types/models/Gym'
import Layout from './Layout'

interface Props {
    gym: IGym
}

function makeRandom(length = 4): string {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789';
    var charactersLength = characters.length;

    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

const TeamView: React.FC<Props> = ({gym}) => {
    const onNewPassword = (async () => {
    })
    return (
        <Layout
            salesPassword={gym.salesPassword}
            onGenerateNew={onNewPassword}
        />
    )
}

export default connect((appState: AppReduxState) => ({gym: appState.gyms.current}))(TeamView)