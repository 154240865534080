import { IForm } from "../types/models/Form";
import { SET_TEMPLATES, SET_TEMPLATE } from "../actions/templates";

const templatesFormReducer = (
    state: {[id: string]: IForm} = {},
    {type, payload}: {type: string, payload: any}
) => {
    switch (type) {
        case SET_TEMPLATES:
            return payload;
        case SET_TEMPLATE:
            state[payload.id] = payload
            return {...state};
    }
    return state;
}
  
export default templatesFormReducer;
  