import { HygUpProduct } from "../../../data/types/models/Addons/HygUp";

export const dummyProducts: HygUpProduct[] = [
    {
        image: "https://hyg-up.com/photos/zooms/726.jpg?v=1.2",
        name: "Carré de soins 30x35 colis de 20 x 50 /1000"
    }, 
    {
        image: "https://hyg-up.com/photos/zooms/727.jpg?v=1.2",
        name: "Serviette de Toilette 80 X 45 en Polycell Embossé /500"
    }, 
    {
        image: "https://hyg-up.com/photos/zooms/200.jpg?v=1.2",
        name: "Gel Hydro-Alcoolique Antiseptique Flacon 500 ml /6"
    }, 
    {
        image: "https://hyg-up.com/photos/zooms/225.jpg?v=1.2",
        name: "Savon Glycériné Haute Fréquence Mains et Corps Steriderm 1L"
    }, 

]