import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
import { Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>  
    createStyles({
        card: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px",
            backgroundColor: "white",
            boxShadow: theme.shadows[1],
            "&:hover": {
                // boxShadow: theme.shadows[5],
                // cursor: "pointer"
            },
            borderRadius: "10px",
            width: "230px",
            aspectRatio: (13/11).toString(),
            boxSizing: "border-box"
        },
        logo: {
            maxWidth: "50%",
            objectFit: "contain",
            flex: 1,
        },
        addonName: {
            fontWeight: "bold",
            textAlign: "center",
            flex: 1,
        },
        buttonDiv: {
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: 'center'
        }
    })
)