import { Addon } from "../types/models/Addons";

export const SET_ADDONS = "SET_ADDONS"

export const setAddons = (addons: {[id: string]: Addon}) =>
      ({
            type: SET_ADDONS,
            payload: addons
      });

export const SET_ADDON = "SET_ADDON"

export const setAddon = (addon: Addon) =>
      ({
            type: SET_ADDON,
            payload: addon
      });
