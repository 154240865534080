import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers';
import * as yup from "yup";
import CopyToClipboard from 'react-copy-to-clipboard';

import { Button, CircularProgress, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core'
import { Form, MainContainer, useStepsStyles, ButtonsDiv, GenerateDiv } from '../stepsStyles'
import { Controller } from "react-hook-form"
import { Visibility, VisibilityOff } from '@material-ui/icons';

interface Props {
    onDone: (values: {password: string}) => void
    onBack: () => void
    gymName: string;
    stepNumber: number
    totalStep: number

}

interface Values {
    password: string;
}

function makeRandom(length = 4): string {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789';
    var charactersLength = characters.length;

    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

const SalesPassword: React.FC<Props> = ({ onDone, onBack, gymName, stepNumber, totalStep }) => {
    const { handleSubmit, errors, setValue, watch, control, register } = useForm<Values>({
        defaultValues: {
            password: gymName.toUpperCase().split(' ').join('') + "-" + makeRandom()
        }
    })

    const onSubmit = (data: Values) => {
        onDone(data)
    }
    const stepsClasses = useStepsStyles()

    return (
        <MainContainer>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="h4" className={stepsClasses.title}>Création de l'espace <span className={stepsClasses.colored}>commercial</span></Typography>
                <Typography variant="body2" className={stepsClasses.stepText}>ÉTAPE {stepNumber}/{totalStep}</Typography>
                <Controller
                    render={({name, value}) => (
                        <TextField 
                            name={name}
                            value={value}
                            label="Mot de passe de l'espace commercial"
                            className={stepsClasses.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <CopyToClipboard text={watch("password")}>
                                            <Button
                                                aria-label="toggle password visibility"
                                                color="secondary"
                                                style={{fontWeight: 'bold'}}
                                            >
                                            {"COPIER LE MDP"}
                                            </Button>
                                        </CopyToClipboard>
                                    </InputAdornment>
                                ),
                                style: {
                                    display: "flex",
                                    fontWeight: "bold"
                                }
                            }}
                            helperText={"Le mot de passe est unique et sera partagé à tous les commerciaux. Tu pourras le retrouver sur ton espace plus tard"}
                        />
                    )}
                    name="password"
                    control={control}
                />
                <GenerateDiv>
                    <Button ref={register} style={{textDecoration: "underline", fontWeight: "bold"}} onClick={() => {
                        setValue("password", gymName.toUpperCase().split(' ').join('') + "-" + makeRandom())
                    }}>GÉNÉRER UN NOUVEAU MOT DE PASSE</Button>
                </GenerateDiv>
                <ButtonsDiv>
                    <Button onClick={onBack} variant="outlined" color="primary">Revenir</Button>
                    <Button type='submit' variant="contained" color="primary">{"Suivant"}</Button>
                </ButtonsDiv>
            </Form>
        </MainContainer>
    )
}

export default SalesPassword