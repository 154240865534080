import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { AppReduxState } from '../../data/types/appReduxState'
import { Addon } from '../../data/types/models/Addons'
import { arrayFromEntitiesCollection } from '../../lib/utils/collections'
import Layout from './Layout'

interface Props {
    addons: Addon[]
}

const Addons: React.FC<Props> = ({ addons }) => {
    const [availableAddons, setAvailableAddons] = useState<Addon[]>(addons.filter((addon) => addon.isAvailable === true && addon.isInstalled === false))
    const [installedAddons, setInstalledAddons] = useState<Addon[]>(addons.filter((addon) => addon.isInstalled === true))
    const history = useHistory()

    useEffect(() => {
        setAvailableAddons(addons.filter((addon) => addon.isAvailable === true && addon.isInstalled === false))
        setInstalledAddons(addons.filter((addon) => addon.isInstalled === true))
    }, [addons])

    return (
        <Layout 
            availableAddons={availableAddons}
            installedAddons={installedAddons}
            onSelectAddon={(addon) => {
                history.push(addon.path)
            }}
        />
    )
}

export default connect((rootState: AppReduxState) => ({
    addons: arrayFromEntitiesCollection(rootState.addons)
}))(Addons)