import { IconButton, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { HygUpProduct } from '../../../../data/types/models/Addons/HygUp'
import { useStyles } from './styles'
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import clsx from 'clsx';

interface Props {
    product: HygUpProduct
    quantity: number
    onUpdateQuantity: (quantity: number) => void
    className?: string
}

const ProductCard: React.FC<Props> = ({ product, quantity, onUpdateQuantity, className }) => {
    const classes = useStyles({})
    return (
        <div className={clsx([classes.main, className])}>
            <img src={product.image} className={classes.image} />
            <div className={classes.details}>
                <Typography className={classes.productTitle}>{product.name}</Typography>
                <div className={classes.quantityDiv}>
                    <IconButton onClick={() => { onUpdateQuantity(quantity - 1) }}>
                        <RemoveIcon />
                    </IconButton>
                    <TextField
                        value={quantity.toString()}
                        type="number"
                        classes={{
                            root: classes.quantityTextField,
                        }}
                        inputProps={{
                            className: classes.quantityInput
                        }}
                        onChange={(e) => {
                            let value = parseInt(e.target.value)
                            if (isNaN(value) || value < 0) {
                                value = 0
                            }
                            onUpdateQuantity(value)
                        }}
                    />
                    <IconButton onClick={() => { onUpdateQuantity(quantity + 1) }}>
                        <AddIcon />
                    </IconButton>
                </div>
            </div>
        </div>
    )
}

export default ProductCard