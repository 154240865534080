import styled from 'styled-components'
import QrReader from 'react-qr-reader'
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    title: {
        marginLeft: "15px",
        marginBottom: "15px",
        alignSelf: "flex-start"
    },
});

export const MainContainer = styled.div`
    padding-bottom: 56px;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: 100%;
    max-height: 100%;
    box-sizing: border-box;

`

export const QrReaderView = styled(QrReader)<{loading?: boolean}>`
    width: 100%;
    max-width: 500px;
    max-height: 70%;
    display: ${({loading}) => (loading === false ? "none" : "block")}
`