import { Button, InputAdornment, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import Drawer from '../../../lib/ui/Drawer'
import { MainContainer, Form, useStyles, GenerateDiv, ButtonsDiv } from './style'
import CopyToClipboard from 'react-copy-to-clipboard';
import AppConfig from '../../../AppConfig';

interface Props {
    salesPassword: string
    onGenerateNew: () => void
}

const Layout: React.FC<Props> = ({ salesPassword, onGenerateNew }) => {
    const classes = useStyles()

    return (
        <Drawer title={"Équipe"}>
            <MainContainer>
                <Form>
                    <TextField 
                        value={salesPassword}
                        label={"Mot de passe de l'espace " + (AppConfig.app === "fitcarebarber" ? "coiffeur" : "commercial")}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <CopyToClipboard text={salesPassword}>
                                        <Button
                                            aria-label="toggle password visibility"
                                            color="secondary"
                                            style={{fontWeight: 'bold'}}
                                        >
                                        {"COPIER LE MDP"}
                                        </Button>
                                    </CopyToClipboard>
                                </InputAdornment>
                            ),
                            style: {
                                display: "flex",
                                fontWeight: "bold"
                            }
                        }}
                        helperText={"Le mot de passe est unique et est partagé entre tous les " + (AppConfig.app === "fitcarebarber" ? "coiffeurs" : "commerciaux")}
                    />
                    <GenerateDiv>
                    </GenerateDiv>
                </Form>
            </MainContainer>
        </Drawer>
    )
}

export default Layout