import { User, auth } from 'firebase'
import { displayName } from 'qrcode.react'
import React, { useState } from 'react'
import { Address, IGym } from '../../data/types/models/Gym'
import { IUser } from '../../data/types/models/User'
import EmailStep from './Steps/EmailStep'
import GymInfo from './Steps/GymInfo'
import LoaderView from './Steps/LoaderView'
import PaiementInfos from './Steps/PaiementInfos'
import PasswordInfos from './Steps/PasswordInfos'
import SalesPassword from './Steps/SalesPassword'
import TrialInformations from './Steps/TrialInformations'
import SelectGym from './Steps/SelectGym'
import { MainContainer, StepContainer, Logo } from './style'
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { sendRequest } from '../../data/firebase/newApi'
import { useHistory } from 'react-router'
import { routes } from '../../lib/utils/routes'
import api from '../../data/firebase/api'
import { connect } from 'react-redux'
import { loginAction } from '../../data/actions/auth'
import swal from '@sweetalert/with-react';

interface Props {
    dispatch: any
}

interface State {
    user?: IUser,
    gym?: IGym
    password?: string
    billingAddress?: Address
}

const SignUp: React.FC<Props> = ({dispatch}) => {
    const [index, setIndex] = useState(0)
    const [state, setState] = useState<State>({})
    const [isLoading, setLoading] = useState(false)
    const [errorSignIn, setErrorSignIn] = useState<string | undefined>(undefined)
    const history = useHistory()
    const stripe = useStripe()
    const elements = useElements()
    let totalStep = 6
    const EmailStepView = <EmailStep
        onDone={({ email, name, phoneNumber }) => {
            setState({user: {email, name, phoneNumber, gyms: []}})
            setIndex(index + 1)
        }}
        user={state.user}
        errorSignIn={errorSignIn}
        stepNumber={index + 1}
        totalStep={totalStep}

    />
    const SelectGymStep = <SelectGym
        onBack={() => {
            setIndex(index - 1)
        }}
        onDone={({ gym }) => {
            setState({...state, gym})
            setIndex(index + 1)
        }}
        stepNumber={index + 1}
        totalStep={totalStep}

    />
    const GymInfoStep = <GymInfo
        gym={state.gym || { id: "Nom de la salle inconnu", name: "Nom de la salle inconnu", app: "App Inconnu" }}
        onBack={() => {
            setIndex(index - 1)
        }}
        onDone={(gym) => {
            setState({...state, gym: {...state.gym, ...gym}})
            setIndex(index + 1)
        }}
        stepNumber={index + 1}
        totalStep={totalStep}

    />
    const PasswordStep = <PasswordInfos
        onBack={() => {
            setIndex(index - 1)
        }}
        onDone={async ({password}) => {
            setState({ ...state, password })
            setIndex(index + 1)
        }}
        isLoading={isLoading}
        stepNumber={index + 1}
        totalStep={totalStep}

    />
    const SalesPasswordStep = <SalesPassword
        onDone={({password}) => {
            //@ts-ignore
            setState({...state, gym: {...state.gym, salesPassword: password}})
            setIndex(index + 1)
        }}
        gymName={state.gym?.name || ""}
        onBack={() => {
            setIndex(index - 1)
        }}
        stepNumber={index + 1}
        totalStep={totalStep}

    />
    const sendEverything = async (paymentMethodId: string, billingAddress: Address) => {
        let data: {
            user: {
                email: string;
                password: string;
                name: string;
                phoneNumber: string;    
            },
            gym: IGym
            billingAddressGym: Address
            // paymentMethodId: string;
        } = {
            user: {
                email: state.user.email || "",
                name: state.user.name || "",
                password: state.password,
                phoneNumber: state.user.phoneNumber || ""
            },
            billingAddressGym: {
                mainAddress: billingAddress.mainAddress,
                city: billingAddress.city,
                postalCode: billingAddress.postalCode
            },
            gym: state.gym,
            // paymentMethodId: paymentMethodId
        }
        try {
            await sendRequest({endpoint:"users", method: "post", data, needToken: false})
            let resAuth = await auth().signInWithEmailAndPassword(state.user?.email || "", state.password || "")
            let userId = resAuth.user?.uid
            if (!userId) {
                swal({
                    title: "Erreur",
                    text: "Ton compte a bien été créé mais nous n'avons pas réussi à te connecter. Connecte toi manuellement et contacte le support si la connexion échoue encore",
                    icon: "error",
                    button: "Revenir"
                }).then(() => {
                    history.push("/login")
                })
            } else {
                dispatch(loginAction({type: "admin"}))
                await api.loadAdminItems(userId, dispatch)
                history.push("/forms-add-preloaded")
            }
        } catch (e) {
            swal({
                title: "Erreur",
                text: e.localizedMessage,
                icon: "error",
                button: "Revenir"
            })
            setIndex(index - 1)
        }
    }
    const TrialInformationsStep = <TrialInformations
        onDone={() => {
            sendEverything("", state.gym.address)
            setIndex(index + 1)
            // const billingAddress = values
            // setState({...state, billingAddress})
            // setIndex(index + 1)
            // setLoading(true)
            // sendEverything("", billingAddress)
        }}
        onBack={() => {
            setIndex(index - 1)
        }}
    />
    const LoaderStep = <LoaderView
        onDone={() => {
        }}
        gymName={state.gym?.name || ""}
    />
    const steps = [EmailStepView, SelectGymStep, GymInfoStep, PasswordStep, SalesPasswordStep, TrialInformationsStep, LoaderStep]
    let CurrentStep: JSX.Element | null = null
    if (steps.length - 1 >= index) {
        CurrentStep = steps[index]
    }
    return (
        <MainContainer>
            <Logo />
            <StepContainer>
                {CurrentStep}
            </StepContainer>
        </MainContainer>
    )
}

export default connect()(SignUp)