import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import { arrayFromEntitiesCollection } from '../../../lib/utils/collections'
import { connect } from 'react-redux'
import { AppReduxState } from '../../../data/types/appReduxState'
import { IGym } from '../../../data/types/models/Gym'
import { IForm } from '../../../data/types/models/Form'
import cuid from 'cuid'
import api from '../../../data/firebase/api'
import { RouteComponentProps, useHistory, withRouter } from 'react-router'
import { routes } from '../../../lib/utils/routes'

interface Props {
    gym: IGym
    dispatch: any
    forms: IForm[]
}

const SelectFormsToPrint: React.FC<Props> = ({ gym, dispatch, forms }) => {
    const [preloadedForms] = useState(forms)
    const [selectedForms, setSelectedForms] = useState<{[id: string]: boolean}>({})
    const [isLoading, setLoading] = useState(false)
    const history = useHistory()
    useEffect(() => {
        let selected = {}
        preloadedForms.forEach((form) => {
            selected[form.id] = true
        })
        setSelectedForms(selected)
    }, [preloadedForms])

    const onSend = async () => {
        setLoading(true)
        window.print()
        setLoading(false)
    }

    return (
        <Layout
            //@ts-ignore
            preloadedForms={preloadedForms}
            selectedForms={selectedForms}
            onSelectAll={() => {
                let selected = {}
                preloadedForms.forEach((form) => {
                    selected[form.id] = true
                })
                setSelectedForms(selected)
            }}
            onUnselectAll={() => {
                let selected = {}
                setSelectedForms(selected)
            }}
            onSelectForm={(id: string) => {
                selectedForms[id] = !selectedForms[id]
                setSelectedForms({...selectedForms})
            }}
            isLoading={isLoading}
            onValid={onSend}
            onBack={() => {
                history.goBack()
            }}
        />
    )
}

export default connect((appState: AppReduxState) => ({gym: appState.gyms.current, forms: arrayFromEntitiesCollection(appState.templatesForm).filter((form) => form.gymId === appState.gyms.current.id)}))(SelectFormsToPrint)