import { Button, Checkbox, CircularProgress, Dialog, DialogTitle, FormControlLabel, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import { IForm } from '../../../../data/types/models/Form'
import { useStyles, ButtonActions, FormContainer, ItemDiv } from './style'

interface Props {
    preloadedForms: IForm[]
    selectedForms: {[id: string]: boolean}
    onSelectForm: (id: string) => void
    onSelectAll: () => void
    onUnselectAll: () => void
    isLoading: boolean
    onValid: () => void
}

const Layout: React.FC<Props> = ({ preloadedForms, selectedForms, onSelectAll, onUnselectAll, onSelectForm, isLoading, onValid }) => {
    const classes = useStyles()
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Dialog fullWidth fullScreen={!matches} open={true} classes={{paper: classes.paper}}>
            <DialogTitle style={{fontWeight: "bold"}} disableTypography>
                <Typography variant="h5" style={{fontWeight: "bold"}}>{"Générer les premiers formulaires"}</Typography>
            </DialogTitle>
            <ButtonActions>
                <Button onClick={onSelectAll} color="primary">Tout séléctionner</Button>
                <Button onClick={onUnselectAll}color="primary">Tout déséléctionner</Button>
            </ButtonActions>
            <FormContainer>
                {preloadedForms.map((form) => (
                    <FormControlLabel
                        key={form.id}
                        className={classes.formControl}
                        control={<Checkbox checked={selectedForms[form.id] === true}
                        onChange={() => {
                            onSelectForm(form.id)
                        }}
                        name={form.id} />}
                        label={form.room}
                    />
                ))}
            </FormContainer>
            <ButtonActions>
                <Button onClick={onValid} disabled={isLoading} color="primary" variant="contained">
                    {isLoading ? <CircularProgress size={20} color="secondary" /> : "Générer les formulaires"}
                </Button>
            </ButtonActions>
        </Dialog>
    )
}

export default Layout