import { CircularProgress, Typography } from '@material-ui/core'
import React from 'react'
import Drawer from '../../../lib/ui/Drawer'
import { MainContainer, QrReaderView, useStyles } from './style'
import FillFormView from '../FillFormView'

interface Props {
    onError: (err: any) => void
    onScan: (data: string | null) => void
    error?: string
    facingMode?: "user" | "environment"
    onLoad?: () => void
    loading?: boolean
    fillForm?: boolean;
}

const Layout: React.FC<Props> = ({ onError, onScan, error, facingMode, onLoad, loading, fillForm }) => {
    const classes = useStyles()

    return (
        <Drawer title={"QRCode"}>
            <MainContainer>
                {fillForm && <FillFormView/>}
                <Typography style={{padding: "0 10px"}} variant="h5">Scan un Qr-Code pour accéder à un formulaire</Typography>
                <br/>
                {loading && <CircularProgress color="primary"/>}
                {!fillForm && <QrReaderView
                    delay={500}
                    className="qr-image-wrapper"
                    onError={onError}
                    onScan={onScan}
                    facingMode={facingMode}
                    onLoad={onLoad}
                    loading={loading}
                />}
                {error && !loading &&
                    <>
                        <br/>
                        <br/>
                        <br/>
                        <Typography color="error" variant="subtitle1">{"Une erreur est survenu : "}</Typography>
                        <br/>
                        <Typography color="error" variant="subtitle1">{error}</Typography>
                    </>
                }
            </MainContainer>
        </Drawer>
    )
}

export default Layout