import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
import { Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>  
    createStyles({
        main: {
            [theme.breakpoints.down("sm")]: {
                padding: "0 20px"
            }
        },
        extensionsList: {
            flexWrap: "wrap",
            display: "flex",
            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            }
        },
        addon: {
            margin: "20px"
        }
    })
)