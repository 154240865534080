import { User, auth } from 'firebase'
import { displayName } from 'qrcode.react'
import React, { useEffect, useState } from 'react'
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useHistory, useRouteMatch } from 'react-router'
import { connect } from 'react-redux'
import swal from '@sweetalert/with-react';
import { IUser } from '../../../data/types/models/User';
import { Address, IGym } from '../../../data/types/models/Gym';
import SalesPassword from '../../SignUp/Steps/SalesPassword';
import GymInfo from '../../SignUp/Steps/GymInfo';
import SelectGym from '../../SignUp/Steps/SelectGym';
import { sendRequest } from '../../../data/firebase/newApi';
import api from '../../../data/firebase/api';
import PaiementInfos from '../../SignUp/Steps/PaiementInfos';
import LoaderView from '../../SignUp/Steps/LoaderView';
import { routes } from '../../../lib/utils/routes';
import { StepContainer } from '../../SignUp/style';
import { Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import { useStyles } from './styles'
import { AppReduxState } from '../../../data/types/appReduxState';
import { setCurrentGym } from '../../../data/actions/gyms';
import SubscriptionDetails, { SubscriptionDetailsValues } from '../../../views/SubscribeGym/SubscriptionDetails';
import PaiementDetails from '../../../views/SubscribeGym/PaiementDetails';

interface Props {
    dispatch: any
    user: IUser
}

interface State {
    gym?: IGym
    billingAddress?: Address
}

const AddGymDialog: React.FC<Props> = ({ user, dispatch }) => {
    const [index, setIndex] = useState(0)
    const [state, setState] = useState<State>({})
    const [billingValues, setBillingValues] = useState<SubscriptionDetailsValues | undefined>(undefined)
    const [isLoading, setLoading] = useState(false)
    const [errorSignIn, setErrorSignIn] = useState<string | undefined>(undefined)
    const match = useRouteMatch(routes.GYMS + "add")
    const history = useHistory()
    const stripe = useStripe()
    const elements = useElements()
    const classes = useStyles()
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    if (!match || !match.isExact) {
        return (null)
    }
    let totalStep = 4
    const SelectGymStep = <SelectGym
        onBack={() => {
            history.push(routes.GYMS)
        }}
        onDone={({ gym }) => {
            setState({ ...state, gym })
            setIndex(index + 1)
        }}
        stepNumber={index + 1}
        totalStep={totalStep}
    />
    const GymInfoStep = <GymInfo
        gym={state.gym || { id: "Nom de la salle inconnu", name: "Nom de la salle inconnu", app: "App Inconnu" }}
        onBack={() => {
            setIndex(index - 1)
        }}
        onDone={(gym) => {
            setState({ ...state, gym: { ...state.gym, ...gym } })
            setIndex(index + 1)
        }}
        stepNumber={index + 1}
        totalStep={totalStep}

    />
    const SalesPasswordStep = <SalesPassword
        onDone={({ password }) => {
            //@ts-ignore
            setState({ ...state, gym: { ...state.gym, salesPassword: password } })
            setIndex(index + 1)
        }}
        gymName={state.gym?.name || ""}
        onBack={() => {
            setIndex(index - 1)
        }}
        stepNumber={index + 1}
        totalStep={totalStep}

    />
    const sendEverything = async (paymentMethodId: string, billingAddress: Address) => {
        let data: {
            gym: IGym
            billingAddressGym: Address
            // paymentMethodId: string;
        } = {
            billingAddressGym: {
                mainAddress: billingAddress.mainAddress,
                city: billingAddress.city,
                postalCode: billingAddress.postalCode
            },
            gym: state.gym,
            // paymentMethodId: paymentMethodId
        }
        try {
            const response = await sendRequest({ endpoint: "gyms", method: "post", data })
            await api.loadAdminItems(auth().currentUser.uid, dispatch)
            const gym = response.body as IGym
            dispatch(setCurrentGym(gym))
            history.push("/forms-add-preloaded")
        } catch (e) {
            swal({
                title: "Erreur",
                text: e.localizedMessage,
                icon: "error",
                button: "Revenir"
            })
            setIndex(index - 1)
        }
    }
    const SubscriptionDetailsStep = <SubscriptionDetails
        onBack={() => {
            setIndex(index - 1)
        }}
        gym={state.gym}
        onDone={(values) => {
            setBillingValues(values)
            setIndex(index + 1)
        }}
    />
    const PaiementDetailsStep = <PaiementDetails
        gym={state.gym}
        newGym
        onBack={() => {
            setIndex(index - 1)
        }}
        onDone={() => {
            setIndex(index + 1)
        }}
        values={billingValues}
    />
    const LoaderStep = <LoaderView
        onDone={async () => {
            try {
                await api.loadAdminItems(auth().currentUser.uid, dispatch)
                dispatch(setCurrentGym(state.gym))
                history.push("/forms-add-preloaded")
            } catch (e) {
                swal({
                    title: "Erreur",
                    text: e.localizedMessage,
                    icon: "error",
                    button: "Revenir"
                })
                setIndex(index - 1)
            }
        }}
        gymName={state.gym?.name || ""}
    />
    const steps = [SelectGymStep, GymInfoStep, SalesPasswordStep, SubscriptionDetailsStep, PaiementDetailsStep, LoaderStep]
    let CurrentStep: JSX.Element | null = null
    if (steps.length - 1 >= index) {
        CurrentStep = steps[index]
    }
    return (
        <Dialog fullWidth fullScreen={!matches} open={true} classes={{ paper: classes.paper }}>
            <StepContainer>
                {CurrentStep}
            </StepContainer>
        </Dialog>
    )
}

export default connect((rootState: AppReduxState) => ({
    user: rootState.user
}))(AddGymDialog)