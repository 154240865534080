import * as firebase from 'firebase'
import { Button, CircularProgress, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { loginAction } from '../../data/actions/auth';
import { setCurrentGym } from '../../data/actions/gyms';
import { AppReduxState } from '../../data/types/appReduxState';
import { IGym } from '../../data/types/models/Gym';
import { arrayFromEntitiesCollection } from '../../lib/utils/collections';
import { routes } from '../../lib/utils/routes';
import { ButtonsDiv, DivContainer, ImageLogo, MainContainer, SignupLink } from './style';
import { auth } from 'firebase'
import api from '../../data/firebase/api';
import AppConfig from '../../AppConfig'

interface Props extends RouteComponentProps {
    gyms: IGym[]
    dispatch: any
}

const Login: React.FC<Props> = ({history, gyms, dispatch}) => {
    const [admin, setIsAdmin] = useState("admin")
    const [email, setEmail] = useState<{error?: boolean, value?: string}>({error: undefined, value: undefined})
    const [password, setPassword] = useState<{error?: boolean, value?: string}>({error: undefined, value: undefined})
    const [loading, setLoading] = useState(false)

    const onSend = async () => {
        setLoading(true)
        if (admin === "admin") {
            try {
                const authRes = await auth().signInWithEmailAndPassword(email.value || "", password.value || "")
                await api.loadAdminItems(authRes.user?.uid || "", dispatch)
                dispatch(loginAction({type: "admin"}))
                history.push(routes.REPORTS)
            } catch (e) {
                setEmail({error: true, value: email.value})
            }
        } else if (admin === "coach") {
            const getGym = await firebase.database().ref('salesPassword/' + password.value).once('value')
            let gym = getGym.val()
            if (gym) {
                const authRes = await auth().signInAnonymously()
                dispatch(loginAction({type: "coach"}))
                await api.loadSalesItems(gym.id || "", dispatch)
                history.push(routes.SCAN_VIEW)
            } else {
                setPassword({error: true, value: ""})
            }
        } else {
            setPassword({error: true, value: ""})
        }
        setLoading(false)
    }

    return (
        <MainContainer>
            <DivContainer onSubmit={(e) => {
                e.preventDefault()
                onSend()
            }}>
                <ImageLogo/>
                <FormControl component="fieldset" style={{textAlign: "center"}}>
                    <RadioGroup row aria-label="position" name="position" defaultValue="admin" onChange={(e) => {setIsAdmin(e.target.value)}}>
                        <FormControlLabel
                            value="admin"
                            control={<Radio color="primary" />}
                            label="Admin"
                            color={admin === "admin" ? "primary" : undefined}
                            labelPlacement="bottom"
                        />
                        <FormControlLabel
                            value="coach"
                            control={<Radio color="primary" />}
                            color={admin === "coach" ? "primary" : undefined}
                            label={AppConfig.app === "fitcarebarber" ? "Coiffeur" : "Commercial"}
                            labelPlacement="bottom"
                        />
                    </RadioGroup>
                </FormControl>
                <br/>
                {
                    admin === "admin" && 
                    <>
                        <TextField 
                            label="Email"
                            placeholder={"email@"+ AppConfig.app +".fr"}
                            variant="outlined"
                            color="primary"
                            fullWidth
                            type="email"
                            autoComplete="email"
                            value={email.value}
                            onChange={(event) => {setEmail({value: event.target.value, error: false})}}
                            error={email.error}
                            helperText={email.error && "Identifiants non reconnu ou mot de passe incorrect"}
                        />
                        <br/>
                    </>
                }
                <TextField 
                    label="Mot de passe"
                    placeholder="Mot de passe" 
                    variant="outlined" 
                    fullWidth
                    color="primary" 
                    type="password" 
                    value={password.value}
                    onChange={(event) => {setPassword({value: event.target.value, error: false})}}
                    error={password.error}
                    helperText={password.error && "Mot de passe invalide."}
                />
                <ButtonsDiv>
                    <br/>
                    <Button type="submit" disabled={loading} variant="contained" color="primary">{
                        loading ? <CircularProgress size={20} color="secondary" /> : "Connexion"
                    }</Button>
                    <br/>
                    {
                        AppConfig.hasSignUp && 
                        <SignupLink to={routes.SIGNUP}>S'inscrire</SignupLink>
                    }
                </ButtonsDiv>
            </DivContainer>
        </MainContainer>
    )
}

export default withRouter(connect(({gyms}: AppReduxState) => {
    let current = gyms.current
    //@ts-ignore
    gyms = {...gyms, current: undefined }
    delete gyms.current
    return {
        gyms: arrayFromEntitiesCollection(gyms)
    }}
)(Login))