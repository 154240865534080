import React, { useState } from 'react'
import Layout from './Layout'
import { withRouter, RouteComponentProps, useRouteMatch } from 'react-router'
import { routes } from '../../lib/utils/routes'

interface Props extends RouteComponentProps {

}

const ScanView: React.FC<Props> = ({ history }) => {
    const [error, setError] = useState(undefined)
    const [facingMode] = useState<"user" | "environment">("environment")
    const [loading, setLoading] = useState(true)
    const fillMatches = useRouteMatch<{id: string}>(routes.FILL_FORM_VIEW + ":id")

    const onScan = (data: string | null) => {
        if (data) {
            history.push("/scan/fill/" + data)
        }
    }

    const onError = (err: any) => {
        setError(err.message)
        setLoading(false)
    }

    const onLoad = () => {
        setError(undefined)
        setLoading(false)
    }

    return (
        <Layout
            onError={onError}
            onScan={onScan}
            error={error}
            facingMode={facingMode}
            onLoad={onLoad}
            loading={loading}
            fillForm={!!fillMatches && fillMatches.isExact}
        />
    )
}

export default withRouter(ScanView)