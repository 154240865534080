import styled from 'styled-components'
import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
    listItemPrimaryText: {
        marginBottom: "10px"
    },
    listItemSecondaryText: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    printAllButton: {
        position: "absolute",
        bottom: "10px",
        right: "10px"
    },
    loadingLayer: {
        position: "absolute",
        backgroundColor: 'rgba(255,255,255, 0.2)',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 100,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    }
})

export const AddButton = styled(IconButton)`
    position: absolute;
    top: 8px;
    right: 8px;

`