import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import { useParams, withRouter, RouteComponentProps } from 'react-router'
import api from '../../../data/firebase/api'
import { connect } from 'react-redux'
import { IForm } from '../../../data/types/models/Form'
import { AppReduxState } from '../../../data/types/appReduxState'
import { routes } from '../../../lib/utils/routes'
import { IGym } from '../../../data/types/models/Gym'

interface Props extends RouteComponentProps {
    forms: {[id: string]: IForm}
    dispatch: any,
    user?: "coach" | "admin"
    gym: IGym
}

const ReportDetailView: React.FC<Props> = ({ forms, dispatch, history, user, gym }) => {
    const { id } = useParams()
    const form = forms[id]
    const [confirmModel, setConfirmModal] = useState(false)

    useEffect(() => {
        if (!form) {
            api.getReports(gym.id, dispatch)
        }
    }, [form, gym])
    return (
        <Layout
            room={form && form.room}
            place={form && form.place}
            description={form && form.description}
            author={form && form.author}
            items={form && form.items}
            date={form && form.date}
            onCancel={() => {history.push(routes.REPORTS)}}
            confirmDelete={confirmModel}
            onConfirmDelete={() => {
                api.removeReport(form, dispatch)
                history.push(routes.REPORTS)
            }}
            onDeleteModal={(bool: boolean) => {
                setConfirmModal(bool)
            }}
            canDelete={user === "admin"}
            pictures={form && form.pictures}
        />
    )
}

export default withRouter(connect(({forms, auth, gyms}: AppReduxState) => ({forms, user: auth.user, gym: gyms.current}))(ReportDetailView))