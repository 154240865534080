import React, { useState } from 'react'

import { connect } from 'react-redux'

import Layout from './Layout'
import { IForm } from '../../data/types/models/Form'
import { AppReduxState } from '../../data/types/appReduxState'
import { withRouter, RouteComponentProps } from 'react-router'
import { routes } from '../../lib/utils/routes'
import api from '../../data/firebase/api'

interface Props extends RouteComponentProps<{id: string}> {
    form?: IForm
    user?: "admin" | "coach"
    dispatch?: any
}

const TemplateFormView: React.FC<Props> = ({ form, history, user, dispatch }) => {
    const [validModal, setValidModal] = useState(false)
    if (!form) {
        history.push(routes.FORMS_LIST_VIEW)
        return null
    }
    return (
        <Layout
            form={form}
            onFill={() => {
                history.push("/scan/fill/" + form.id)
            }}
            onCancelDelete={() => {
                setValidModal(false)
            }}
            onDelete={() => {
                setValidModal(true)
            }}
            onValidDelete={() => {
                //delete Here
                api.removeTemplate(form, dispatch)
                history.push(routes.FORMS_LIST_VIEW)
            }}
            validDeleteModal={validModal}
            canDelete={user === "admin"}
            onEdit={() => {
                history.push(routes.TEMPLATE_FORM_VIEW + form.id + "/edit")
            }}
            onPrint={() => {
                window.print()
            }}
        />
    )
}

interface TStateProps {
    forms: {[id: string]: IForm}
    user?: "coach" | "admin"
}
  
interface TOwnProps {
    id: string
}

export default withRouter(
    connect<TStateProps, {dispatch: any}, RouteComponentProps<{id: string}>, Props, AppReduxState>(
    ({ templatesForm, auth }: AppReduxState) => ({
        forms: templatesForm,
        user: auth.user
    }),
    (dispatch) => ({dispatch}),
    ({forms, user, ...stateProps}, dispatchProps, props) => ({
        form: forms[props.match.params.id],
        user,
        ...dispatchProps,
        ...props
    })
)(TemplateFormView))