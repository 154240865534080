import { Button, Hidden, IconButton, useMediaQuery, Select, MenuItem } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import LogoIcon from '../../icons/components/LogoIcon';
import items from './items';
import { useStyles } from './styles';
import { connect } from 'react-redux';
import { logoutAction } from '../../../data/actions/auth';
import { IGym } from '../../../data/types/models/Gym';
import { arrayFromEntitiesCollection } from '../../utils/collections';
import { AppReduxState } from '../../../data/types/appReduxState';
import { setCurrentGym } from '../../../data/actions/gyms';
import moment from 'moment'

interface Props extends RouteComponentProps {
    title?: string
    buttonAction?: {title: string, onClick: () => void};
    window?: () => Window;
    logout: () => any
    gyms: {[id: string]: IGym}
    setCurrentGym: (gym: IGym) => void
    user?: "coach" | "admin"
}

const AppDrawer: FunctionComponent<Props> = ({ title, children, history, window, match, buttonAction, logout, gyms, setCurrentGym, user }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const matches = useMediaQuery("@media print")
  const valueSelected = (match.path.split('/'))[1]

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const current = gyms.current?.id
  const currentGym = gyms.current
  gyms = {...gyms, current: {...gyms.current}}
  delete gyms.current
  const gymsList = arrayFromEntitiesCollection(gyms)
  let trialTime = -1
  if (currentGym.subscription && currentGym.subscription.status === "trialing") {
    let endTrial = moment(currentGym.subscription.trial_end * 1000)
    let today = moment(new Date())
    trialTime = endTrial.diff(today, 'day') + 1
  }
  const drawer = (
    <div style={{display: "flex", flexDirection: "column"}}>
      <LogoIcon style={{width: "76%", margin: "auto", display: "block", padding: "71px 0"}}/>
      {
        user === "admin" && 
        <Select
          className={classes.select}
          value={current}
          color="secondary"
          onChange={({target}) => {
            //@ts-ignore
            setCurrentGym(gyms[target.value])
          }}
        >
        {gymsList.map((gym) => (
          <MenuItem key={gym.id} value={gym.id}>{gym.name.toUpperCase()}</MenuItem>
        ))}
        </Select>
      }
      <List>
        {items.filter((item) => item.adminOnly ? user === "admin" : true).map(({label, link, value, icon}, index) => {
          let Icon = icon
          return (
            <ListItem button className={classes.listItem} key={label} onClick={() => {
              if (value === "logout") {
                logout()
              }
              history.push(link)
            }} selected={value === valueSelected}>
              <ListItemIcon><Icon selected={value === valueSelected}/></ListItemIcon>
              <ListItemText classes={{primary: classes.listItemText}} primary={label} />
            </ListItem>
          )
        })}
        {trialTime >= 0 && (
          <ListItem button onClick={() => {
            history.push("/gyms/" + current)
          }}>
              <ListItemText
                classes={{primary: classes.trialTimeText, secondary: classes.trialTimeTextButton}}
                primary={`Il te reste ${trialTime} jour${trialTime > 1 ? "s" : ""} sur ta periode d'essai.`}
                secondary={"Débuter mon abonnement dès maintenant"}
              />
          </ListItem>
        )}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      {matches && <LogoIcon style={{width: "20%", right: 50, bottom: 0, position: "fixed"}}/>}
      <AppBar position="fixed" className={clsx(classes.appBar, "no-print")}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={clsx([classes.menuButton, "no-print"])}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h4" noWrap className={classes.title}>
            {title}
          </Typography>
          {buttonAction &&
            <Hidden smDown implementation="css">
              <Button
                color="primary" 
                variant="contained" 
                className={clsx(["no-print",classes.buttonAction])}
                onClick={buttonAction.onClick}
              >{buttonAction.title}</Button>
            </Hidden>
          }
        </Toolbar>
      </AppBar>
      {!matches && <nav className={clsx([classes.drawer, "no-print"])} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
          }
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Hidden mdUp implementation="css">
          {buttonAction &&
            <Button
              color="primary" 
              variant="contained" 
              className={clsx(["no-print", classes.buttonAction])} 
              onClick={buttonAction.onClick}
            >{buttonAction.title}</Button>
          }
        </Hidden>
        {children}
      </main>
    </div>
  );
}

export default withRouter(connect(({gyms, auth}: AppReduxState) => ({gyms, user: auth.user}), {logout: logoutAction, setCurrentGym})(AppDrawer))