import React from 'react';
import { StylesProvider, ThemeProvider } from '@material-ui/core';
import { Provider } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import '@sweetalert/with-react'
import './data/firebase'
import './App.css';
import Theme from './lib/ui/theme'
import Routes from './lib/routes'
import { BrowserRouter as Router } from 'react-router-dom';
import configureStore from './data/store'
import config from './config';
import { PersistGate } from 'redux-persist/integration/react'
import CacheBuster from './CacheBuster';
import Helmet from 'react-helmet'
import AppTypeConfig from './AppConfig'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const stripePromise = loadStripe(config.stripe.apiKey);

const { store, persistor } = configureStore()

function App() {
  const theme = Theme({
    primaryColor: AppTypeConfig.primaryColor,
    secondaryColor: AppTypeConfig.secondaryColor,
    secondaryTextColor: AppTypeConfig.secondaryTextColor
  })
  return (
    <Provider store={store}>
      <Helmet>
        <title>{AppTypeConfig.htmlTitle}</title>
      </Helmet>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Elements stripe={stripePromise}>
              {
                //@ts-ignore
                <CacheBuster>
                  {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                      // You can decide how and when you want to force reload
                      refreshCacheAndReload();
                    }
                    return null
                  }}
                </CacheBuster>
              }
              <Router>
                <Routes />
              </Router>
            </Elements>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
