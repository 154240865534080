import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { AppReduxState } from '../../data/types/appReduxState'
import { Addon, AddonsList } from '../../data/types/models/Addons'
import { HygUpData } from '../../data/types/models/Addons/HygUp'
import { routes } from '../../lib/utils/routes'
import Layout from './Layout'

interface Props {
    addon?: Addon
    data?: HygUpData
}

const HygUp: React.FC<Props> = ({ addon }) => {
    const [loading, setLoading] = useState(!addon)
    const history = useHistory()
    return (
        <Layout
            loading={loading}
            data={addon && addon.data as HygUpData}
            onUpdateData={(data) => {

            }}
            onOrder={() => {
                history.push(routes.HYGUP_ORDER)
            }}
            onPrint={() => {
                window.print()
            }}
        />
    )
}

export default connect((rootState: AppReduxState) => ({addon: rootState.addons[AddonsList.HygUp]}))(HygUp)