import * as firebase from 'firebase'
import { IForm } from '../types/models/Form'
import { arrayFromEntitiesCollection } from '../../lib/utils/collections'
import { setForms, setForm } from '../actions/forms'
import { setTemplates, setTemplateAction } from '../actions/templates'
import { setUser } from '../actions/users'
import { IGym } from '../types/models/Gym'
import { IUser } from '../types/models/User'
import { setCurrentGym, setGyms } from '../actions/gyms'
import { setAddon, setAddons } from '../actions/addons'
import cuid from 'cuid'
import { AddonsList } from '../types/models/Addons'
import { sendRequest } from './newApi'

async function getReports(gymId: string, dispatch: any) {
    const res = await firebase.database().ref('reports/' + gymId + "/").once('value')
    const values = res.val()
    if (!values) {
        dispatch(setForms(values || {}))
        return res.val()
    }
    for (const [key, value] of Object.entries(values)) {
        // @ts-ignore
        if (value.date) {
            // @ts-ignore
            value.date = new Date(value.date)
        }
        // @ts-ignore
        if (value.createdOn) {
            // @ts-ignore
            value.createdOn = new Date(value.date)
        }
    }
    dispatch(setForms(values || {}))
    return res.val()
}

async function getTemplatesReport(gymId: string, dispatch: any): Promise<IForm[]> {
    const res = await firebase.database().ref('templates/' + gymId + "/").once('value')
    const values = res.val()
    if (!values) {
        dispatch(setTemplates({}))
        return res.val()
    }
    for (const [key, value] of Object.entries(values)) {
        // @ts-ignore
        if (value.date) {
            // @ts-ignore
            value.date = new Date(value.date)
        }
        // @ts-ignore
        if (value.createdOn) {
            // @ts-ignore
            value.createdOn = new Date(value.createdOn)
        }
    }
    dispatch(setTemplates(values || {}))
    if (values === null) {
        return []
    }
    const forms = values as { [id: string]: IForm }
    return arrayFromEntitiesCollection(forms)
}

async function setTemplate(form: IForm, dispatch: any) {
    if (form.createdOn && typeof (form.createdOn) !== "number") {
        // @ts-ignore
        form.createdOn = form.createdOn?.getTime()
    } else {
        console.log(form.createdOn)
    }
    for (const [key, value] of Object.entries(form)) {
        if (value === undefined) {
            //@ts-ignore
            form[key] = null
        }
    }
    await firebase.database().ref('templates/' + form.gymId + "/" + form.id).set(form)
    dispatch(setTemplateAction(form))
    return
}

async function getTemplateReport({ id, gymId }: { id: string, gymId: string }, dispatch: any) {
    const res = await firebase.database().ref('templates/' + gymId + "/" + id).once('value')
    const value = res.val()
    if (!value) {
        return value
    }
    // @ts-ignore
    if (value.date) {
        // @ts-ignore
        value.date = new Date(value.date)
    }
    // @ts-ignore
    if (value.createdOn) {
        // @ts-ignore
        value.createdOn = new Date(value.date)
    }
    dispatch(setTemplateAction(value))
    return value
}

async function setReport(form: IForm, dispatch: any) {
    if (form.date) {
        // @ts-ignore
        form.date = form.date.getTime()
    }
    if (form.createdOn) {
        // @ts-ignore
        form.createdOn = form.createdOn?.getTime()
    }
    await firebase.database().ref('reports/' + form.gymId + "/" + form.id).set(form)
    getReports(form.gymId, dispatch)
    return form
}

async function removeReport(form: IForm, dispatch: any) {
    await firebase.database().ref('reports/' + form.gymId + "/" + form.id).remove()
    getReports(form.gymId, dispatch)
    return form
}

async function removeTemplate(form: IForm, dispatch: any) {
    await firebase.database().ref('templates/' + form.gymId + "/" + form.id).remove()
    getTemplatesReport(form.gymId, dispatch)
    return
}

async function loadAdminItems(userId: string, dispatch: any) {
    const getUser = await firebase.database().ref('users/' + userId).once("value")
    const user = getUser.val() as IUser

    dispatch(setUser(user))

    let gyms: { [id: string]: IGym } = {}
    for (let i = 0; i < user.gyms.length; i++) {
        const getGym = await firebase.database().ref("gyms/" + user.gyms[i].id).once("value")
        const gym = getGym.val() as IGym
        const subscription = await sendRequest({ endpoint: "subscriptions", method: "GET", params: {gymId: user.gyms[i].id} })
        gym.subscription = subscription.body
        gyms[gym.id] = gym;
        if (!gyms.current) {
            gyms.current = gym
        }
    }
    dispatch(setGyms(gyms))
    dispatch(setAddons({
        [AddonsList.HygUp]: {
            id: AddonsList.HygUp,
            name: "Hyg'Up",
            isAvailable: true,
            isInstalled: false,
            path: "/addons/hyg-up",
            logoUrl: "https://hyg-up.com/images/logo.png"
        },
    }))
    return
}

async function loadSalesItems(gymId: string, dispatch: any) {
    let gyms: { [id: string]: IGym } = {}

    const getGym = await firebase.database().ref("gyms/" + gymId).once("value")
    const gym = getGym.val() as IGym
    gyms[gym.id] = gym;
    if (!gyms.current) {
        gyms.current = gym
    }
    dispatch(setGyms(gyms))
    return
}

async function getLastAppVersion(): Promise<string> {
    let ref = await firebase.database().ref("appWebVersion").once("value")
    return ref.val()
}

export default {
    getReports,
    getTemplatesReport,
    getTemplateReport,
    setTemplate,
    setReport,
    removeTemplate,
    removeReport,
    loadAdminItems,
    loadSalesItems,
    getLastAppVersion
}