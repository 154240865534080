import dotenv from 'dotenv'

dotenv.config()

export interface Config {
    stripe: {
        apiKey: string;
    }
}

let config;
if (process.env.NODE_ENV === "production") {
    config = require('./config.prod');
} else {
    config = require('./config.dev');
}

export default config.default