import { Button, ButtonGroup, FormHelperText, FormLabel, TextField, Typography } from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Address, IGym } from '../../../data/types/models/Gym'
import { useMainStyles } from '../../../lib/ui/mainStyles'
import { useStyles } from '../styles'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers';
import AppConfig from '../../../AppConfig'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import CardSection from '../../../components/SignUp/Steps/PaiementInfos/CardSection'
import { ButtonsDiv } from '../../../components/SignUp/Steps/stepsStyles'
import { sendRequest } from '../../../data/firebase/newApi'
import swal from '@sweetalert/with-react';
import { captureException } from '@sentry/minimal'
import { PaymentIntent } from '@stripe/stripe-js'
import { Stripe } from 'stripe'
import { FlexDiv } from '../../../lib/ui/Divs/FlexDiv'

interface Props {
    gym: IGym
    onBack: () => void
    onDone: (props: SubscriptionDetailsValues) => void
}

export interface SubscriptionDetailsValues {
    paiement: "monthly" | "yearly"
    name: string;
    phoneNumber: string;
    email: string;
    address: Address
    siret: string
}

const schema = yup.object().shape({
    name: yup.string().required("Le nom complet est obligatoire."),
    phoneNumber: yup.string().min(10, "Le numéro est invalide").required("Le numéro de téléphone est obligatoire."),
    email: yup.string().email().required("L'adresse e-mail est obligatoire."),
});

const SubscriptionDetails: React.FC<Props> = ({ gym, onBack, onDone }) => {
    const mainClasses = useMainStyles({})
    const classes = useStyles()

    const { watch, handleSubmit, setError, clearErrors, errors, control, register, setValue } = useForm<SubscriptionDetailsValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            paiement: "yearly",
            name: `${AppConfig.brandName} ${gym.name}`,
            address: gym.address,
            email: gym.email,
            phoneNumber: gym.phoneNumber,
            siret: gym.siret || ""
        }
    })

    const onSubmit = (values: SubscriptionDetailsValues) => {
        onDone(values)
    }

    return (
        <form className={classes.stepMain} onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="body2" className={mainClasses.stepSectionTitle}>DÉTAILS DE L'ABONNEMENT</Typography>
            <br />
            <div className={classes.paiementDiv}>
                <FormLabel>Choix de l'abonnement : </FormLabel>
                <br />
                <Controller
                    name="paiement"
                    control={control}
                    render={({ value }) => (
                        <ButtonGroup color="primary" style={{ alignSelf: "center" }}>
                            <Button
                                variant={value === "yearly" ? "contained" : undefined}
                                onClick={() => { setValue("paiement", "yearly") }}
                                style={{display: "inline-block"}}
                            >Annuel (948 HT € / an)<br /><span style={{fontSize: "10px"}}>(soit 79 HT € / mois)</span></Button>
                            <Button
                                variant={value === "monthly" ? "contained" : undefined}
                                onClick={() => { setValue("paiement", "monthly") }}
                            >Mensuel (89 HT € / mois)</Button>
                        </ButtonGroup>
                    )}
                />
            </div>
            <br />
            <Controller as={TextField} name="name" control={control} label="Nom" className={classes.textField} error={!!errors.name} helperText={errors.name?.message} />
            <Controller as={TextField} name="email" control={control} type="email" label="Adresse mail" className={classes.textField} error={!!errors.name} helperText={errors.name?.message} />
            <Controller as={TextField} name="address.mainAddress" control={control} label="Adresse" className={classes.textField} error={!!errors.address?.mainAddress} helperText={errors.address?.mainAddress?.message} />
            <Controller as={TextField} name="address.postalCode" control={control} label="Code Postal" className={classes.textField} error={!!errors.address?.postalCode} helperText={errors.address?.postalCode?.message} />
            <Controller as={TextField} name="address.city" control={control} label="Ville" className={classes.textField} error={!!errors.address?.city} helperText={errors.address?.city?.message} />
            <Controller as={TextField} name="phoneNumber" control={control} label="Numéro de téléphone" className={classes.textField} error={!!errors.phoneNumber} helperText={errors.phoneNumber?.message} />
            <Controller as={TextField} name="siret" control={control} label="N°SIRET" className={classes.textField} error={!!errors.siret} helperText={errors.siret?.message} />
            <br />
            <ButtonsDiv>
                <Button onClick={onBack} variant="outlined" color="primary">Retour</Button>
                <Button type='submit' variant="contained" color="primary">Passer au paiement</Button>
            </ButtonsDiv>
        </form>
    )
}

export default SubscriptionDetails