import { Button, ButtonGroup, CircularProgress, FormHelperText, FormLabel, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField, Typography } from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Address, IGym } from '../../../data/types/models/Gym'
import { useMainStyles } from '../../../lib/ui/mainStyles'
import { useStyles } from '../styles'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers';
import AppConfig from '../../../AppConfig'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import CardSection from '../../../components/SignUp/Steps/PaiementInfos/CardSection'
import { ButtonsDiv } from '../../../components/SignUp/Steps/stepsStyles'
import { sendRequest } from '../../../data/firebase/newApi'
import swal from '@sweetalert/with-react';
import { captureException } from '@sentry/minimal'
import { PaymentIntent } from '@stripe/stripe-js'
import { Stripe } from 'stripe'
import { FlexDiv } from '../../../lib/ui/Divs/FlexDiv'
import { SubscriptionDetailsValues } from '../SubscriptionDetails'

interface Props {
    gym: IGym
    values: SubscriptionDetailsValues
    onBack: () => void
    onDone: () => void
    newGym?: boolean
}

const schema = yup.object().shape({
    name: yup.string().required("Le nom complet est obligatoire."),
    phoneNumber: yup.string().min(10, "Le numéro est invalide").required("Le numéro de téléphone est obligatoire."),
    email: yup.string().email().required("L'adresse e-mail est obligatoire."),
});

const PaiementDetails: React.FC<Props> = ({ gym, onBack, onDone, newGym, values }) => {
    const mainClasses = useMainStyles({})
    const classes = useStyles()
    const [cardError, setCardError] = useState<string | undefined>("Les informations de la carte sont incorrect")
    const [cardBlur, setCardBlur] = useState(false)
    const [upcomingInvoice, setUpcomingInvoice] = useState<Stripe.Invoice | undefined>(undefined)
    const [loading, setLoading] = useState(false)
    const [promoCode, setPromoCode] = useState("")
    const [nameOnCard, setNameOnCard] = useState("")
    const [coupon, setCoupon] = useState<{ codeId: string, percentOf: number }>()
    const [errorCoupon, setErrorCoupon] = useState(undefined)

    const stripe = useStripe()
    const elements = useElements();

    async function getUpcomingInvoice() {
        setLoading(true)
        try {
            let invoiceRequest = await sendRequest({
                method: "post", endpoint: "subscriptions/previewInvoice", data: {
                    gymId: gym.id,
                    customerBillingDetails: {
                        data: values.name,
                        email: values.email,
                        address: values.address,
                        phoneNumber: values.phoneNumber
                    },
                    coupon: coupon && coupon.codeId,
                    paiementRecurence: values.paiement
                }
            })
            let invoice = invoiceRequest.body as Stripe.Invoice
            console.log(invoice)
            setUpcomingInvoice(invoice)
        } catch (e) {
            captureException(e)
            swal({
                title: "Erreur durant l'apercu de la facture",
                text: e.message,
                icon: "error",
                button: "Ferme"
            })
        }
        setLoading(false)
    }

    async function checkPromoCode(promoCode) {
        setLoading(true)
        try {
            let code = await sendRequest({ method: "GET", endpoint: "subscriptions/promotionalCodes/" + promoCode })
            if (code.body && code.body.codeId) {
                setCoupon({ codeId: code.body.codeId, percentOf: code.body.percentOff })
                setErrorCoupon(undefined)
            } else {
                setCoupon(undefined)
                setErrorCoupon("Code promotionnel non valide")
            }
        } catch (e) {
            setErrorCoupon("Code promotionnel non valide")
        }
        setLoading(false)
    }

    useEffect(() => {
        getUpcomingInvoice()
    }, [coupon])

    const onSubmit = async () => {
        setLoading(true)
        setCardBlur(true)
        if (!cardError || (upcomingInvoice && upcomingInvoice.total === 0)) {
            try {
                let subscribeRequest = await sendRequest({
                    method: "post", endpoint: "subscriptions", data: {
                        gymId: gym.id,
                        newGym: newGym ? gym : undefined,
                        customerBillingDetails: {
                            data: values.name,
                            email: values.email,
                            address: values.address,
                            phoneNumber: values.phoneNumber
                        },
                        coupon: coupon && coupon.codeId,
                        paiementRecurence: values.paiement
                    }
                })
                let subscription = subscribeRequest.body as Stripe.Subscription
                if (subscription.status !== "active") {
                    let latestInvoice = subscription.latest_invoice as Stripe.Invoice
                    let paymentIntent = latestInvoice.payment_intent as Stripe.PaymentIntent
                    let clientSecret = paymentIntent.client_secret
                    const cardElement = elements.getElement(CardElement);

                    let cardResult = await stripe.confirmCardPayment(clientSecret, {
                        payment_method: {
                            card: cardElement,
                            billing_details: {
                                name: values.name,
                                address: {
                                    line1: values.address.mainAddress,
                                    postal_code: values.address.postalCode,
                                    city: values.address.city
                                },
                                email: values.email,
                                phone: values.phoneNumber
                            },
                        }
                    })
                    if (cardResult.error) {
                        throw cardResult.error
                    } else {
                        swal({
                            title: "Merci pour ta confiance",
                            text: "Ton abonnement à bien été pris en compte",
                            icon: "success",
                        }).then(() => {
                            onDone()
                        })
                    }
                } else {
                    swal({
                        title: "Merci pour ta confiance",
                        text: "Ton abonnement à bien été pris en compte",
                        icon: "success",
                    }).then(() => {
                        onDone()
                    })
                }
            } catch (e) {
                captureException(e)
                swal({
                    title: "Erreur durant la création de l'abonnement",
                    text: e.message,
                    icon: "error",
                    button: "Revenir"
                })
            }
        }
        setLoading(false)
    }
    return (
        <div className={classes.stepMain}>
            <Typography variant="body2" className={mainClasses.stepSectionTitle}>DÉTAILS DU PAIEMENT</Typography>
            <br />
            <FormLabel>Aperçu du paiement : </FormLabel>
            <br />
            {upcomingInvoice ? (
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={3}>Description du produit</TableCell>
                                <TableCell colSpan={1} align="right">HT</TableCell>
                                <TableCell colSpan={1} align="right">TVA</TableCell>
                                <TableCell colSpan={1} align="right">TTC</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {upcomingInvoice.lines.data.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell colSpan={3} component="th" scope="row">
                                        {item.description}
                                    </TableCell>
                                    <TableCell colSpan={1} align="right">{item.amount / 100} €</TableCell>
                                    <TableCell colSpan={1} align="right">{item.tax_amounts.length > 0 ? item.tax_amounts[0].amount / 100 : "--"} €</TableCell>
                                    <TableCell colSpan={1} align="right">{(item.amount + (item.tax_amounts.length > 0 ? item.tax_amounts[0].amount : 0)) / 100} €</TableCell>
                                </TableRow>
                            ))}
                            {upcomingInvoice.discount &&
                                <TableRow>
                                    <TableCell colSpan={3} component="th" scope="row">
                                        REMISE: {upcomingInvoice.discount.coupon.name}
                                    </TableCell>
                                    <TableCell colSpan={3} align="right">-{upcomingInvoice.discount.coupon.percent_off} %</TableCell>
                                </TableRow>
                            }
                            <TableRow>
                                <TableCell colSpan={5} style={{ fontWeight: "bold" }} component="th" scope="row" align="right">TOTAL :</TableCell>
                                <TableCell colSpan={1} style={{ fontWeight: "bold" }} align="right">{upcomingInvoice.total / 100} €</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <CircularProgress size={50} style={{ alignSelf: "center" }} color={"secondary"} />
            )}
            <br />
            <br />
            <FormLabel>Code Promo</FormLabel>
            <FlexDiv alignItems="center" margin="10px 0 0 0">
                <TextField
                    disabled={loading}
                    placeholder={"Ajouter un coupon promo"}
                    fullWidth
                    type={"text"}
                    style={{ marginRight: "20px" }}
                    helperText={errorCoupon
                        ? errorCoupon
                        : ""
                    }
                    inputProps={{
                        style: { padding: "10px 5px 10px 20px", fontSize: "0.8rem" }
                    }}
                    InputProps={{
                        disableUnderline: true,
                        style: {
                            border: "1px solid rgba(139, 149, 154, 0.3)",
                            borderRadius: "5px",
                            boxShadow: "unset",
                            backgroundColor: "inherit",
                            WebkitAppearance: "none"
                        }
                    }}
                    value={promoCode}
                    error={!coupon && !!errorCoupon}
                    // onBlur={handleBlur}
                    onChange={(e) => {
                        setPromoCode(e.target.value.trim())
                    }}
                    onKeyPress={(e) => {
                        if (e.keyCode === 13) {
                            e.preventDefault()
                            checkPromoCode(promoCode)
                        }
                    }}
                    variant="filled"
                    classes={{ root: classes.textFieldPromoCode }}
                />
                <Button color="primary" disabled={!promoCode} variant="outlined" onClick={() => { checkPromoCode(promoCode) }}>Vérifier</Button>
            </FlexDiv>
            <br />
            {upcomingInvoice && upcomingInvoice.total > 0 && (
                <Fragment>
                    <CardSection
                        postalCode={values.address.postalCode}
                        onBlur={() => {
                            setCardBlur(true)
                        }}
                        onChange={(e) => {
                            if (e.complete) {
                                setCardError(undefined)
                            } else if (e.empty) {
                                setCardError("Les informations de la carte sont incorrect")
                            } else if (e.error) {
                                if (e.error.code === "invalid_number") {
                                    setCardError("Numéro de carte invalid")
                                } else {
                                    setCardError("La carte n'a pas été reconnu : " + e.error.message)
                                }
                            }
                        }}
                    />
                    {cardBlur && cardError && <FormHelperText error>{cardError}</FormHelperText>}
                </Fragment>
            )}
            <br />
            <ButtonsDiv>
                <Button disabled={loading} onClick={onBack}  variant="outlined" color="primary">Retour</Button>
                <Button disabled={!stripe || loading} variant="contained" color="primary" onClick={onSubmit}>{loading ? <CircularProgress size={20} color="secondary"/> : "Finaliser mon abonnement"}</Button>
            </ButtonsDiv>
        </div>
    )
}

export default PaiementDetails