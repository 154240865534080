import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import { IForm } from '../../data/types/models/Form'
import api from '../../data/firebase/api'
import { connect } from 'react-redux'
import { AppReduxState } from '../../data/types/appReduxState'
import { arrayFromEntitiesCollection } from '../../lib/utils/collections'
import { RouteComponentProps, withRouter, useRouteMatch } from 'react-router'
import { routes } from '../../lib/utils/routes'
import { IGym } from '../../data/types/models/Gym'
import moment from 'moment'
import { sameDay } from '../../lib/utils/date'
import { ReportsFilters } from './types'

interface Props extends RouteComponentProps {
    dispatch: any
    forms: IForm[]
    gym: IGym
    templates: IForm[]
}

function filterAndGenerateForms(forms: IForm[], filters?: ReportsFilters): IForm[][] {
    let separatedForms: IForm[][] = []
    let dayForms: IForm[] | undefined = undefined
    let lastDate: Date | undefined = undefined

    if (filters) {
        forms = forms.filter((form) => {
            if (filters.date && !sameDay(form.date, filters.date.toDate())) {
                return false
            }
            if (filters.templates.length > 0 && !filters.templates.find((template) => template === form.templateId)) {
                return false
            }
            if (filters.authors.length > 0 && !filters.authors.find((author) => author === form.author)) {
                return false
            }
            if ((filters.issue !== undefined && filters.issue !== null) && filters.issue !== form.hasIssue) {
                return false
            }
            return true
        })
    }
    forms.forEach((form) => {
        if (form.date && (!lastDate || !sameDay(lastDate, form.date))) {
            lastDate = form.date
            if (dayForms) {
                separatedForms.push(dayForms)
            }
            dayForms = [form]
        } else if (form.date && lastDate && sameDay(lastDate, form.date)) {
            if (!dayForms) {
                dayForms = [form]
            } else {
                dayForms.push(form)
            }
        }
    })
    if (dayForms) {
        separatedForms.push(dayForms)
    }
    return separatedForms
}

const ReportsView: React.FC<Props> = ({ dispatch, forms, history, gym, templates }) => {
    const [isLoading, setLoading] = useState(false)
    const reportDetailMatches = useRouteMatch<{ id: string }>(routes.REPORTS + ":id")
    const [filters, setFilters] = useState<ReportsFilters>({ templates: [], authors: [] })
    const [allAuthors, setAuthors] = useState<string[]>(() => {
        let authors = forms.reduce<{ [id: string]: any }>((previous, form) => {
            if (previous[form.author]) {
                return previous
            } else {
                previous[form.author] = true
                return previous
            }
        }, {})
        let res: string[] = []
        for (const [key, value] of Object.entries(authors)) {
            res.push(key)
        }
        return res
    })
    useEffect(() => {
        let authors = forms.reduce<{ [id: string]: any }>((previous, form) => {
            if (previous[form.author]) {
                return previous
            } else {
                previous[form.author] = true
                return previous
            }
        }, {})
        let res: string[] = []
        for (const [key, value] of Object.entries(authors)) {
            res.push(key)
        }
        setAuthors(res)
    }, [forms, gym])

    const getForms = async () => {
        setLoading(true)
        await api.getReports(gym.id, dispatch)
        setLoading(false)
    }

    useEffect(() => {
        getForms()
    }, [forms.length, gym])

    const [filteredForms, setFilteredForms] = useState<IForm[][]>(filterAndGenerateForms(forms, filters))
    const [daysForm, setDaysForms] = useState(filterAndGenerateForms(forms, {...filters, date: undefined}))
    useEffect(() => {
        setFilteredForms(filterAndGenerateForms(forms, filters))
        setDaysForms(filterAndGenerateForms(forms, {...filters, date: undefined}))
    }, [forms, filters, gym])
    return (
        <Layout
            forms={filteredForms}
            onSelect={(id) => { history.push(routes.REPORTS_DETAIL_VIEW + id) }}
            reportDetailView={reportDetailMatches?.isExact || false}
            filters={filters}
            onChange={({ name, value }) => {
                let newFilters = { ...filters }
                newFilters[name] = value
                setFilters(newFilters)
            }}
            templates={templates}
            authors={allAuthors}
            allForms={daysForm}
            onReset={() => {setFilters({templates: [], authors: []})}}
            isLoading={isLoading}
        />
    )
}

export default withRouter(connect(({ forms, gyms, templatesForm }: AppReduxState) => ({
    forms: arrayFromEntitiesCollection(forms).filter((gym) => gym.gymId === gyms.current.id).sort((a, b) => {
        if (!b.date.getTime || !a.date.getTime) {
            console.log(b, a)
            return 1
        } else if (a.date && b.date) {
            return b.date.getTime() - a.date.getTime()
        } else {
            return 1
        }
    }),
    templates: arrayFromEntitiesCollection(templatesForm).filter((gym) => gym.gymId === gyms.current.id).sort((a, b) => {
        return a.room.localeCompare(b.room)
    }),
    gym: gyms.current
}))(ReportsView))