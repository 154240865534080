import { useTheme } from "@material-ui/core";
import React from "react";

const FormsIcon: React.FC<React.SVGAttributes<HTMLOrSVGElement> & { selected?: boolean }> = ({ selected, ...props }) => {
  const theme = useTheme()
  const secondary = theme.palette.secondaryText
  return (
    <svg {...props} style={{ fill: selected ? secondary : "black" }} xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 26 32">
      <g id="checklist" transform="translate(-44.001)">
        <g id="Group_3" data-name="Group 3" transform="translate(55.48 9.482)">
          <g id="Group_2" data-name="Group 2">
            <path id="Path_10" data-name="Path 10" d="M256.074,152h-9.419a.654.654,0,0,0,0,1.308h9.419a.654.654,0,0,0,0-1.308Z" transform="translate(-246.001 -152)" />
          </g>
        </g>
        <g id="Group_5" data-name="Group 5" transform="translate(62.577 12.476)">
          <g id="Group_4" data-name="Group 4" transform="translate(0)">
            <path id="Path_11" data-name="Path 11" d="M349.057,200.193a.654.654,0,1,0,.192.462A.659.659,0,0,0,349.057,200.193Z" transform="translate(-347.941 -200.001)" />
          </g>
        </g>
        <g id="Group_7" data-name="Group 7" transform="translate(56.346 2.813)">
          <g id="Group_6" data-name="Group 6" transform="translate(0)">
            <path id="Path_12" data-name="Path 12" d="M247.108,43.193a.654.654,0,1,0,.192.462A.659.659,0,0,0,247.108,43.193Z" transform="translate(-245.991 -43.001)" />
          </g>
        </g>
        <g id="Group_9" data-name="Group 9" transform="translate(56.196 12.476)">
          <g id="Group_8" data-name="Group 8" transform="translate(0)">
            <path id="Path_13" data-name="Path 13" d="M250.572,200h-3.916a.654.654,0,0,0,0,1.308h3.916a.654.654,0,0,0,0-1.308Z" transform="translate(-246.001 -200)" />
          </g>
        </g>
        <g id="Group_11" data-name="Group 11" transform="translate(55.48 16.219)">
          <g id="Group_10" data-name="Group 10">
            <path id="Path_14" data-name="Path 14" d="M256.074,260h-9.419a.654.654,0,0,0,0,1.308h9.419a.654.654,0,0,0,0-1.308Z" transform="translate(-246.001 -260)" />
          </g>
        </g>
        <g id="Group_13" data-name="Group 13" transform="translate(62.577 19.214)">
          <g id="Group_12" data-name="Group 12" transform="translate(0)">
            <path id="Path_15" data-name="Path 15" d="M349.057,308.193a.654.654,0,1,0,.192.462A.659.659,0,0,0,349.057,308.193Z" transform="translate(-347.941 -308.001)" />
          </g>
        </g>
        <g id="Group_15" data-name="Group 15" transform="translate(56.196 19.214)">
          <g id="Group_14" data-name="Group 14" transform="translate(0)">
            <path id="Path_16" data-name="Path 16" d="M250.572,308h-3.916a.654.654,0,1,0,0,1.308h3.916a.654.654,0,1,0,0-1.308Z" transform="translate(-246.001 -308)" />
          </g>
        </g>
        <g id="Group_17" data-name="Group 17" transform="translate(55.48 22.956)">
          <g id="Group_16" data-name="Group 16">
            <path id="Path_17" data-name="Path 17" d="M256.074,368h-9.419a.654.654,0,0,0,0,1.308h9.419a.654.654,0,1,0,0-1.308Z" transform="translate(-246.001 -368)" />
          </g>
        </g>
        <g id="Group_19" data-name="Group 19" transform="translate(62.577 25.721)">
          <g id="Group_18" data-name="Group 18" transform="translate(0)">
            <path id="Path_18" data-name="Path 18" d="M349.057,416.193a.654.654,0,1,0,.192.462A.659.659,0,0,0,349.057,416.193Z" transform="translate(-347.941 -416.001)" />
          </g>
        </g>
        <g id="Group_21" data-name="Group 21" transform="translate(56.196 25.721)">
          <g id="Group_20" data-name="Group 20" transform="translate(0)">
            <path id="Path_19" data-name="Path 19" d="M250.572,416h-3.916a.654.654,0,1,0,0,1.308h3.916a.654.654,0,1,0,0-1.308Z" transform="translate(-246.001 -416)" />
          </g>
        </g>
        <g id="Group_23" data-name="Group 23" transform="translate(44.001)">
          <g id="Group_22" data-name="Group 22" transform="translate(0)">
            <path id="Path_20" data-name="Path 20" d="M67.011,2.438H62.328a3.72,3.72,0,0,0-2.6-1.063h-.537a2.432,2.432,0,0,0-4.381,0h-.538a3.72,3.72,0,0,0-2.6,1.063H46.991A3.022,3.022,0,0,0,44,5.485V28.953A3.022,3.022,0,0,0,46.991,32h20.02A3.022,3.022,0,0,0,70,28.953V5.485A3.022,3.022,0,0,0,67.011,2.438Zm-12.739.187h.956a.615.615,0,0,0,.591-.459,1.22,1.22,0,0,1,2.364,0,.615.615,0,0,0,.591.459h.954A2.568,2.568,0,0,1,62.264,5H51.736A2.568,2.568,0,0,1,54.272,2.625Zm14.5,26.328a1.783,1.783,0,0,1-1.763,1.8H46.991a1.783,1.783,0,0,1-1.763-1.8V5.485a1.783,1.783,0,0,1,1.763-1.8h3.823A3.884,3.884,0,0,0,50.5,5.219v.406a.619.619,0,0,0,.613.625H62.887a.619.619,0,0,0,.613-.625V5.219a3.884,3.884,0,0,0-.313-1.531h3.824a1.783,1.783,0,0,1,1.763,1.8Z" transform="translate(-44.001)" />
          </g>
        </g>
        <g id="Group_25" data-name="Group 25" transform="translate(48.008 9.099)">
          <g id="Group_24" data-name="Group 24" transform="translate(0)">
            <path id="Path_21" data-name="Path 21" d="M110.962,146.859a.654.654,0,0,0-.925,0l-2.8,2.8-.862-.862a.654.654,0,0,0-.925.925l1.324,1.324a.654.654,0,0,0,.925,0l3.26-3.26A.654.654,0,0,0,110.962,146.859Z" transform="translate(-105.261 -146.667)" />
          </g>
        </g>
        <g id="Group_27" data-name="Group 27" transform="translate(47.997 22.581)">
          <g id="Group_26" data-name="Group 26" transform="translate(0)">
            <path id="Path_22" data-name="Path 22" d="M113.795,368h-3.14a.654.654,0,0,0-.654.654v3.14a.654.654,0,0,0,.654.654h3.14a.654.654,0,0,0,.654-.654v-3.14A.654.654,0,0,0,113.795,368Zm-.654,3.14h-1.831v-1.831h1.831Z" transform="translate(-110.001 -368)" />
          </g>
        </g>
        <g id="Group_29" data-name="Group 29" transform="translate(47.997 16.134)">
          <g id="Group_28" data-name="Group 28" transform="translate(0)">
            <path id="Path_23" data-name="Path 23" d="M113.795,260h-3.14a.654.654,0,0,0-.654.654v3.14a.654.654,0,0,0,.654.654h3.14a.654.654,0,0,0,.654-.654v-3.14A.654.654,0,0,0,113.795,260Zm-.654,3.14h-1.831v-1.831h1.831Z" transform="translate(-110.001 -260)" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default FormsIcon;
