import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
import { Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>  
    createStyles({
        card: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px",
            backgroundColor: "white",
            boxShadow: theme.shadows[5],
            "&:hover": {
                boxShadow: theme.shadows[10],
                cursor: "pointer"
            },
            borderRadius: "10px",
            width: "180px",
            height: "180px",
            boxSizing: "border-box"
        },
        logo: {
            width: "80px",
            height: "80px",
            objectFit: "contain",
        },
        addonName: {
            fontWeight: "bold",
            textAlign: "center"
        }
    })
)