import { AuthState } from "../types/appReduxState";
import Cookies from 'js-cookie'
import { LOGIN_ACTION_TYPE, LOGOUT_ACTION_TYPE } from "../actions/auth";
import { auth } from 'firebase'

const defaultState = {
    isConnected: false,
}

const authReducer = (
    //@ts-ignore
    state: AuthState = defaultState,
    {type, payload}: {type: string, payload: any}
) => {
    switch (type) {
        case LOGIN_ACTION_TYPE:
            return {
                isConnected: true,
                user: payload
            }

        case LOGOUT_ACTION_TYPE:
            auth().signOut()
            return {
                isConnected: false,
                user: undefined
            }
        default:
            return state
    }
}
  
export default authReducer;
  