import { Button, Checkbox, CircularProgress, Container, Dialog, DialogTitle, FormControlLabel, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import React, { Fragment } from 'react'
import { IForm } from '../../../../data/types/models/Form'
import { useStyles, ButtonActions, FormContainer, PrintContainer, PrintDiv, QRCodeView } from './style'
import PrintIcon from '@material-ui/icons/Print';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

interface Props {
    preloadedForms: IForm[]
    selectedForms: {[id: string]: boolean}
    onSelectForm: (id: string) => void
    onSelectAll: () => void
    onUnselectAll: () => void
    isLoading: boolean
    onValid: () => void
    onBack: () => void
}

const Layout: React.FC<Props> = ({ preloadedForms, selectedForms, onSelectAll, onUnselectAll, onSelectForm, isLoading, onValid, onBack }) => {
    const classes = useStyles()
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const print = useMediaQuery("@media print")

    return (
        <Fragment>
            <PrintContainer>
                    {preloadedForms.filter((form) => selectedForms[form.id] === true).map((form, index) => 
                        <PrintDiv key={form.id}>
                            <Typography color="primary" style={{textAlign: "center", fontWeight: "bold"}}>{form.room}</Typography>
                            <QRCodeView value={form.id} renderAs='canvas' size={180} level='H'/>
                        </PrintDiv>
                    )}
            </PrintContainer>
            <Dialog fullWidth fullScreen={!matches} open={!print} classes={{paper: classes.paper}} className="no-print">
                <DialogTitle style={{fontWeight: "bold"}} disableTypography>
                    <Typography variant="h5" style={{fontWeight: "bold"}}>{"Séléctionner les formulaires à imprimer"}</Typography>
                </DialogTitle>
                <ButtonActions>
                    <Button onClick={onSelectAll} color="primary">Tout séléctionner</Button>
                    <Button onClick={onUnselectAll}color="primary">Tout déséléctionner</Button>
                </ButtonActions>
                <FormContainer>
                    {preloadedForms.map((form) => (
                        <FormControlLabel
                            key={form.id}
                            className={classes.formControl}
                            control={<Checkbox checked={selectedForms[form.id] === true}
                            onChange={() => {
                                onSelectForm(form.id)
                            }}
                            name={form.id} />}
                            label={form.room}
                        />
                    ))}
                </FormContainer>
                <ButtonActions>
                    <Button startIcon={<ArrowBackIcon/>} style={{marginRight: "10px"}} onClick={onBack} disabled={isLoading} color="primary">
                        {isLoading ? <CircularProgress size={20} color="secondary" /> : "Retour"}
                    </Button>
                    <Button startIcon={<PrintIcon/>} onClick={onValid} disabled={isLoading} color="primary" variant="contained">
                        {isLoading ? <CircularProgress size={20} color="secondary" /> : "Imprimer"}
                    </Button>
                </ButtonActions>
            </Dialog>
        </Fragment>
    )
}

export default Layout