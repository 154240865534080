import React, { Fragment } from 'react'
import { IGym } from '../../../data/types/models/Gym'
import GymCard from '../../../lib/ui/GymCard'
import { MainContainer } from '../../../lib/ui/mainStyles'
import { useStyles } from './styles'
import LocationCityIcon from '@material-ui/icons/LocationCity';
import Drawer from '../../../lib/ui/Drawer'
import AddLocationIcon from '@material-ui/icons/AddLocation';
interface Props {
    gyms: IGym[]
    onSelectGym: (id: string) => void
    onAddGym: () => void
}

const Layout: React.FC<Props> = ({
    gyms,
    onSelectGym,
    onAddGym
}) => {

    const classes = useStyles({})
    return (
        <Drawer title={"Mes Salles"} buttonAction={{
            title: "Ajouter une salle",
            onClick: onAddGym
        }}>
            <MainContainer>
                <div className={classes.gymsList}>
                    {gyms.map((gym) => (
                        <GymCard key={gym.id} gym={gym} onClick={() => { onSelectGym(gym.id) }} className={classes.gymCard}/>
                    ))}
                </div>
            </MainContainer>
        </Drawer>
    )
}

export default Layout