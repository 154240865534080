import { Button, Typography } from '@material-ui/core'
import React from 'react'
import Drawer from '../../../../lib/ui/Drawer'
import { ButtonActions, MainContainer } from '../../../../lib/ui/mainStyles'
import { useStyles } from './styles'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { HygUpProduct } from '../../../../data/types/models/Addons/HygUp'
import ProductCard from '../ProductCard'

interface Props {
    products: HygUpProduct[]
    quantities: number[]
    onUpdateQuantity: (newValue: number, index: number) => void
}

const Layout: React.FC<Props> = ({ products, quantities, onUpdateQuantity }) => {
    const classes = useStyles({})
    return (
        <Drawer title="Commander">
            <MainContainer>
                <Typography variant="h6" color="primary" className={classes.title}>Liste des produits :</Typography>
                <div className={classes.products}>
                    {products.map((product, index) => (
                        <ProductCard product={product} quantity={quantities[index]} onUpdateQuantity={(newValue) => {onUpdateQuantity(newValue, index)}} className={classes.product}/>
                    ))}
                </div>
                <ButtonActions>
                    <Button color="primary" variant="contained" startIcon={<AddShoppingCartIcon/>}>Valider ma commande</Button>
                </ButtonActions>
            </MainContainer>
        </Drawer>
    )
}

export default Layout