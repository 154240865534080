import React from 'react'
import { Addon } from '../../../data/types/models/Addons'
import { FlexDiv } from '../Divs/FlexDiv'
import { useStyles } from './styles'
import ExtensionIcon from '@material-ui/icons/Extension';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

interface Props {
    imageSrc?: string
    ImageComponent?: (props: any) => JSX.Element
    label: string
    className?: string;
    onClick?: () => void
}

const ActionCard: React.FC<Props> = ({ imageSrc, className, onClick, label, ImageComponent }) => {
    const classes = useStyles({})

    return (
        <div className={clsx([classes.card, className])} onClick={onClick}>
            {imageSrc && <img className={classes.logo} src={imageSrc} alt={label + " Image"} />}
            {ImageComponent && <ImageComponent className={classes.logo}/>}
            <Typography variant="h6" className={classes.addonName} color="primary">{label}</Typography>
        </div>
    )
}

export default ActionCard