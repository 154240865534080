import { Dialog, useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { useMainStyles } from '../../../../lib/ui/mainStyles'
import { routes } from '../../../../lib/utils/routes'
import SubscribeGym from '../../../../views/SubscribeGym'

interface Props {
    gymId: string
}

const SubscriptionDialog: React.FC<Props> = ({ gymId }) => {
    const subscriptionMatch = useRouteMatch(routes.GYMS + ":id/subscription")
    const showSubscriptionDialog = subscriptionMatch && subscriptionMatch.isExact
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const classes = useMainStyles()
    const history = useHistory()
    const onCancel = () => {
        history.push(routes.GYMS + gymId)
    }
    return (
        <Dialog onClose={onCancel} open={showSubscriptionDialog} fullWidth fullScreen={!matches} classes={{ paper: classes.dialogPaper }}>
            <SubscribeGym gymId={gymId} onCancel={onCancel} />
        </Dialog>
    )
}

export default SubscriptionDialog