import { IGym } from "../types/models/Gym";

export const SET_GYMS = "SET_GYMS"

export const setGyms = (gyms: {[id: string]: IGym}) =>
      ({
            type: SET_GYMS,
            payload: gyms
      });

export const SET_GYM = "SET_GYM"

export const setGym = (gym: IGym) =>
      ({
            type: SET_GYM,
            payload: gym
      });

export const SET_CURRENT_GYM = "SET_CURRENT_GYM"

export const setCurrentGym = (gym: IGym) =>
      ({
            type: SET_CURRENT_GYM,
            payload: gym
      });
