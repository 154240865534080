import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from "yup";

import { Button, TextField, Typography } from '@material-ui/core'
import { Form, MainContainer, useStepsStyles, ButtonsDiv } from '../stepsStyles'
import { Controller } from "react-hook-form"
import { IUser } from '../../../../data/types/models/User';
import AppConfig from '../../../../AppConfig';
import { yupResolver } from '@hookform/resolvers';

interface Props {
    onDone: (values: {email: string, name: string, phoneNumber: string}) => void
    errorSignIn?: string
    user?: IUser
    stepNumber: number
    totalStep: number
}

interface Values {
    email: string;
    phoneNumber: string;
    name: string;
}

const schema = yup.object().shape({
    name: yup.string().required("Le nom complet est obligatoire."),
    phoneNumber: yup.string().min(10, "Le numéro est invalide").required("Le numéro de téléphone est obligatoire."),
    email: yup.string().email().required("L'adresse e-mail est obligatoire."),
});
  
const EmailStep: React.FC<Props> = ({ onDone, user, errorSignIn, stepNumber, totalStep }) => {
    const { handleSubmit, setError, clearErrors ,errors, control, register } = useForm<Values>({
        resolver: yupResolver(schema),
        defaultValues: user
    })

    useEffect(() => {
        if (errorSignIn) {
            setError("email", {message: errorSignIn})
        } else {
            clearErrors("email")
        }
    }, [errorSignIn])

    const onSubmit = (data: Values) => {
        onDone(data)
    }

    const stepsClasses = useStepsStyles()

    return (
        <MainContainer>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="h4" className={stepsClasses.title}>Bienvenue sur {AppConfig.brandName} <span className={stepsClasses.colored}>Forms</span></Typography>
                <Typography variant="body2" className={stepsClasses.stepText}>ÉTAPE {stepNumber}/{totalStep}</Typography>
                <Controller
                    as={TextField}
                    name="name"
                    control={control}
                    type="text"
                    label="Nom complet du responsable"
                    className={stepsClasses.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    autoComplete="name"
                    placeholder="Prénom Nom"
                    helperText={errors.name?.message}
                    error={!!errors.name}
                />
                <Controller
                    as={TextField}
                    control={control}
                    name="email"
                    className={stepsClasses.textField}
                    ref={register}
                    type="email"
                    autoComplete="email"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label="Adresse e-mail du responsable"
                    placeholder={`email@${AppConfig.brandName.toLowerCase()}.fr`}
                    helperText={errors.email?.message}
                    error={!!errors.email}
                />
                <Controller
                    as={TextField}
                    control={control}
                    name="phoneNumber"
                    ref={register}
                    type="tel"
                    autoComplete="tel"
                    className={stepsClasses.textField}
                    label="Numéro de téléphone du responsable"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    helperText={errors.phoneNumber?.message}
                    error={!!errors.phoneNumber}
                    placeholder="0612345678"
                />
                <ButtonsDiv>
                    <div/>
                    <Button type='submit' variant="contained" color="primary">Suivant</Button>
                </ButtonsDiv>
            </Form>
        </MainContainer>
    )
}

export default EmailStep