import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers';
import * as yup from "yup";

import { Button, Checkbox, FormLabel, TextField, Typography } from '@material-ui/core'
import { Form, MainContainer, useStepsStyles, ButtonsDiv, CheckBoxDiv } from '../stepsStyles'
import { Controller } from "react-hook-form"
import CreatableSelect from 'react-select/creatable';
import options from './gyms'
import { IGym } from '../../../../data/types/models/Gym';
import AppConfig from '../../../../AppConfig';
import cuid from 'cuid';

interface Values {
    gymName: string,
    honnorDeclaration: boolean
}

interface Props {
    onDone: (values: {gym: IGym}) => void
    onBack: () => void
    stepNumber: number
    totalStep: number
}

const schema = yup.object().shape({
    gymName: yup.string().required("Le nom de la salle est obligatoire."),
    // honnorDeclaration: yup.bool().oneOf([true], "Coche la case et valide que tu es bien le responsable de cette salle.").required()
});
  
const SelectGym: React.FC<Props> = ({ onDone, onBack, stepNumber, totalStep }) => {
    const { handleSubmit, watch, getValues, setValue, errors, control } = useForm<Values>({
        resolver: yupResolver(schema)
    })

    const onSubmit = (data: Values) => {
        let gym = options.find((gym) => gym.name === data.gymName) || {id: data.gymName, name: data.gymName, app: AppConfig.app, franchise: false}
        gym.id = cuid()
        onDone({ gym })
    }
    const stepsClasses = useStepsStyles()
    return (
        <MainContainer>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="h4" className={stepsClasses.title}>Informations sur la <span className={stepsClasses.colored}>salle de sport</span></Typography>
                <Typography variant="body2" className={stepsClasses.stepText}>ÉTAPE {stepNumber}/{totalStep}</Typography>

                <Controller
                    render={({ onChange, onBlur, value, name }) => (
                        <CreatableSelect
                            // value={{value, label: value}}
                            name={name}
                            styles={{
                                menu: (provided, state) => ({
                                    ...provided, zIndex: 2
                                }),
                                option: (provided, state) => ({
                                    ...provided, cursor: "pointer"
                                }),
                                container: (provided, state) => ({
                                    ...provided, marginTop: "22px"
                                })
                            }}
                            noOptionsMessage={() => "Aucune salle trouvée."}
                            isClearable
                            placeholder="Nanterre"
                            onChange={(e) => {
                                if (e) {
                                    //@ts-ignore
                                    setValue("gymName", e.value)
                                } else {
                                    setValue("gymName", undefined)
                                }
                            }}
                            onBlur={onBlur}
                            options={options.map((gym) => ({value: gym.name, label: gym.name}))}
                            formatCreateLabel={(userInput) => userInput.charAt(0).toUpperCase() + userInput.slice(1).toLowerCase()}
                        />
                    )}
                    name="gymName"
                    control={control}
                    type="text"
                    label={"Localisation du " + AppConfig.brandName}
                    style={{marginBottom: "0"}}
                    className={stepsClasses.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    helperText={errors.gymName?.message}
                    error={!!errors.gymName}
                />
                {/* <CheckBoxDiv>
                    <Controller
                        control={control} 
                        name="honnorDeclaration"
                        render={({ onChange, onBlur, value, name }) => (
                            <Checkbox
                                color="secondary"
                                onBlur={onBlur}
                                onChange={e => onChange(e.target.checked)}
                                checked={value}
                                name={name}
                            />
                        )}
                    />
                    <FormLabel style={{fontSize: "0.8rem"}}>J'atteste sur l'honneur être le responsable de ce {AppConfig.brandName}</FormLabel>
                </CheckBoxDiv> */}
                <ButtonsDiv>
                    <Button onClick={onBack} variant="outlined" color="primary">Revenir</Button>
                    <Button type='submit' disabled={/*watch("honnorDeclaration") !== true || */!(watch("gymName"))} variant="contained" color="primary">Suivant</Button>
                </ButtonsDiv>
            </Form>
        </MainContainer>
    )
}

export default SelectGym